import { Model } from '@vuex-orm/core';

export default class Tank extends Model {
    static fields() {
        return {
            id: this.number(),
            agreement_id: this.number(),
            name: this.string(),
            address: this.string(),
            type: this.string().nullable(),
            interval: this.number().nullable(),
            last_emptied: this.number().nullable(),
            customer: this.string(),
            date: this.string().nullable(),
            priority: this.string(),

            contact: this.string(),
            email: this.string(),
            phone: this.string(),
            po_number: this.string(),
            description: this.string(),
            volume: this.string().nullable(),
            comment: this.string().nullable(),

            created_at: this.number(),
            updated_at: this.number(),
        };
    }
}

Tank.entity = 'tanks';
Tank.primaryKey = 'id';
