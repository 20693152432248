var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wrapper",staticClass:"relative inline-block"},[_vm._t("activator",null,{"on":{ click: _vm.onClickActivator }}),(_vm.show)?_c('div',{staticClass:"dropdown-menu-content absolute z-10",class:{
            'left-0 before:left-8': _vm.alignment === _vm.ALIGNMENTS.LEFT,
            'left-1/2 transform -translate-x-1/2 before:left-1/2': _vm.alignment === _vm.ALIGNMENTS.CENTER,
            'right-0 before:right-4': _vm.alignment === _vm.ALIGNMENTS.RIGHT,
        },style:({
            top: _vm.top || null,
        })},[_c('div',{staticClass:"rounded-lg bg-white shadow overflow-hidden"},[_vm._t("default")],2)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }