<template>
    <div class="border-t-black">
        <table class="work-log__work_rows_table w-full">
            <tr>
                <th>No</th>
                <th>Produkt</th>
                <th>Enhet</th>
                <th>Pris</th>
                <th>Mengde</th>
                <th>Mva</th>
                <th>Sum</th>
            </tr>
            <tr v-for="(orderLine, uuid) in workLog.order_lines" :key="uuid">
                <td>{{ orderLine.product.no }}</td>
                <td class="w-full">
                    <input
                        type="text"
                        class="w-full"
                        :value="orderLine.name"
                        :disabled="!isEditing"
                        :data-key="'order_lines.' + uuid + '.name'"
                        @change="onUpdateInputValue"
                    />
                </td>
                <td>
                    <select
                        class="w-[60px]"
                        :value="orderLine.unit"
                        v-if="isEditing"
                        :data-key="'order_lines.' + uuid + '.unit'"
                        @change="onUpdateSelectValue"
                    >
                        <option></option>
                        <option v-for="unit in units" :key="unit" :value="unit">{{ unit }}</option>
                    </select>
                    <span v-else>{{ orderLine.unit }}</span>
                </td>
                <td class="text-right">
                    <input
                        type="text"
                        pattern="[0-9]+([.,][0-9]+)?"
                        class="w-[60px] text-right"
                        :value="orderLine.price"
                        :disabled="!isEditing"
                        :data-key="'order_lines.' + uuid + '.price'"
                        @change="onUpdateInputValue"
                    />
                </td>
                <td class="text-right">
                    <input
                        type="text"
                        pattern="[0-9]+([.,][0-9]+)?"
                        class="w-[60px] text-right"
                        :value="orderLine.amount"
                        :disabled="!isEditing"
                        :data-key="'order_lines.' + uuid + '.amount'"
                        @change="onUpdateInputValue"
                    />
                </td>
                <td class="text-right">{{ orderLine.product.tax_rate }}%</td>
                <td class="text-right">{{ orderLine.total }}</td>
                <td v-if="isEditing" style="border-width: 0"><IconDelete @click="$emit('onDeleteOrderLine', uuid)" /></td>
            </tr>
            <tr v-if="sumTotal">
                <th colspan="6" class="border-0"></th>
                <th class="text-right">{{ sumTotal }}</th>
            </tr>
        </table>
        <select v-if="isEditing" @change="onAddOrderLineFromSelect">
            <option value="">-- Legg til produkt --</option>
            <option v-for="product in products" :key="product.id" :value="product.id">{{ product.no }} / {{ product.name }}</option>
        </select>
    </div>
</template>
<script>
import IconDelete from '@/assets/svg/delete.svg';

export default {
    components: {
        IconDelete,
    },
    props: {
        workLog: {
            type: Object,
        },
        isEditing: {
            type: Boolean,
        },
        products: {
            type: Array,
        },
        units: {
            type: Array,
        },
        productCategories: {
            type: Array,
        },
    },
    data() {
        return {};
    },
    computed: {
        sumTotal() {
            return Object.values(this.workLog?.order_lines || {}).reduce((carry, orderLine) => {
                return carry + (parseFloat(String(orderLine.total || 0).replace(',', '.')) || 0);
            }, 0);
        },
    },
    methods: {
        onUpdateInputValue(event) {
            this.$emit('onUpdateInputValue', event);
        },
        onUpdateSelectValue(event) {
            this.$emit('onUpdateSelectValue', event);
        },
        onAddOrderLineFromSelect(event) {
            this.$emit('onAddOrderLineFromSelect', event);
        },
    },
    mounted() {},
};
</script>
