<template>
    <div
        class="v-select v-select--blue relative flex items-center px-6 body h-20"
        :class="{ 'bg-white': isFocused, 'bg-red-300 text-red-600': isInvalid }"
    >
        <label
            :for="id"
            class="absolute left-6 transition-transform origin-top-left"
            :class="{
                'transform translate-y-[-18px] scale-75': isFocused || value,
            }"
        >
            {{ label }}
        </label>
        <input
            autocomplete="new-value"
            class="w-full bg-transparent outline-none"
            :id="id"
            v-model="currentValue"
            :type="type"
            ref="input"
            @blur="onBlur"
            @focus="onFocus"
            @keydown="onKeyDown"
            @input="onInput"
        />
        <span class="absolute bottom-2 micro">
            {{ message }}
        </span>
        <ul v-if="showAutocompleteValues" ref="autocompleteOptionContainer" role="listbox" tabindex="-1" class="vs__dropdown-menu">
            <li
                v-for="option in filteredAutocompleteValues"
                :key="option"
                role="option"
                aria-selected="true"
                class="vs__dropdown-option vs__dropdown-option--selected"
                :class="{ 'vs__dropdown-option--highlight': option === highlightedOption }"
                @mouseenter="onOptionMouseEnter(option)"
                @mouseleave="onOptionMouseLeave(option)"
                @click="onOptionClick($event, option)"
            >
                {{ option }}
            </li>
        </ul>
        <slot name="icon-right"></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isFocused: false,
            currentValue: null,
            hoverOption: null,
            keyboardOption: null,
            autocompleteValuesOpen: false,
            blurTimeout: null,
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: String,
            default: '',
        },
        autocompleteValues: {
            type: Array,
            default: null,
        },
        openAutocompleteValuesOnFocus: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(newValue) {
            if (!this.isFocused) {
                this.currentValue = newValue;
            }
        },
    },
    computed: {
        showAutocompleteValues() {
            return this.isFocused && this.autocompleteValues && this.autocompleteValuesOpen;
        },
        highlightedOption() {
            if (this.keyboardOption) {
                return this.keyboardOption;
            } else if (this.hoverOption) {
                return this.hoverOption;
            } else {
                return this.currentValue;
            }
        },
        filteredAutocompleteValues() {
            if (this.showAutocompleteValues && this.autocompleteValues) {
                let values = this.autocompleteValues.filter((option) => option.includes(this.currentValue));
                return values;
            } else {
                return null;
            }
        },
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
        onBlur() {
            if (this.autocompleteValuesOpen) {
                if (this.blurTimeout) {
                    clearTimeout(this.blurTimeout);
                }
                this.blurTimeout = setTimeout(() => {
                    // We have to give the onOptionClick handler a bit of time to catch a click before closing the autocomplete list
                    // or else the click on the li will blur the component and close the autocomplete list before the click is handled
                    this.onBlurInternal();
                }, 250);
            } else {
                this.onBlurInternal();
            }
        },
        onBlurInternal() {
            this.isFocused = false;
            this.autocompleteValuesOpen = false;
            this.$emit('blur', this.currentValue);
        },
        onInput() {
            if (this.autocompleteValues) {
                this.autocompleteValuesOpen = true;
            }
            this.$emit('input', this.currentValue);
        },
        onKeyDown(event) {
            if (this.autocompleteValuesOpen && this.filteredAutocompleteValues.length > 0) {
                switch (event.keyCode) {
                    case 38: // up
                        if (this.keyboardOption) {
                            const currentIndex = this.filteredAutocompleteValues.indexOf(this.keyboardOption);
                            let nextIndex = currentIndex - 1;
                            if (nextIndex < 0) {
                                nextIndex = this.filteredAutocompleteValues.length - 1;
                            }
                            this.keyboardOption = this.filteredAutocompleteValues[nextIndex];
                        } else {
                            this.keyboardOption = this.filteredAutocompleteValues[this.filteredAutocompleteValues.length - 1];
                        }
                        event.preventDefault();
                        break;
                    case 40: // down
                        if (this.keyboardOption) {
                            const currentIndex = this.filteredAutocompleteValues.indexOf(this.keyboardOption);
                            const nextIndex = (currentIndex + 1) % this.filteredAutocompleteValues.length;
                            this.keyboardOption = this.filteredAutocompleteValues[nextIndex];
                        } else {
                            this.keyboardOption = this.filteredAutocompleteValues[0];
                        }
                        event.preventDefault();
                        break;
                    case 13: // enter
                        if (this.keyboardOption) {
                            event.target.value = this.currentValue = this.keyboardOption;
                            this.autocompleteValuesOpen = false;
                            this.$emit('input', this.$refs.input.value);
                            event.preventDefault();
                        }
                        break;
                }
            }
        },
        onFocus() {
            this.isFocused = true;
            if (this.openAutocompleteValuesOnFocus && this.autocompleteValues) {
                this.autocompleteValuesOpen = true;
            }
            this.$emit('focus');
        },
        onOptionMouseEnter(option) {
            this.hoverOption = option;
        },
        onOptionMouseLeave(option) {
            if (this.hoverOption === option) {
                this.hoverOption = null;
            }
        },
        onOptionClick(event, option) {
            this.currentValue = option;
            this.keyboardOption = null;
            this.autocompleteValuesOpen = false;
            if (this.blurTimeout) {
                clearTimeout(this.blurTimeout);
                this.blurTimeout = null;
                this.onBlurInternal();
            }
            event.preventDefault();
        },
    },
    mounted() {
        this.currentValue = this.value;
    },
};
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
