<template>
    <div>
        <h3 class="h3 mt-4">Delordre</h3>
        <dl class="order_information_dl">
            <dt>Type</dt>
            <dd>{{ $t('sub_order.type.' + subOrder.type_id) }}</dd>
            <template v-if="subOrder.sub_category">
                <dt>Underkategori</dt>
                <dd>{{ $t('sub_order.sub_category.' + subOrder.sub_category) }}</dd>
            </template>
            <template v-if="subOrder.sub_category_2">
                <dt>Underkategori2</dt>
                <dd>{{ $t('sub_order.sub_category_2.' + subOrder.sub_category_2) }}</dd>
            </template>
            <dt>Dato</dt>
            <dd>{{ subOrder.date }} - {{ subOrder.date_end }}</dd>
            <dt>Gjenta</dt>
            <dd>{{ $t('sub_order.repeat.' + subOrder.repeat) }}</dd>
            <template v-if="subOrder.repeat === 1">
                <dt>Gjenta ukedager</dt>
                <dd v-if="subOrder.repeat_weekdays">
                    {{ translatePipeSeparated('weekdays.short', subOrder.repeat_weekdays).join(', ') }}
                </dd>
                <dd v-else>$t('sub_order.repeat_weekdays.every_day')</dd>
            </template>
            <template v-if="subOrder.repeat === 14 && subOrder.repeat_weekdays">
                <dt>Ukedag</dt>
                <dd>{{ translatePipeSeparated('weekdays.short', subOrder.repeat_weekdays).join(', ') }}</dd>
            </template>
            <template v-if="subOrder.mapping_of">
                <dt>Kartlegging av</dt>
                <dd>{{ translatePipeSeparated('sub_order.mapping_of', subOrder.mapping_of).join(', ') }}</dd>
            </template>
            <dt>Kartlegging - annet</dt>
            <dd>{{ subOrder.mapping_other_description }}</dd>
            <dt>Vil bruke</dt>
            <dd>{{ translatePipeSeparated('sub_order.equipment', subOrder.equipment).join(', ') }}</dd>
            <dt>Vil bruke - annet</dt>
            <dd>{{ subOrder.equipment_other_description }}</dd>
            <dt>Rapport</dt>
            <dd>{{ subOrder.should_send_report ? 'Ja' : 'Nei' }}</dd>
            <dt>Dimensjon</dt>
            <dd>{{ subOrder.is_dimension_unknown ? 'Ukjent' : subOrder.dimension }}</dd>
            <dt>Lengde</dt>
            <dd>{{ subOrder.is_length_unknown ? 'Ukjent' : subOrder.length }}</dd>
            <dt>Tilkomst</dt>
            <dd>{{ subOrder.access_instructions }}</dd>
            <dt>Spyling</dt>
            <dd>{{ subOrder.flushing ? 'Ja' : 'Nei' }}</dd>
            <dt>Spyling - annet</dt>
            <dd>{{ subOrder.flushing_instructions }}</dd>
            <dt>Spaltemåling</dt>
            <dd>{{ subOrder.gap_measurement ? 'Ja' : 'Nei' }}</dd>
            <dt>Tilleggsinformasjon</dt>
            <dd>{{ subOrder.additional_information }}</dd>
        </dl>
    </div>
</template>

<script>
export default {
    name: 'WorkLogSubOrderInfo',
    props: {
        subOrder: {
            type: Object,
            required: true,
        },
    },
    methods: {
        translatePipeSeparated(prefix, keysPipeSeparated) {
            if (!keysPipeSeparated) {
                return [];
            }
            const keys = keysPipeSeparated.split('|');
            const result = [];
            for (const key of keys) {
                result.push(this.$t(prefix + '.' + key));
            }
            return result;
        },
    },
};
</script>

<style lang="scss"></style>
