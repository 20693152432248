import { Model } from '@vuex-orm/core';

export default class AutocompleteValue extends Model {
    static fields() {
        return {
            field: this.string(),
            id: this.number(),
            value: this.string(),
        };
    }
}

AutocompleteValue.entity = 'autocomplete values';
AutocompleteValue.primaryKey = 'id';
