<template>
    <div class="rounded-lg bg-white shadow-md py-3 pl-4 pr-3" @dragover.prevent @dragenter.prevent>
        <div class="flex justify-between">
            <h4 class="h3 text-blue-600 mb-1">
                {{ header }}
                <slot name="filter-icons" />
            </h4>

            <span class="text-blue-500 body-sm underline">
                <slot name="link" />
            </span>
        </div>
        <h5 class="body-sm">
            <span class="text-brown-500 tracking-wider uppercase">
                {{ task }}
            </span>

            <slot name="flushing" />
            <slot name="inspection" />

            <template v-if="customer">
                <span class="text-grey">for </span>
                <span class="text-blue-500">{{ customer }}</span>
            </template>
        </h5>
        <div class="flex items-end justify-between">
            <div class="flex mt-3">
                <div class="flex items-center">
                    <div class="mr-4 text-brown-600 body-sm">
                        {{ dateFormated }}
                        <span v-if="dateEnd && dateEnd !== date">
                            - <br />
                            {{ dateEndFormated }}
                        </span>
                    </div>
                </div>
                <div
                    class="flex items-center body-sm before:block before:w-3.5 before:h-3.5 before:rounded-full before:mr-2"
                    :class="`${priorityObj.textColor} ${priorityObj.backgroundColor}`"
                >
                    {{ priorityObj.label }} prioritet
                </div>

                <div
                    v-if="workLogCount"
                    class="cursor-pointer flex items-center body-sm before:block before:w-3.5 before:h-3.5 before:rounded-full before:mr-2"
                    @click="$emit('show-work-logs')"
                >
                    <IconWorkLog class="text-brown-600" />
                    {{ workLogCount.uncompleted }}+<span :class="{ 'text-red-600': workLogCount.completed > 0 }">{{ workLogCount.completed }}</span
                    >+{{ workLogCount.approved }}={{ workLogCount.total }}
                </div>
            </div>

            <button @click="$emit('show-operators')">
                <IconLoader v-if="isLoading" class="animate-spin" width="20" height="20" />
                <div class="flex" v-else-if="operators.length > 0">
                    <VAvatar
                        class="border-white border execution-order-recipient"
                        :data-operator_id="operator.user_id"
                        :size="40"
                        v-for="operator in operators"
                        :key="operator.user_id"
                        :refusedAt="operator.refused_at"
                    >
                        <template v-slot:badge-br v-if="operator.execution_order">
                            <span
                                class="inline-block h-4 w-4 rounded-full text-center text-black text-micro leading-4"
                                :class="operator.execution_order < 0 ? 'bg-green' : 'bg-blue-400'"
                            >
                                {{ operator.execution_order < 0 ? 'F' : operator.execution_order }}
                            </span>
                        </template>

                        <template v-slot:badge-tr v-if="operator.work_log">
                            <span
                                v-if="operator.work_log.isInProgress"
                                class="h-4 w-4 rounded-full flex justify-center items-center bg-orange text-white"
                                :title="'Påbegynt ' + formatUnixTime(operator.work_log.unix_time)"
                            >
                                <IconTimelapse />
                            </span>
                            <span
                                v-else-if="operator.work_log.isCompleted"
                                class="h-4 w-4 rounded-full flex justify-center items-center bg-green text-black p-1"
                                :title="'Ferdig ' + formatUnixTime(operator.work_log.unix_time)"
                            >
                                <IconCheckmark />
                            </span>
                        </template>
                        {{ operator.initials }}
                    </VAvatar>
                </div>
                <div
                    v-else
                    class="text-brown-500 hover:text-brown-600 bg-brown-300 hover:bg-brown-400 rounded-full w-10 h-10 flex items-center justify-center"
                >
                    <IconAddUser />
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import { format, fromUnixTime, isThisYear, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';

import IconAddUser from '@/assets/svg/add-user.svg';
import IconCheckmark from '@/assets/svg/checkmark.svg';
import IconLoader from '@/assets/svg/loader.svg';
import IconTimelapse from '@/assets/svg/timelapse.svg';
import IconWorkLog from '@/assets/svg/work-log.svg';
import VAvatar from '@/components/VAvatar';
import { PRIORITIES } from '@/constants';

export default {
    components: {
        IconAddUser,
        IconCheckmark,
        IconWorkLog,
        IconLoader,
        IconTimelapse,
        VAvatar,
    },
    props: {
        header: {
            type: String,
        },
        customer: {
            type: String,
        },
        date: {
            type: String,
        },
        dateEnd: {
            type: String,
        },
        task: {
            type: String,
        },
        priority: {
            type: String,
        },
        plannable: {
            type: Object,
        },
        loading: {
            type: Object,
            default: function () {
                return {
                    operators: false,
                    executionOrder: false,
                };
            },
        },
    },
    computed: {
        dateFormated() {
            return this.date ? this.formatDate(this.date) : 'Mangler dato';
        },
        dateEndFormated() {
            return this.dateEnd ? this.formatDate(this.dateEnd) : null;
        },
        priorityObj() {
            let backgroundColor;
            let textColor;
            let iconSize;
            let label;

            /*
            The background color styles need to be written out in full form rather than
            being interpolated strings with variables so that Tailwind can find them and
            include those classes. For example we should have 'before:bg-green' in the file
            rather than `before:bg-${color}`.
            See: https://tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html
            */
            switch (this.priority) {
                case PRIORITIES.LOW:
                    backgroundColor = 'before:bg-green';
                    textColor = 'text-green';
                    iconSize = 8;
                    label = 'lav';
                    break;
                case PRIORITIES.MEDIUM:
                    backgroundColor = 'before:bg-orange';
                    textColor = 'text-orange';
                    iconSize = 12;
                    label = 'medium';
                    break;
                case PRIORITIES.HIGH:
                    backgroundColor = 'before:bg-red';
                    textColor = 'text-red';
                    iconSize = 14;
                    label = 'høy';
                    break;

                default:
                    break;
            }

            return {
                backgroundColor,
                iconSize,
                label,
                textColor,
            };
        },
        workLogCount() {
            if (!this.plannable) {
                return null;
            }

            const dlc = this.plannable.work_log_count;
            return {
                total: dlc.total,
                approved: dlc.approved || 0,
                completed: dlc.completed || 0,
                uncompleted: (dlc.available || 0) + (dlc.in_progress || 0),
            };
        },
        operators() {
            if (!this.plannable) {
                return [];
            }

            return Object.values(this.plannable.assigned_operators);
        },
        isLoading() {
            return this.loading?.operators || this.loading?.executionOrder;
        },
    },
    methods: {
        formatDate(date) {
            const dateObj = parseISO(date);
            return format(dateObj, isThisYear(dateObj) ? 'E do MMM' : 'do MMM yyyy', { locale: nb });
        },
        formatUnixTime(unixTime) {
            const dateObj = fromUnixTime(unixTime);
            return format(dateObj, isThisYear(dateObj) ? 'E do MMM' : 'do MMM yyyy', { locale: nb });
        },
    },
};
</script>
