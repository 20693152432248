import { Model } from '@vuex-orm/core';

import Order from '@/store/models/Order';

export default class Contact extends Model {
    static fields() {
        return {
            created_at: this.number(),
            customer_id: this.number(),
            email: this.string(),
            id: this.number(),
            integration_id: this.number(),
            invoice_orders: this.hasMany(Order, 'invoice_contact_id'),
            name: this.string(),
            phone: this.string(),
            remote_id: this.number(),
            title: this.string(),
            updated_at: this.number(),
        };
    }
}

Contact.entity = 'contacts';
Contact.primaryKey = 'id';
