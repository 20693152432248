<template>
    <div class="p-4 rounded-lg bg-blue-400 flex flex-col" ref="dayContainer" @dragleave="onLeave">
        <h3 class="h3 flex-grow-0 transition-transform origin-[0.75rem_0.75rem]">
            <span class="sticky left-0">{{ formatDate(date) }}</span>
        </h3>
        <div class="flex-1 flex overflow-y-scroll" ref="scrollContainer">
            <div class="-ml-4 pl-6 pr-2 z-[6] relative pt-3 h-[99rem] flex-1 bg-blue-400" :style="{ left: adjustStickyColumnsLeft + 'px' }">
                <div v-for="timeslot in allTimeslots" v-bind:key="timeslot" class="h-8 w-[40px]" ref="timeslotLabel">
                    {{ timeslot }}
                </div>
                <div class="h-8 w-[40px]">
                    <div class="h-8 w-[40px]">24:00</div>
                </div>
            </div>

            <ResourceDepartment
                :date="date"
                :allPlannableTuples="plannableTuples"
                :plannableTuples="plannableTuples[date]['unassigned'] || []"
                :department="null"
                :currently-dragged-element="currentlyDraggedElement"
                :workDayStart="workDayStart"
                :workDayEnd="workDayEnd"
                :departmentUserSchedules="[]"
                :timeSlotsArePerDefaultAvailable="true"
                :adjustStickyColumnsLeft="adjustStickyColumnsLeft"
                :is-drag-target-calendar="isDragTargetCalendar"
                @setIsDragTargetCalendar="onSetClickTargetCalendar"
            />
            <template v-for="department in departments">
                <ResourceDepartment
                    v-if="departmentCanDoTaskType(department, currentlyDraggedType)"
                    v-bind:key="department.id"
                    :date="date"
                    :department="department"
                    :plannableTuples="plannableTuples[date][department.id] || []"
                    :allPlannableTuples="plannableTuples"
                    :show-time-slots="false"
                    :currently-dragged-element="currentlyDraggedElement"
                    :currently-dragged-type="currentlyDraggedType"
                    :workDayStart="workDayStart"
                    :workDayEnd="workDayEnd"
                    :departmentUserSchedules="departmentUserSchedulesByDepartmentId[department.id]"
                    :is-drag-target-calendar="isDragTargetCalendar"
                    @menuEdit="onClickMenuEdit"
                    @menuNew="onClickMenuNew"
                    @setCurrentlyClickedTaskType="onClickSetCurrentlyDraggedTaskType"
                    @setIsDragTargetCalendar="onSetClickTargetCalendar"
                    @setCurrentlyOpenedDropDownMenu="onSetCurrentlyOpenedDropDownMenu"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';

// import { throttle } from 'lodash';
import ResourceDepartment from '@/components/future/ResourceDepartment.vue';
import { capitalizeString, timeToDecimal } from '@/util';

export default {
    components: {
        ResourceDepartment,
    },
    data() {
        return {
            debouncedOnHorizontalScroll: null,
            adjustStickyColumnsLeft: 0,
            waitingForAnimationFrame: false,
        };
    },
    props: {
        departments: {
            type: Array,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        plannableTuples: {
            type: Object,
            required: true,
        },
        currentlyDraggedElement: {
            type: Object,
            default: null,
        },
        currentlyDraggedType: {
            type: String,
            default: null,
        },
        workDayStart: {
            type: String,
            required: true,
        },
        workDayEnd: {
            type: String,
            required: true,
        },
        departmentUserSchedulesByDepartmentId: {
            type: Array,
            required: true,
        },
        isDragTargetCalendar: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        allTimeslots: () => {
            let arr = Array(48)
                .fill()
                .map((e, i) => (Math.floor(i / 2) + '').padStart(2, '0') + ':' + (i % 2 === 0 ? '00' : '30'));
            return arr;
        },
    },
    methods: {
        formatDate: (date) => {
            return date ? capitalizeString(format(parseISO(date), 'E do LLL', { locale: nb })) : '';
        },
        adjustScrollPositionToWorkDay() {
            const timeslotHeight = this.$refs.timeslotLabel[0].getBoundingClientRect().height;
            const scrollContainerHeight = this.$refs.scrollContainer.getBoundingClientRect().height;
            const startWorkDayOffset = (timeToDecimal(this.workDayStart) * 2 + 1) * timeslotHeight;
            const endWorkDayOffset = (timeToDecimal(this.workDayEnd) * 2 + 1) * timeslotHeight;
            this.$refs.scrollContainer.scrollTop = Math.min(
                startWorkDayOffset - timeslotHeight,
                (startWorkDayOffset + endWorkDayOffset - scrollContainerHeight) / 2
            );
        },
        onClickMenuNew(departmentUserSchedule, date) {
            // not including date
            this.$emit('menuNew', departmentUserSchedule, date);
        },
        onClickMenuEdit(departmentUserSchedule, date) {
            // not including date
            this.$emit('menuEdit', departmentUserSchedule, date);
        },
        onHorizontalScroll() {
            this.adjustStickyColumnsLeft = Math.max(0, -this.$el.getBoundingClientRect().x);
        },
        setupScrollListener() {
            this.debouncedOnHorizontalScroll = () => {
                if (!this.waitingForAnimationFrame) {
                    this.waitingForAnimationFrame = true;
                    window.requestAnimationFrame(() => {
                        this.onHorizontalScroll();
                        this.waitingForAnimationFrame = false;
                    });
                }
            };
            // this.debouncedOnHorizontalScroll = throttle(() => {
            //     this.onHorizontalScroll();
            // }, 50);
            const horizontalScrollContainer = document.getElementById('horizontal-scroll-container');
            horizontalScrollContainer.addEventListener('scroll', this.debouncedOnHorizontalScroll);
        },
        cleanupScrollListener() {
            const horizontalScrollContainer = document.getElementById('horizontal-scroll-container');
            horizontalScrollContainer.removeEventListener('scroll', this.debouncedOnHorizontalScroll);
        },
        departmentCanDoTaskType(department, task) {
            return !task || department?.sub_order_types?.includes(task);
        },
        onClickSetCurrentlyDraggedTaskType(type) {
            this.$emit('setCurrentlyDraggedTaskType', type);
        },
        onSetClickTargetCalendar(active) {
            this.$emit('setIsDragTargetCalendar', active);
        },
        onLeave(e) {
            /**
             * Check whether the event occurred roughly inside (or above) the element.
             * https://stackoverflow.com/questions/16401856/detect-if-a-mouse-event-occurred-inside-an-elements-client-area
             * @param {MouseEvent} event Event to check.
             * @param {Node} element Element to check.
             */
            const isEventInElement = (event, element) => {
                const rect = element.getBoundingClientRect();
                const x = event.clientX;
                if (x < rect.left || x >= rect.right) {
                    return false;
                }
                const y = event.clientY;
                return !(y < rect.top || y >= rect.bottom);
            };
            if (!isEventInElement(e, this.$refs.dayContainer)) {
                this.$emit('setIsDragTargetCalendar', false);
            }
        },
        onSetCurrentlyOpenedDropDownMenu(data) {
            data.date = this.date;
            this.$emit('setCurrentlyOpenedDropDownMenu', data);
        },
    },
    mounted() {
        this.adjustScrollPositionToWorkDay();
        this.onHorizontalScroll();
        this.setupScrollListener();
    },
    beforeUnmount() {
        this.cleanupScrollListener();
    },
};
</script>
