<template>
    <vue-final-modal
        v-slot="{ close }"
        v-bind="$attrs"
        classes="flex justify-center items-center text-black"
        content-class="relative flex flex-col w-[1400px] h-[calc(100%-2rem)] m-4 p-8 rounded bg-white"
        v-on="$listeners"
    >
        <IconLoader v-if="isLoading" width="80" height="80" class="text-blue-400 mx-auto align-middle animate-spin" />
        <h2 class="h2 mb-4 text-blue-700 border-b border-blue-700" v-if="plannableSubOrder">
            Kjørelister {{ plannableSubOrder.address }}
            {{ type }}
            <template v-if="customer"> for {{ customer }}</template>
        </h2>
        <div class="flex overflow-hidden">
            <div class="flex-none w-[300px] mr-4 pr-4 overflow-y-auto">
                <v-checkbox
                    v-if="Object.keys(orders).length + Object.keys(tankAgreements).length > 0"
                    class="mb-4"
                    :model-value="filterShowCompleted"
                    @change="onUpdateFilterShowCompleted"
                >
                    Vis lister til godkjenning</v-checkbox
                >
                <div
                    v-for="order in filteredOrders"
                    :key="order.uuid"
                    class="border rounded-[6px] p-1 mb-2"
                    :class="order.uuid === currentOrderUUID ? 'bg-blue-400' : ''"
                >
                    <h3 @click="selectObject(null, order.uuid, null)" class="h3 cursor-pointer text-blue-600 mt-1">O:{{ order.customer.name }}</h3>
                    <p class="mb-1">{{ order.has_separate_assignment_address ? order.assignment_address : order.invoice_contact?.address }}</p>
                    <div v-for="subOrder in order.subOrders" :key="subOrder.uuid">
                        {{ $t('sub_order.type.' + subOrder.type_id) }}
                        <div v-for="(workLogs, status) in subOrder.workLogsByStatus" :key="status">
                            <template v-if="workLogs.length && (!filterShowCompleted || status === WORK_LOG_STATUSES.COMPLETED)">
                                <p class="">{{ $t('workLog.status.' + status) }}</p>
                                <ul class="border divide-y rounded-[4px] text-blue-600">
                                    <li
                                        v-for="workLog in workLogs"
                                        :key="workLog.uuid"
                                        @click="selectObject(workLog.uuid, null, null)"
                                        class="px-4 py-2 cursor-pointer"
                                        :class="
                                            workLog.uuid === currentWorkLogUUID
                                                ? 'bg-blue-400'
                                                : workLog.status === 'invoiced'
                                                ? 'bg-green'
                                                : 'bg-white'
                                        "
                                    >
                                        <div>{{ workLog.operator }}</div>
                                        <div>{{ format(parseISO(workLog.date), 'dd/MM-yyyy', { locale: nb }) }}</div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </div>
                <div
                    v-for="tankAgreement in filteredTankAgreements"
                    :key="tankAgreement.id"
                    class="border rounded-[6px] p-1 mb-2"
                    :class="tankAgreement.id === currentTankAgreementId ? 'bg-blue-400' : ''"
                >
                    <h3 @click="selectObject(null, null, tankAgreement.id)" class="h3 cursor-pointer text-blue-600 mt-1 mb-1">
                        A:{{ tankAgreement.customer.name }}
                    </h3>
                    <div v-for="tank in tankAgreement.tanks" :key="tank.id">
                        {{ tank.name }}
                        <div v-for="(workLogs, status) in tank.workLogsByStatus" :key="status">
                            <template v-if="workLogs.length && (!filterShowCompleted || status === WORK_LOG_STATUSES.COMPLETED)">
                                <p class="">{{ $t('workLog.status.' + status) }}</p>
                                <ul class="border divide-y rounded-[4px] text-blue-600">
                                    <li
                                        v-for="workLog in workLogs"
                                        :key="workLog.uuid"
                                        @click="selectObject(workLog.uuid, null, null)"
                                        class="px-4 py-2 cursor-pointer"
                                        :class="
                                            workLog.uuid === currentWorkLogUUID
                                                ? 'bg-blue-400'
                                                : workLog.invoiced_at !== null
                                                ? 'bg-green'
                                                : 'bg-white'
                                        "
                                    >
                                        <div>{{ workLog.operator }}</div>
                                        <div>{{ format(parseISO(workLog.date), 'dd/MM-yyyy', { locale: nb }) }}</div>
                                    </li>
                                </ul>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- button class="small-btn-blue mt-2" @click="createWorkLog">Opprett ny</button -->
            </div>
            <WorkLogEditor
                v-if="currentWorkLog"
                :key="currentWorkLogUUID"
                :workLog="currentWorkLog"
                :products="products"
                :units="units"
                :productCategories="productCategories"
                @workLogUpdated="onWorkLogUpdated"
                @workLogDeleted="onWorkLogDeleted"
                @startEditing="onWorkLogStartEditing"
                @stopEditing="onWorkLogStopEditing"
            />
            <OrderLineInvoicer
                v-else-if="currentOrder || currentTankAgreement"
                :order="currentOrder"
                :tankAgreement="currentTankAgreement"
                :key="currentOrder ? currentOrder.uuid : currentTankAgreement.id"
                :twentyfoursevenOrders="currentTwentyfoursevenOrders"
                @workLogUpdated="onWorkLogUpdated"
                @twentyfoursevenOrderUpdated="onTwentyfoursevenOrderUpdated"
            />
            <div v-else class="flex-auto"></div>
            <div class="flex-none w-[300px] ml-4 pl-4 overflow-y-auto border-l border-blue-700">
                <WorkLogTankAgreementInfo v-if="displayTankAgreement" :tankAgreement="displayTankAgreement" />
                <WorkLogTankInfo v-if="displayTank" :tank="displayTank" />
                <WorkLogOrderInfo v-if="displayOrder" :order="displayOrder" />
                <WorkLogSubOrderInfo v-if="displaySubOrder" :subOrder="displaySubOrder" />
            </div>
        </div>
        <button :disabled="isEditing" class="absolute top-8 right-8 pa-2 text-blue-700" @click="close">
            <IconClose />
        </button>
    </vue-final-modal>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import Vue from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { mapActions } from 'vuex';

import IconClose from '@/assets/svg/close.svg';
import IconLoader from '@/assets/svg/loader.svg';
import OrderLineInvoicer from '@/components/future/OrderLineInvoicer';
import WorkLogEditor from '@/components/future/WorkLogEditor';
import WorkLogOrderInfo from '@/components/future/WorkLogOrderInfo';
import WorkLogSubOrderInfo from '@/components/future/WorkLogSubOrderInfo';
import WorkLogTankAgreementInfo from '@/components/future/WorkLogTankAgreementInfo';
import WorkLogTankInfo from '@/components/future/WorkLogTankInfo';
import SubOrderOptions from '@/components/TheSubOrderForm/options';
import VCheckbox from '@/components/VCheckbox/VCheckbox.vue';
import { WORK_LOG_STATUSES } from '@/constants';
import { ACTIONS } from '@/store/actions';
import Customer from '@/store/models/Customer';
import SubOrder from '@/store/models/SubOrder';
import Tank from '@/store/models/Tank';
import TankAgreement from '@/store/models/TankAgreement';
import { applyReactiveUpdatesRecursively, getCleanWorkLog } from '@/util';

export default {
    components: {
        VCheckbox,
        IconClose,
        IconLoader,
        OrderLineInvoicer,
        WorkLogEditor,
        VueFinalModal,
        WorkLogTankInfo,
        WorkLogTankAgreementInfo,
        WorkLogOrderInfo,
        WorkLogSubOrderInfo,
    },
    props: {
        plannableId: {
            type: Number,
            default: null,
        },
        loadCompletedWorkLogs: {
            type: Boolean,
            default: false,
        },
        statusList: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            filterShowCompleted: false,
            currentWorkLogUUID: null,
            currentOrderUUID: null,
            currentTankAgreementId: null,
            workLogs: {},
            subOrders: {},
            orders: {},
            twentyfoursevenOrders: {},
            isLoading: false,
            isSaving: false,
            isEditing: false,
            nb,
            WORK_LOG_STATUSES: WORK_LOG_STATUSES,
            products: null,
            units: null,
            productCategories: null,
            currentWorkLog: null,
            currentOrder: null,
            currentTankAgreement: null,
            tankAgreements: {},
            tanks: {},
        };
    },
    computed: {
        filteredOrders() {
            if (!this.filterShowCompleted) {
                return this.orders;
            }
            const filteredOrders = {};
            for (const orderUuid in this.orders) {
                const order = this.orders[orderUuid];
                for (const subOrderUuid in order.subOrders) {
                    const subOrder = order.subOrders[subOrderUuid];
                    if (Object.prototype.hasOwnProperty.call(subOrder.workLogsByStatus, WORK_LOG_STATUSES.COMPLETED)) {
                        filteredOrders[orderUuid] = order;
                        break;
                    }
                }
            }
            return filteredOrders;
        },
        filteredTankAgreements() {
            if (!this.filterShowCompleted) {
                return this.tankAgreements;
            }
            const filteredTankAgreements = {};
            for (const tankAgreementId in this.tankAgreements) {
                const tankAgreement = this.tankAgreements[tankAgreementId];
                for (const tankId in tankAgreement.tanks) {
                    const tank = tankAgreement.tanks[tankId];
                    if (Object.prototype.hasOwnProperty.call(tank.workLogsByStatus, WORK_LOG_STATUSES.COMPLETED)) {
                        filteredTankAgreements[tankAgreementId] = tankAgreement;
                        break;
                    }
                }
            }
            return filteredTankAgreements;
        },
        workLogsByStatus() {
            let workLogsByStatus = Object.values(this.workLogs).reduce((carry, item) => {
                if (Object.prototype.hasOwnProperty.call(carry, item.status)) {
                    carry[item.status].push(item);
                }
                return carry;
                /* eslint-disable-next-line no-unused-vars */ // Key is required but eslint complains
            }, Object.fromEntries(Object.entries(WORK_LOG_STATUSES).map(([key, value]) => [value, []])));
            for (const status in workLogsByStatus) {
                if (workLogsByStatus[status].length > 0) {
                    workLogsByStatus[status].sort((a, b) => ('' + b.date).localeCompare(a.date));
                } else {
                    delete workLogsByStatus[status];
                }
            }
            return workLogsByStatus;
        },
        plannableSubOrder() {
            if (this.plannable && this.plannable.sub_order_uuid) {
                return SubOrder.find(this.plannable.sub_order_uuid);
            } else {
                return null;
            }
        },
        plannableTank() {
            if (this.plannable && this.plannable.tank_id) {
                return Tank.find(this.plannable.tank_id);
            } else {
                return null;
            }
        },
        displaySubOrder() {
            if (this.plannableSubOrder) {
                return this.plannableSubOrder;
            } else if (this.currentWorkLog && this.currentWorkLog.sub_order_uuid) {
                return this.subOrders[this.currentWorkLog.sub_order_uuid];
            } else {
                return null;
            }
        },
        displayOrder() {
            return this.displaySubOrder ? this.orders[this.displaySubOrder.order_uuid] : this.currentOrderUUID ? this.currentOrder : null;
        },
        displayTank() {
            if (this.plannableTank) {
                return this.plannableTank;
            } else if (this.currentWorkLog && this.currentWorkLog.tank_id) {
                return Tank.find(this.currentWorkLog.tank_id);
            } else {
                return null;
            }
        },
        displayTankAgreement() {
            if (this.displayTank) {
                return TankAgreement.find(this.displayTank.agreement_id);
            } else if (this.currentTankAgreementId) {
                return TankAgreement.find(this.currentTankAgreementId);
            } else {
                return null;
            }
        },
        customer() {
            if (this.displayOrder) {
                return this.displayOrder.customer.name;
            } else if (this.tankAgreement) {
                const customer = Customer.find(this.tankAgreement.customer_id);
                return customer?.name;
            } else {
                return '';
            }
        },
        type() {
            return SubOrderOptions.type_id.find((typeId) => typeId.value === this.plannableSubOrder.type_id)?.label;
        },
        currentTwentyfoursevenOrders() {
            if (this.currentOrder) {
                return this.twentyfoursevenOrders.filter((item) => {
                    return item.order_uuid === this.currentOrder.uuid;
                });
            } else if (this.currentTankAgreement) {
                return this.twentyfoursevenOrders.filter((item) => {
                    return item.tank_agreement_id === this.currentTankAgreement.id;
                });
            } else {
                return null;
            }
        },
    },
    methods: {
        ...mapActions({
            getWorkLogModalData: ACTIONS.GET_WORK_LOG_MODAL_DATA,
            updateWorkLog: ACTIONS.UPDATE_WORK_LOG,
            deleteWorkLog: ACTIONS.DELETE_WORK_LOG,
            approveWorkLog: ACTIONS.APPROVE_WORK_LOG,
            startWorkLog: ACTIONS.START_WORK_LOG,
            completeWorkLog: ACTIONS.COMPLETE_WORK_LOG,
            setWorkLogInvoiced: ACTIONS.SET_WORK_LOG_INVOICED,
            getProductData: ACTIONS.GET_PRODUCT_DATA,
        }),
        onUpdateFilterShowCompleted(value, event) {
            console.log(value, event);
            this.filterShowCompleted = value;
        },
        selectObject(workLogUuid, orderUuid, tankAgreementId) {
            if (!this.isEditing) {
                this.currentWorkLogUUID = workLogUuid;
                this.currentWorkLog = workLogUuid ? JSON.parse(JSON.stringify(this.workLogs[this.currentWorkLogUUID])) : null;
                this.currentOrderUUID = orderUuid;
                this.currentOrder = orderUuid ? this.orders[orderUuid] : null;
                this.currentTankAgreementId = tankAgreementId;
                this.currentTankAgreement = tankAgreementId ? this.tankAgreements[tankAgreementId] : null;
            }
        },
        async loadData(plannableId) {
            this.isLoading = true;
            try {
                const data = await this.getWorkLogModalData(plannableId);
                for (const uuid in data.workLogs) {
                    Vue.set(this.workLogs, uuid, getCleanWorkLog());
                }
                applyReactiveUpdatesRecursively(this.workLogs, data.workLogs);
                this.subOrders = data.subOrders;
                this.orders = data.orders;
                this.twentyfoursevenOrders = data.twentyfoursevenOrders;
                // connecting orders to sub orders
                for (const subOrderUuid in this.subOrders) {
                    const subOrder = this.subOrders[subOrderUuid];
                    const order = this.orders[subOrder.order_uuid];
                    if (order) {
                        if (!Object.prototype.hasOwnProperty.call(order, 'subOrders')) {
                            Vue.set(order, 'subOrders', {});
                        }
                        Vue.set(order.subOrders, subOrderUuid, subOrder);
                    }
                }
                // connecting sub orders and tanks to work logs
                for (const workLogUuid in this.workLogs) {
                    const workLog = this.workLogs[workLogUuid];
                    if (workLog.sub_order_uuid) {
                        const subOrder = this.subOrders[workLog.sub_order_uuid];
                        if (!Object.prototype.hasOwnProperty.call(subOrder, 'workLogsByStatus')) {
                            Vue.set(subOrder, 'workLogsByStatus', {});
                        }
                        if (!Object.prototype.hasOwnProperty.call(subOrder.workLogsByStatus, workLog.status)) {
                            Vue.set(subOrder.workLogsByStatus, workLog.status, []);
                        }
                        subOrder.workLogsByStatus[workLog.status].push(workLog);
                    } else if (workLog.tank_id) {
                        if (!Object.prototype.hasOwnProperty.call(this.tanks, workLog.tank_id)) {
                            Vue.set(this.tanks, workLog.tank_id, {
                                ...Tank.find(workLog.tank_id),
                                workLogsByStatus: {
                                    [workLog.status]: [workLog],
                                },
                            });
                        } else {
                            this.tanks[workLog.tank_id].workLogsByStatus[workLog.status].push(workLog);
                        }
                    }
                }

                // connecting tanks to tank agreements
                for (const tankId in this.tanks) {
                    const tank = this.tanks[tankId];
                    if (!Object.prototype.hasOwnProperty.call(this.tankAgreements, tank.agreement_id)) {
                        let ormTankAgreement = TankAgreement.find(tank.agreement_id);
                        const customer = Customer.find(ormTankAgreement.customer_id);
                        Vue.set(this.tankAgreements, tank.agreement_id, {
                            ...ormTankAgreement,
                            customer: customer,
                            tanks: {
                                [tank.id]: tank,
                            },
                        });
                    } else {
                        Vue.set(this.tankAgreements[tank.agreement_id].tanks, tank.id, tank);
                    }
                }

                let productData = await this.getProductData();
                this.products = productData.products;
                this.units = productData.units;
                this.productCategories = productData.categories;
            } finally {
                this.isLoading = false;
            }
        },
        onWorkLogUpdated(workLog) {
            Vue.set(this.workLogs, workLog.uuid, getCleanWorkLog());
            applyReactiveUpdatesRecursively(this.workLogs[workLog.uuid], workLog);
            workLog = this.workLogs[workLog.uuid];
            // Also needs to update the sub order or tank's reference to the work log
            let subObject = null;
            if (workLog.sub_order_uuid) {
                subObject = this.subOrders[workLog.sub_order_uuid];
            } else if (workLog.tank_id) {
                subObject = this.tanks[workLog.tank_id];
            }
            if (subObject) {
                let foundWithSameStatus = false;
                if (Object.prototype.hasOwnProperty.call(subObject.workLogsByStatus, workLog.status)) {
                    // updated work log status is present - see if we can find the work log in that list
                    for (let i = 0; i < subObject.workLogsByStatus[workLog.status].length; i++) {
                        if (subObject.workLogsByStatus[workLog.status][i].uuid === workLog.uuid) {
                            // updated work log is in the same status list - just replace it directly in array
                            Vue.set(subObject.workLogsByStatus[workLog.status], i, workLog);
                            foundWithSameStatus = true;
                        }
                    }
                    if (!foundWithSameStatus) {
                        // updated work log was not found in status list - append it
                        subObject.workLogsByStatus[workLog.status].push(workLog);
                    }
                } else {
                    // work log status does not yet have a list, so add it
                    Vue.set(subObject.workLogsByStatus, workLog.status, [workLog]);
                }
                if (!foundWithSameStatus) {
                    // updated work log wasn't found in status list - loop through all statuses and remove it
                    for (const status in subObject.workLogsByStatus) {
                        if (status === workLog.status) {
                            continue; // only want to remove from statuses other than the updated status
                        }
                        for (let i = 0; i < subObject.workLogsByStatus[status].length; i++) {
                            if (subObject.workLogsByStatus[status][i].uuid === workLog.uuid) {
                                subObject.workLogsByStatus[status].splice(i, 1);
                            }
                        }
                        if (subObject.workLogsByStatus[status].length === 0) {
                            Vue.delete(subObject.workLogsByStatus, status);
                        }
                    }
                }
            }
        },
        onWorkLogDeleted(workLog) {
            Vue.delete(this.workLogs, workLog.uuid);
            if (this.currentWorkLogUUID === workLog.uuid) {
                this.selectObject(null, null, null);
            }
            let subObject = null;
            if (workLog.sub_order_uuid) {
                subObject = this.subOrders[workLog.sub_order_uuid];
            } else if (workLog.tank_id) {
                subObject = this.tanks[workLog.tank_id];
            }
            if (subObject) {
                // Could look in workLog.status array first, but shouldn't have a noticeable performance impact.
                outer: for (const status in subObject.workLogsByStatus) {
                    for (let i = 0; i < subObject.workLogsByStatus[status].length; i++) {
                        if (subObject.workLogsByStatus[status][i].uuid === workLog.uuid) {
                            subObject.workLogsByStatus[status].splice(i, 1);
                            break outer;
                        }
                    }
                }
            }
        },
        onTwentyfoursevenOrderUpdated(twentyfoursevenOrder) {
            let found = false;
            for (let i = 0; i < this.twentyfoursevenOrders.length; i++) {
                if (this.twentyfoursevenOrders[i].uuid === twentyfoursevenOrder.uuid) {
                    Vue.set(this.twentyfoursevenOrders, i, twentyfoursevenOrder);
                    found = true;
                }
            }
            if (!found) {
                this.twentyfoursevenOrders.push(twentyfoursevenOrder);
            }
        },
        onWorkLogStartEditing() {
            this.isEditing = true;
        },
        onWorkLogStopEditing() {
            this.isEditing = false;
        },
        resetData(load) {
            this.selectObject(null, null, null);
            this.workLogs = {};
            this.orders = {};
            this.subOrders = {};
            this.tanks = {};
            this.tankAgreements = {};
            if (load) {
                this.loadData(this.plannableId);
            }
        },
        format,
        parseISO,
    },
    watch: {
        plannableId(plannableId) {
            this.resetData(plannableId);
        },
        loadCompletedWorkLogs(value) {
            this.resetData(value);
        },
    },
    async mounted() {},
};
</script>
<style lang="scss">
.work-log__work_rows_table {
    border-collapse: collapse;
    margin-top: 20px;

    th {
        padding: 3px 6px;
        vertical-align: bottom;
    }

    td {
        border: 1px solid black;
        padding: 3px 6px;

        input[pattern]:invalid {
            color: #ff0000;
        }
    }
}

.work-log__work_types_table {
    margin-top: 20px;

    th,
    td {
        padding: 3px 6px;
    }
}

.work-log__work_descriptions_table {
    border-collapse: collapse;
    margin-top: 20px;

    th {
        padding: 3px 6px;
        vertical-align: bottom;
    }

    td {
        border: 1px solid black;
        padding: 3px 6px;

        input[pattern]:invalid {
            color: #ff0000;
        }
    }
}

.order_information_dl {
    dt {
        color: #777777;
        margin-top: 0.25em;
    }
}
</style>
