<template>
    <main class="relative flex-grow mt-16">
        <section class="absolute top-0 left-0 right-0 bottom-0 flex flex-col">
            <div class="flex space-x-6 mt-12 pl-12">
                <PlannerDateSelector />
                <TheOperators />
            </div>
            <div class="py-8 px-12 space-x-4 flex flex-grow overflow-x-auto overflow-y-hidden whitespace-nowrap">
                <VPlannerColumn
                    class="flex-shrink-0"
                    :style="stickyStyles.tanks"
                    color="brown"
                    :isEmpty="!isLoading && tanks.length === 0"
                    :isExpanded="plannerColumnsExpanded.tanks"
                >
                    <template v-slot:header>
                        <button class="mr-2" @click="togglePlannerColumnExpanded('tanks')">
                            <IconMinusCircle class="inline-block" v-if="plannerColumnsExpanded.tanks" />
                            <IconPlusCircle class="inline-block" v-else />
                        </button>
                        Avtaleoppdrag ({{ tanks.length }})
                        <button
                            v-for="filter in tankFilters"
                            :key="filter.key"
                            class="ml-2"
                            :class="{ 'opacity-25': !filter.show }"
                            @click="filter.show = !filter.show"
                        >
                            <component :is="filter.icon" class="inline-block" />
                        </button>
                    </template>
                    <template v-slot:body>
                        <IconLoader v-if="isLoading" width="40" height="40" class="text-brown-600 mx-auto animate-spin" />
                        <draggable v-else :value="tanks" group="plannables" class="space-y-2 h-full" @change="onChangeDraggable($event)">
                            <CardTank v-for="tank in tanks" :key="tank.id" :tank="tank" />
                        </draggable>
                    </template>
                    <template v-slot:emptyContent> Ingen oppgaver </template>
                </VPlannerColumn>
                <VPlannerColumn
                    class="flex-shrink-0"
                    :style="stickyStyles.sub_orders"
                    color="brown"
                    :isEmpty="!isLoading && subOrdersFromDraftOrders.length === 0"
                    :isExpanded="plannerColumnsExpanded.sub_orders"
                >
                    <template v-slot:header>
                        <button class="mr-2" @click="togglePlannerColumnExpanded('sub_orders')">
                            <IconMinusCircle class="inline-block" v-if="plannerColumnsExpanded.sub_orders" />
                            <IconPlusCircle class="inline-block" v-else />
                        </button>
                        Nye henvendelser ({{ subOrdersFromDraftOrders.length }})
                    </template>
                    <template v-slot:body>
                        <IconLoader v-if="isLoading" width="40" height="40" class="text-brown-600 mx-auto animate-spin" />
                        <div v-else class="space-y-2 h-full">
                            <CardSubOrder v-for="subOrder in subOrdersFromDraftOrders" :key="subOrder.uuid" :subOrder="subOrder" />
                        </div>
                    </template>
                    <template v-slot:emptyContent> Ingen oppgaver </template>
                </VPlannerColumn>
                <template v-for="(column, i) in columns">
                    <VPlannerColumn
                        class="flex-shrink-0"
                        :class="column.sticky ? 'sticky left-0 z-10' : ''"
                        :style="i === 0 ? stickyStyles[column.columnKey] : ''"
                        :key="i"
                        :color="column.color"
                        :isEmpty="!isLoading && column.plannables.length === 0"
                        :isExpanded="plannerColumnsExpanded[column.columnKey]"
                    >
                        <template v-slot:header>
                            <div class="flex items-start">
                                <button class="mr-2" @click="togglePlannerColumnExpanded(column.columnKey)">
                                    <IconMinusCircle class="inline-block" v-if="plannerColumnsExpanded[column.columnKey]" />
                                    <IconPlusCircle class="inline-block" v-else />
                                </button>
                                {{ column.header }} ({{ column.plannables.length }})
                                <template v-if="column.hasTypeFilter">
                                    <vue-select
                                        v-if="plannerColumnsExpanded[column.columnKey]"
                                        class="plannable-type-select body-compact flex-grow ml-2"
                                        multiple
                                        placeholder="Alle typer"
                                        :selectOnTab="true"
                                        :options="plannableTypes"
                                        :value="selectedPlannableTypes"
                                        @input="onChangeSelectedPlannableTypes"
                                    >
                                        <template #open-indicator="{ attributes }">
                                            <span class="text-brown-700" v-bind="attributes">
                                                <IconCaretDown />
                                            </span>
                                        </template>
                                        <template #selected-option-container="{ deselect, option }">
                                            <div class="body-compact px-1 border rounded-[4px] flex items-center text-brown-700 bg-brown-300">
                                                <span>{{ option.label }}</span>
                                                <button @click="deselect(option)" class="ml-1">
                                                    <IconClose class="w-3 h-3" />
                                                </button>
                                            </div>
                                        </template>
                                        <template #option="option">{{ option.label }}</template>
                                    </vue-select>
                                    <template v-else>
                                        <span v-if="selectedPlannableTypes.length > 0" class="ml-2">Typefilter aktivt</span>
                                    </template>
                                </template>
                                <span
                                    v-if="column.userFilterActive"
                                    class="ml-2 inline-block align-bottom"
                                    title="Klikk for å nullstille filter"
                                    @click="resetPlannerFilterUserIds"
                                    ><IconFilter
                                /></span>
                                <span
                                    v-if="column.oldPlannables && column.oldPlannables.length > 0"
                                    class="ml-2 inline-block align-bottom text-orange"
                                    :title="getOldPlannableTitle(column.oldPlannables)"
                                    ><IconWarning
                                /></span>
                            </div>
                        </template>
                        <template v-slot:body>
                            <IconLoader v-if="isLoading" width="40" height="40" class="text-brown-600 mx-auto animate-spin" />
                            <draggable
                                v-else-if="column.canDrag"
                                :value="column.plannables"
                                group="plannables"
                                class="space-y-2 h-full"
                                @change="onChangeDraggable($event, column)"
                            >
                                <CardPlannable
                                    v-for="plannable in column.plannables"
                                    :key="plannable.id"
                                    :plannable="plannable"
                                    @show-operators="onClickOperator(plannable)"
                                    @show-work-logs="onClickWorkLogInfo(plannable)"
                                />
                            </draggable>
                            <div v-else class="space-y-2 h-full">
                                <CardPlannable
                                    v-for="plannable in column.plannables"
                                    :key="plannable.id"
                                    :plannable="plannable"
                                    @show-operators="onClickOperator(plannable)"
                                    @show-work-logs="onClickWorkLogInfo(plannable)"
                                />
                            </div>
                        </template>
                        <template v-slot:emptyContent>
                            {{ column.emptyContent }}
                        </template>
                    </VPlannerColumn>
                    <div
                        v-if="column.prioritiesAfter"
                        :key="'priorities_' + i"
                        class="p-4 rounded-lg bg-blue-600 w-[72px] rounded-lg relative flex flex-col transition-all"
                        :style="i === 0 ? stickyStyles['priorities_' + i] : ''"
                    >
                        <button
                            draggable
                            @dragstart="onExecutionOrderDragStart($event, priority)"
                            v-for="priority in [1, 2, 3, 4, 5, 6, 7, 8, 9, -1, null]"
                            :key="priority"
                            class="block rounded-full w-10 h-10 text-center leading-10 mb-2 opacity-50 hover:opacity-100 transition-opacity duration-200 ease-out"
                            :class="{
                                'bg-green': priority < 0,
                                'bg-white': priority > 0,
                                'border-white': priority === null,
                                border: priority === null,
                            }"
                        >
                            <template v-if="priority === -1">F</template>
                            <template v-else-if="priority > 0">{{ priority }}</template>
                        </button>
                    </div>
                </template>
            </div>
        </section>
        <VModalOperators :plannable="currentPlannable" v-model="isOperatorsModalOpen" @closed="onClosedOperatorsModal" />
        <VModalWorkLogs :plannableId="currentPlannable?.id" v-model="isWorkLogModalOpen" @closed="onClosedWorkLogModal" />
    </main>
</template>

<script>
import { arrayMoveImmutable } from 'array-move';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import VueSelect from 'vue-select';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import IconCalendar from '@/assets/svg/calendar.svg';
import IconCaretDown from '@/assets/svg/caret-down.svg';
import IconCaretLeft from '@/assets/svg/caret-left.svg';
import IconCaretRight from '@/assets/svg/caret-right.svg';
import IconClose from '@/assets/svg/close.svg';
import IconFatSeparator from '@/assets/svg/fat-separator.svg';
import IconFilter from '@/assets/svg/filter.svg';
import IconLoader from '@/assets/svg/loader.svg';
import IconMinusCircle from '@/assets/svg/minus-circle.svg';
import IconOilSeparator from '@/assets/svg/oil-separator.svg';
import IconPlusCircle from '@/assets/svg/plus-circle.svg';
import IconUnknownType from '@/assets/svg/unknown-type.svg';
import IconWarning from '@/assets/svg/warning.svg';
import CardPlannable from '@/components/future/CardPlannable';
import CardSubOrder from '@/components/future/CardSubOrder.vue';
import CardTank from '@/components/future/CardTank';
import PlannerDateSelector from '@/components/future/PlannerDateSelector.vue';
import TheOperators from '@/components/TheOperators';
import SubOrderOptions from '@/components/TheSubOrderForm/options';
import VModalOperators from '@/components/VModalOperators';
import VModalWorkLogs from '@/components/VModalWorkLogs';
import VPlannerColumn from '@/components/VPlannerColumn';
import { PLANNABLE_STATUSES, SUB_ORDER_STATUSES } from '@/constants';
import DateNavigator from '@/mixins/DateNavigator';
import { ACTIONS } from '@/store/actions';
import Plannable from '@/store/models/Plannable';
import SubOrder from '@/store/models/SubOrder';
import Tank from '@/store/models/Tank';
import { MUTATIONS } from '@/store/mutations';
import { capitalizeString, isString } from '@/util';

export default {
    mixins: [DateNavigator],
    components: {
        PlannerDateSelector,
        CardSubOrder,
        CardPlannable,
        CardTank,
        draggable,
        IconCalendar,
        IconClose,
        IconCaretLeft,
        IconCaretDown,
        IconCaretRight,
        IconFilter,
        IconLoader,
        IconWarning,
        IconPlusCircle,
        IconMinusCircle,
        IconOilSeparator,
        IconFatSeparator,
        IconUnknownType,
        TheOperators,
        VModalOperators,
        VModalWorkLogs,
        VPlannerColumn,
        VueSelect,
    },
    data() {
        return {
            currentSubOrder: null,
            currentPlannable: null,
            currentTank: null,
            isLoading: true,
            isOperatorsModalOpen: false,
            isWorkLogModalOpen: false,
            isUpdatingOrderStatus: false,
            /*
            This is an object keyed by column statuses and whose properties are arrays of
            sub-orders to display in that column while a sub-order status update API call
            is in progress. We use this to "optimistically" display the results of a
            user's drag & drop before the API call finishes. If we don't do this, then
            after dropping a sub-order in a new location, the sub-order will jump back to
            it's original position while the API call is fired, and it will jump to it's
            new position only after the call finishes, assuming the call succeeds.
            */
            optimisticPlannableResults: {},
            tankFilters: [
                {
                    key: 'oil_separator',
                    show: true,
                    icon: IconOilSeparator,
                    values: ['oil_separator'],
                },
                {
                    key: 'fat_separator',
                    show: true,
                    icon: IconFatSeparator,
                    values: ['fat_separator'],
                },
                {
                    key: 'other',
                    show: true,
                    icon: IconUnknownType,
                    values: [null, 'other'],
                },
            ],
            plannableTypes: [
                {
                    label: 'Avtaleoppdrag',
                    value: 'tank',
                },
                ...SubOrderOptions.type_id,
            ],
            selectedPlannableTypes: [],
        };
    },
    computed: {
        ...mapState(['plannerFilterUserIds', 'plannerColumnsExpanded']),
        ...mapGetters(['tankIdToPlannedDateMap']),
        stickyStyles() {
            const result = {
                tanks: '',
                sub_orders: '',
                [PLANNABLE_STATUSES.UNPLANNED]: '',
                priorities_1: '',
            };
            if (this.plannerDayCount > 1) {
                let offset = 0;
                result.tanks = 'z-index:10;position:sticky;left:0';
                offset += this.plannerColumnsExpanded['tanks'] ? 496 : 76;
                result.sub_orders = 'z-index:10;position:sticky;left:' + offset + 'px;';
                offset += this.plannerColumnsExpanded['sub_orders'] ? 496 : 76;
                result[PLANNABLE_STATUSES.UNPLANNED] = 'z-index:10;position:sticky;left:' + offset + 'px;';
                offset += this.plannerColumnsExpanded['unplanned'] ? 496 : 76;
                result['priorities_0'] = 'z-index:10;position:sticky;left:' + offset + 'px;';
            }
            return result;
        },
        columns() {
            const columns = [];
            columns.push({
                color: 'brown',
                columnKey: PLANNABLE_STATUSES.UNPLANNED,
                emptyContent: 'Ingen oppgaver',
                header: this.$t('plannable.status.unplanned'),
                status: PLANNABLE_STATUSES.UNPLANNED,
                plannables: this.getPlannables(PLANNABLE_STATUSES.UNPLANNED, PLANNABLE_STATUSES.UNPLANNED, null, this.selectedPlannableTypes),
                hasTypeFilter: true,
                sticky: this.plannerDayCount > 1,
                prioritiesAfter: this.plannerDayCount > 1,
                canDrag: true,
                acceptExecutionOrderDrop: true,
            });
            for (let i = 0; i < this.plannerDates.length; i++) {
                const date = this.plannerDates[i];
                const dayOfWeek = parseISO(date).getDay();
                const columnKey = PLANNABLE_STATUSES.PLANNED + '_' + dayOfWeek;
                columns.push({
                    date,
                    dayOfWeek,
                    color: 'blue',
                    columnKey,
                    emptyContent: 'Dra og slipp oppgaven hit',
                    header:
                        this.plannerDayCount > 1
                            ? capitalizeString(format(parseISO(date), 'E do LLL', { locale: nb }))
                            : this.$t('plannable.status.planned'),
                    status: PLANNABLE_STATUSES.PLANNED,
                    plannables: this.getPlannables(PLANNABLE_STATUSES.PLANNED, PLANNABLE_STATUSES.PLANNED, date),
                    oldPlannables: this.getOldPlannables(PLANNABLE_STATUSES.PLANNED),
                    userFilterActive: this.plannerFilterUserIds.length > 0,
                    prioritiesAfter: this.plannerDayCount === 1 || i === this.plannerDates.length - 1,
                    canDrag: true,
                    acceptExecutionOrderDrop: true,
                });
            }
            columns.push({
                color: 'blue',
                columnKey: PLANNABLE_STATUSES.IN_PROGRESS,
                emptyContent: 'Ingen oppgaver her',
                header: this.$t('plannable.status.in_progress'),
                status: PLANNABLE_STATUSES.IN_PROGRESS,
                plannables: this.getPlannables(PLANNABLE_STATUSES.IN_PROGRESS, PLANNABLE_STATUSES.IN_PROGRESS, this.plannerDateRange),
                oldPlannables: this.getOldPlannables(PLANNABLE_STATUSES.IN_PROGRESS),
                userFilterActive: this.plannerFilterUserIds.length > 0,
                canDrag: false,
                acceptExecutionOrderDrop: true,
            });
            columns.push({
                color: 'blue',
                columnKey: PLANNABLE_STATUSES.COMPLETED,
                emptyContent: 'Ingen oppgaver her',
                header: this.$t('plannable.status.completed'),
                status: PLANNABLE_STATUSES.COMPLETED,
                plannables: this.getPlannables(PLANNABLE_STATUSES.COMPLETED, PLANNABLE_STATUSES.COMPLETED, this.plannerDateRange),
                userFilterActive: this.plannerFilterUserIds.length > 0,
                canDrag: false,
                acceptExecutionOrderDrop: false,
            });
            return columns;
        },
        subOrdersFromDraftOrders() {
            return (
                SubOrder.query()
                    .where('status', SUB_ORDER_STATUSES.DRAFT)
                    // .where((subOrder) => {
                    //     const order = Order.find(subOrder.order_uuid);
                    //     return order?.status === ORDER_STATUSES.DRAFT;
                    // })
                    .orderBy('sort_order')
                    .get()
            );
        },
        tanks() {
            let tanks = [];

            // Apply filter
            const visibleTanks = this.tankFilters.filter((tf) => tf.show);
            if (visibleTanks.length === this.tankFilters.lengh) {
                tanks = Tank.all();
            } else if (visibleTanks.length > 0) {
                const types = visibleTanks.reduce((types, visibleTank) => {
                    return [...types, ...visibleTank.values];
                }, []);
                tanks = Tank.query()
                    .where('type', (type) => types.includes(type))
                    .get();
            }

            // Sort
            if (tanks.length > 0) {
                tanks = tanks.sort((a, b) => {
                    if (!a.date && b.date) {
                        return -1;
                    } else if (a.date && !b.date) {
                        return 1;
                    } else {
                        return a.date > b.date ? 1 : -1;
                    }
                });
            }

            return tanks;
        },
    },
    methods: {
        ...mapActions({
            updatePlannableStatus: ACTIONS.UPDATE_PLANNABLE_STATUS,
            createPlannableForTank: ACTIONS.CREATE_PLANNABLE_FOR_TANK,
        }),
        ...mapMutations({
            resetPlannerFilterUserIds: MUTATIONS.RESET_PLANNER_FILTER_USER_IDS,
            togglePlannerColumnExpanded: MUTATIONS.TOGGLE_PLANNER_COLUMN_EXPANDED,
        }),
        onExecutionOrderDragStart(event, executionOrder) {
            event.dataTransfer.effectAllowed = 'link';
            event.dataTransfer.setData('executionOrder', executionOrder);
        },
        onTankDragStart(event, tankId) {
            event.dataTransfer.effectAllowed = 'link';
            event.dataTransfer.setData('tankId', tankId);
        },
        getOldPlannableTitle(plannables) {
            let result = plannables.length + ' oppdrag før dagens dato\n';
            let dateToCountMap = {};
            for (const plannable of plannables) {
                if (!Object.prototype.hasOwnProperty.call(dateToCountMap, plannable.date)) {
                    dateToCountMap[plannable.date] = 1;
                } else {
                    dateToCountMap[plannable.date]++;
                }
            }
            for (const date in dateToCountMap) {
                let dateStr = format(parseISO(date), 'E do LLL', {
                    locale: nb,
                });
                result += '\n' + dateToCountMap[date] + ' oppdrag på ' + dateStr;
            }
            return result;
        },
        getOldPlannables(status) {
            let plannableQuery = Plannable.query()
                .where((plannable) => {
                    if (plannable.tank_id) {
                        return true;
                    } else if (plannable.sub_order) {
                        // return plannable.sub_order.order_status === ORDER_STATUSES.ACTIVE;
                        return plannable.sub_order.status === SUB_ORDER_STATUSES.ACTIVE;
                    } else {
                        return false;
                    }
                })
                .where((subOrder) => {
                    return subOrder.date < this.today;
                });
            if (Array.isArray(status)) {
                plannableQuery.where('status', (value) => status.includes(value));
            } else {
                plannableQuery.where('status', status);
            }
            return plannableQuery.orderBy('date').get();
        },
        /*
        date should be Date object
        */
        getPlannables(status, columnKey, date, types) {
            if (this.isUpdatingOrderStatus && this.optimisticPlannableResults[columnKey]) {
                return this.optimisticPlannableResults[columnKey];
            }

            const query = Plannable.query()
                .where((plannable) => {
                    if (plannable.tank_id) {
                        return true;
                    } else if (plannable.sub_order) {
                        // return plannable.sub_order.order_status === ORDER_STATUSES.ACTIVE;
                        return plannable.sub_order.status === SUB_ORDER_STATUSES.ACTIVE;
                    } else {
                        return false;
                    }
                })
                .orderBy('sort_order');
            if (Array.isArray(status)) {
                query.where('status', (value) => status.includes(value));
            } else {
                query.where('status', status);
            }

            if (date) {
                // date is either an ISO-formatted string formatted as 'yyyy-MM-dd' or an object with start and end ISO formatted dates
                if (isString(date)) {
                    query.where('date', date);
                } else {
                    query.where((plannable) => plannable.date >= date.start && plannable.date <= date.end);
                }
            }

            if (types && types.length > 0) {
                const hasTankType = types.some((type) => type.value === 'tank');
                const otherTypeIds = types.filter((type) => type.value !== 'tank').map((type) => type.value);
                query.where((plannable) => {
                    if (plannable.tank_id && hasTankType) {
                        return true;
                    }
                    if (plannable.sub_order_uuid && otherTypeIds.length > 0) {
                        if (otherTypeIds.includes(plannable.sub_order.type_id)) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            if (
                this.plannerFilterUserIds.length > 0 &&
                (status === PLANNABLE_STATUSES.PLANNED || status === PLANNABLE_STATUSES.IN_PROGRESS || status === PLANNABLE_STATUSES.COMPLETED)
            ) {
                query.where((plannable) => {
                    for (const operatorKey in plannable.assigned_operators) {
                        if (this.plannerFilterUserIds.includes(plannable.assigned_operators[operatorKey].user_id)) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            return query.with('work_log_info').get();
        },
        /*
        vuedraggable's event is an object with one of three keys at the top level:
        'moved', which is fired when an item is dragged to a new order in the same column,
        and 'added' and 'removed', which are each fired once when an item is dragged to a
        new column. In other words, moving an item to a new column will result in two
        events being fired.
        */
        async onChangeDraggable(event, column) {
            const eventKey = Object.keys(event)[0];

            /*
            We only make an API call on the 'moved' or 'added' events
            */
            if (eventKey === 'moved' || eventKey === 'added') {
                const element = event[eventKey].element;
                if (element instanceof Plannable) {
                    const plannable = element;
                    let plannables;

                    // If a sub-order was reordered within a single column
                    if (eventKey === 'moved') {
                        // Move sub-order to new position in same array
                        plannables = arrayMoveImmutable(column.plannables, event.moved.oldIndex, event.moved.newIndex);
                        // Else a sub-order was moved to a new column
                    } else if (column && column.canDrag && column.plannables) {
                        // Add sub-order to array at new index
                        plannables = [...column.plannables];
                        plannables.splice(event.added.newIndex, 0, event.added.element);
                    }

                    this.optimisticPlannableResults[column.columnKey] = plannables;
                    this.isUpdatingOrderStatus = true;

                    await this.updatePlannableStatus({
                        date: column.date || null,
                        status: column.status,
                        orderedIdList: plannables.map((plannable) => plannable.id),
                        id: plannable.id,
                    });

                    this.optimisticPlannableResults = {};
                    this.isUpdatingOrderStatus = false;
                } else if (element instanceof Tank) {
                    if (column && column.canDrag) {
                        const tank = element;
                        await this.createPlannableForTank({
                            status: column.status,
                            date: column.date || null,
                            tankId: tank.id,
                        });
                    }
                }
            } else if (event.removed) {
                /*
                We don't make an API call for the 'removed' event since an 'added' event
                is fired at the same time and we make the API call for the 'added' event.
                We do however make an optimistic array of sub-orders to display in the
                column the item was removed from while the API call is in progress.
                */

                const element = event[eventKey].element;
                if (element instanceof Plannable) {
                    // Remove sub-order from array
                    const plannables = [...column.plannables];
                    plannables.splice(event.removed.oldIndex, 1);

                    this.optimisticPlannableResults[column.columnKey] = plannables;
                }
            }
        },
        onClickOperator(plannable) {
            this.currentPlannable = plannable;
            this.isOperatorsModalOpen = true;
        },
        onClosedOperatorsModal() {
            this.currentPlannable = null;
        },
        onClickWorkLogInfo(plannable) {
            this.currentTank = null;
            this.currentPlannable = plannable;
            this.isWorkLogModalOpen = true;
        },
        onClickWorkLogInfoForTank(tank) {
            // TODO: need to add prop to VModalWorkLogs for this in a way that doesn't interfere with computed tank
            console.log(tank);
            // this.currentTank = tank;
            // this.currentPlannable = null;
            // this.isWorkLogModalOpen = true;
        },
        onClosedWorkLogModal() {
            this.currentTank = null;
            this.currentPlannable = null;
        },
        onChangeSelectedPlannableTypes(value) {
            console.log(event);
            this.selectedPlannableTypes = value;
        },
    },
    async mounted() {
        this.isLoading = false;
    },
};
</script>

<style lang="scss">
.plannable-type-select.v-select {
    .vs__dropdown-toggle {
        padding-bottom: 0;
    }
    .vs__search {
        margin-top: 0;
    }
}
</style>
