<template>
    <div class="pt-3" :class="{ 'bg-red-300': isInvalid }">
        <div class="ml-6 body-sm h-4">
            <slot name="label">{{ label }}</slot>
        </div>
        <vue-select
            class="v-select-component body"
            :class="{
                'v-select--blue': color === 'blue',
                'v-select--brown': color === 'brown',
                'v-select--invalid': isInvalid,
            }"
            :components="{ Deselect: IconClose }"
            :input-id="inputId"
            :options="isPaginated ? paginatedOptions : options"
            :filterable="!isPaginated"
            :placeholder="placeholder"
            :value="value"
            :selectOnTab="true"
            v-bind="{
                clearable: false,
                taggable: true,
                ...$attrs,
            }"
            @input="onInput"
            @search="onSearch"
        >
            <template #open-indicator="{ attributes }">
                <span
                    :class="{
                        'text-blue-700': color === 'blue',
                        'text-brown-700': color === 'brown',
                    }"
                    v-bind="attributes"
                >
                    <IconCaretDown />
                </span>
            </template>
            <template #selected-option-container="{ deselect, multiple, option }">
                <slot name="selected-option-container" :deselect="deselect" :multiple="multiple" :option="option">
                    <div
                        v-if="multiple"
                        class="body p-2 space-x-2 border rounded-[4px] mx-1 mb-1 flex items-center"
                        :class="{
                            'text-blue-700 bg-blue-300': color === 'blue',
                            'text-brown-700 bg-brown-300': color === 'brown',
                        }"
                    >
                        <span>{{ option.label }}</span>
                        <button type="button" formnovalidate @click="deselect(option)">
                            <IconClose />
                        </button>
                    </div>
                    <div v-else class="vs__selected">{{ option.label }}</div>
                </slot>
            </template>
            <template #selected-option="option">
                <slot name="selected-option" v-bind:option="option"> {{ option.label }} bubble </slot>
            </template>
            <template #option="option">
                <slot name="option" v-bind:option="option">
                    {{ option.label }}
                </slot>
            </template>
            <li v-if="isPaginated && options.length > paginationLimit" slot="list-footer" class="flex w-full">
                <button
                    type="button"
                    formnovalidate
                    class="text-body h-14 m-2 flex-grow px-0"
                    :class="color === 'blue' ? 'btn-blue' : 'btn-brown'"
                    :disabled="!hasPrevPage"
                    @click="pageOffset -= paginationLimit"
                >
                    Prev
                </button>
                <button
                    type="button"
                    formnovalidate
                    class="text-body h-14 m-2 flex-grow px-0"
                    :class="color === 'blue' ? 'btn-blue' : 'btn-brown'"
                    :disabled="!hasNextPage"
                    @click="pageOffset += paginationLimit"
                >
                    Next
                </button>
            </li>
        </vue-select>
    </div>
</template>

<script>
import VueSelect from 'vue-select';

import IconCaretDown from '../../assets/svg/caret-down.svg';
import IconClose from '../../assets/svg/close.svg';

export default {
    components: {
        IconCaretDown,
        IconClose,
        VueSelect,
    },
    data() {
        return {
            IconClose,
            pageOffset: 0,
            query: '',
        };
    },
    props: {
        color: {
            type: String,
            default: 'blue',
        },
        inputId: {
            type: String,
            default: '',
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
        isPaginated: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            default: () => [],
        },
        paginationLimit: {
            type: Number,
            default: 20,
        },
        placeholder: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [Object, String, Array],
            default: null,
        },
    },
    computed: {
        filteredOptions() {
            return this.options.filter((option) => option.label.toLowerCase().includes(this.query.toLowerCase()));
        },
        hasNextPage() {
            const nextOffset = this.pageOffset + this.paginationLimit;
            return Boolean(this.filteredOptions.slice(nextOffset, this.paginationLimit + nextOffset).length);
        },
        hasPrevPage() {
            const prevOffset = this.pageOffset - this.paginationLimit;
            return Boolean(this.filteredOptions.slice(prevOffset, this.paginationLimit + prevOffset).length);
        },
        paginatedOptions() {
            return this.filteredOptions.slice(this.pageOffset, this.paginationLimit + this.pageOffset);
        },
    },
    methods: {
        onInput(value) {
            this.$emit('change', value);
        },
        onSearch(query) {
            this.query = query;
            this.pageOffset = 0;
        },
    },
};
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.v-select-component.v-select {
    .vs__actions {
        padding-right: 26px;
    }

    .vs__dropdown-option {
        align-items: center;
        display: flex;
        height: 64px;
    }

    .vs__dropdown-toggle {
        border: none;
        padding-bottom: 21px;
    }

    .vs__selected-options {
        padding-left: 18px;
    }

    &.v-select--blue {
        .vs__search,
        .vs__selected,
        .vs__dropdown-option {
            @apply text-blue-700;
        }
        .vs__dropdown-menu {
            @apply bg-blue-300;
        }
        .vs__dropdown-option--highlight {
            @apply bg-blue-400;
        }
    }

    &.v-select--brown {
        .vs__search,
        .vs__selected,
        .vs__dropdown-option {
            @apply text-brown-700;
        }
        .vs__dropdown-menu {
            @apply bg-brown-300;
        }
        .vs__dropdown-option--highlight {
            @apply bg-brown-400;
        }
    }

    &.v-select--invalid {
        .vs__search,
        .vs__selected {
            @apply text-red-600;
        }
    }
}
</style>
