<template>
    <header class="fixed w-full bg-blue-600 text-white flex items-center h-16 border-b border-[#0D4268] px-4 md:px-8 drop-shadow-md z-50">
        <div class="flex flex-col inline-block text-white">
            <span class="font-medium text-xl md:text-2xl leading-5 mr-12 md:mr-24">VA Planner</span>
            <vue-select
                v-if="sortedTenants.length > 1"
                class="tenant-select"
                :options="sortedTenants"
                :value="currentTenant"
                :selectOnTab="true"
                label="name"
                @input="changeCurrentTenant"
            >
                <template #open-indicator="{ attributes }">
                    <span class="text-white" v-bind="attributes">
                        <IconCaretDown />
                    </span>
                </template>
                <template #option="option">
                    {{ option.name }}
                </template>
            </vue-select>
            <span v-else class="text-micro">{{ tenant.name }}</span>
        </div>
        <nav class="space-x-10 overflow-x-auto whitespace-nowrap">
            <VPostitMenuItem />
            <router-link :to="{ name: 'Planner' }" class="underline-offset-4">Planlegging</router-link>
            <router-link :to="{ name: 'Calendar' }" class="underline-offset-4">Kalender</router-link>
            <a href @click.prevent="$emit('openCompletedWorkLogsModal')"
                >Kjørelister
                <span v-if="completedWorkLogCount > 0" class="inline-block h-6 w-6 rounded-full bg-orange text-center">
                    <template v-if="completedWorkLogCount < 10">{{ completedWorkLogCount }}</template>
                    <template v-else>9+</template>
                </span>
            </a>
            <router-link v-if="user.is_developer" :to="{ name: 'orders' }" class="underline-offset-4">Ordre</router-link>
            <router-link :to="{ name: 'order-new' }" class="underline-offset-4">Opprett ordre</router-link>
            <router-link :to="{ name: 'map' }" class="underline-offset-4">Kart</router-link>
            <router-link :to="{ name: 'chat-channels' }" class="underline-offset-4"
                >Meldinger
                <span v-if="unreadChatMessageCount > 0" class="inline-block h-6 w-6 rounded-full bg-orange text-center">
                    <template v-if="unreadChatMessageCount < 10">{{ unreadChatMessageCount }}</template>
                    <template v-else>9+</template>
                </span>
            </router-link>
            <a :href="adminUrl" target="_blank">Admin <icon-open-in-new class="inline-block" /></a>
        </nav>
    </header>
</template>
<script>
import VueSelect from 'vue-select';
import { mapGetters, mapMutations, mapState } from 'vuex';

import IconOpenInNew from '@/assets/svg/open-in-new.svg';
import VPostitMenuItem from '@/components/VPostitMenuItem';
import { WORK_LOG_STATUSES } from '@/constants';
import ChatMessage from '@/store/models/ChatMessage';
import { MUTATIONS } from '@/store/mutations';

import IconCaretDown from '../../assets/svg/caret-down.svg';

export default {
    components: { VueSelect, IconOpenInNew, IconCaretDown, VPostitMenuItem },
    data() {
        return {
            WORK_LOG_STATUSES: WORK_LOG_STATUSES,
        };
    },
    computed: {
        ...mapState(['user', 'tenant', 'tenants', 'currentTenantId', 'completedWorkLogCount']),
        ...mapGetters(['sortedTenants', 'currentTenant']),
        unreadChatMessageCount() {
            return ChatMessage.query()
                .where((message) => {
                    return message.unread_by_roles.includes('backoffice');
                })
                .count();
        },
        adminUrl() {
            return process.env.VUE_APP_ADMIN_URL;
        },
    },
    methods: {
        ...mapMutations({
            updateCurrentTenantId: MUTATIONS.UPDATE_CURRENT_TENANT_ID,
        }),
        changeCurrentTenant(tenant) {
            this.updateCurrentTenantId(tenant.id);
        },
    },
};
</script>

<style lang="scss">
.tenant-select.v-select {
    .vs__search,
    .vs__search:focus,
    .vs__selected {
        @apply text-white;
        @apply m-0;
        @apply text-micro;
    }

    .vs__dropdown-option {
        @apply text-blue-700;
    }

    .vs__dropdown-toggle {
        @apply pb-0;
    }

    .vs__selected-options {
        @apply pl-0;
    }

    .vs__dropdown-menu {
        @apply bg-white;
    }

    .vs__dropdown-option--highlight {
        @apply bg-brown-400;
    }
    ul {
        z-index: 1000;
        li {
            z-index: 1000;
        }
    }
}
</style>
