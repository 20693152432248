export const MUTATIONS = {
    SET_ERRORS: 'SET_ERRORS',
    RESET_ERRORS: 'RESET_ERRORS',
};

export default {
    namespaced: true,
    state: {
        errors: {},
    },
    mutations: {
        [MUTATIONS.SET_ERRORS](state, values) {
            state.errors = { ...state.errors, ...values };
        },
        [MUTATIONS.RESET_ERRORS](state) {
            state.errors = {};
        },
    },
};
