<template>
    <main class="relative flex-grow mt-16">
        <section class="absolute top-0 left-0 right-0 bottom-0 flex flex-col">
            <div class="flex space-x-6 mt-12 pl-12">
                <PlannerDateSelector />
                <TheOperators
                    :departmentUserSchedulesByUserId="departmentUserSchedulesByUserId"
                    @menuNew="onClickMenuNew"
                    @menuEdit="onClickMenuEdit"
                />
            </div>
            <div id="horizontal-scroll-container" class="py-8 px-12 space-x-4 flex flex-grow overflow-x-auto overflow-y-hidden whitespace-nowrap">
                <VPlannerColumn
                    class="flex-shrink-0"
                    :style="stickyStyles.tanks"
                    color="brown"
                    :isEmpty="!isLoading && tanks.length === 0"
                    :isExpanded="plannerColumnsExpanded.tanks"
                >
                    <template v-slot:header>
                        <button class="mr-2" @click="togglePlannerColumnExpanded('tanks')">
                            <IconMinusCircle class="inline-block" v-if="plannerColumnsExpanded.tanks" />
                            <IconPlusCircle class="inline-block" v-else />
                        </button>
                        Avtaleoppdrag ({{ tanks.length }})
                        <button
                            v-for="filter in tankFilters"
                            :key="filter.key"
                            class="ml-2"
                            :class="{ 'opacity-25': !filter.show }"
                            @click="filter.show = !filter.show"
                        >
                            <component :is="filter.icon" class="inline-block" />
                        </button>
                    </template>
                    <template v-slot:body>
                        <IconLoader v-if="isLoading" width="40" height="40" class="text-brown-600 mx-auto animate-spin" />
                        <draggable
                            v-else
                            :value="tanks"
                            group="plannables"
                            class="space-y-2 h-full"
                            @change="onChangeDraggable($event)"
                            @start="onStartDraggableTank($event, tanks)"
                            @end="onEndDraggable($event, tanks)"
                            :move="checkMove"
                        >
                            <CardTank v-for="tank in tanks" :key="tank.id" :tank="tank" />
                        </draggable>
                    </template>
                    <template v-slot:emptyContent> Ingen oppgaver</template>
                </VPlannerColumn>
                <VPlannerColumn
                    class="flex-shrink-0"
                    :style="stickyStyles.sub_orders"
                    color="brown"
                    :isEmpty="!isLoading && subOrdersFromDraftOrders.length === 0"
                    :isExpanded="plannerColumnsExpanded.sub_orders"
                >
                    <template v-slot:header>
                        <button class="mr-2" @click="togglePlannerColumnExpanded('sub_orders')">
                            <IconMinusCircle class="inline-block" v-if="plannerColumnsExpanded.sub_orders" />
                            <IconPlusCircle class="inline-block" v-else />
                        </button>
                        Nye henvendelser ({{ subOrdersFromDraftOrders.length }})
                    </template>
                    <template v-slot:body>
                        <IconLoader v-if="isLoading" width="40" height="40" class="text-brown-600 mx-auto animate-spin" />
                        <div v-else class="space-y-2 h-full">
                            <CardSubOrder v-for="subOrder in subOrdersFromDraftOrders" :key="subOrder.uuid" :subOrder="subOrder" />
                        </div>
                    </template>
                    <template v-slot:emptyContent> Ingen oppgaver</template>
                </VPlannerColumn>
                <template v-for="(column, i) in columns">
                    <VPlannerColumn
                        class="flex-shrink-0"
                        :class="column.sticky ? 'sticky left-0 z-10' : ''"
                        :style="i === 0 ? stickyStyles[column.columnKey] : ''"
                        :key="i"
                        :color="column.color"
                        :isEmpty="!isLoading && column.plannables.length === 0"
                        :isExpanded="plannerColumnsExpanded[column.columnKey]"
                    >
                        <template v-slot:header>
                            <div class="flex items-start">
                                <button class="mr-2" @click="togglePlannerColumnExpanded(column.columnKey)">
                                    <IconMinusCircle class="inline-block" v-if="plannerColumnsExpanded[column.columnKey]" />
                                    <IconPlusCircle class="inline-block" v-else />
                                </button>
                                {{ column.header }} ({{ column.plannables.length }})
                                <template v-if="column.hasTypeFilter">
                                    <vue-select
                                        v-if="plannerColumnsExpanded[column.columnKey]"
                                        class="plannable-type-select body-compact flex-grow ml-2"
                                        multiple
                                        placeholder="Alle typer"
                                        :selectOnTab="true"
                                        :options="plannableTypes"
                                        :value="selectedPlannableTypes"
                                        @input="onChangeSelectedPlannableTypes"
                                    >
                                        <template #open-indicator="{ attributes }">
                                            <span class="text-brown-700" v-bind="attributes">
                                                <IconCaretDown />
                                            </span>
                                        </template>
                                        <template #selected-option-container="{ deselect, option }">
                                            <div class="body-compact px-1 border rounded-[4px] flex items-center text-brown-700 bg-brown-300">
                                                <span>{{ option.label }}</span>
                                                <button @click="deselect(option)" class="ml-1">
                                                    <IconClose class="w-3 h-3" />
                                                </button>
                                            </div>
                                        </template>
                                        <template #option="option">{{ option.label }}</template>
                                    </vue-select>
                                    <template v-else>
                                        <span v-if="selectedPlannableTypes.length > 0" class="ml-2">Typefilter aktivt</span>
                                    </template>
                                </template>
                                <span
                                    v-if="column.userFilterActive"
                                    class="ml-2 inline-block align-bottom"
                                    title="Klikk for å nullstille filter"
                                    @click="resetPlannerFilterUserIds"
                                    ><IconFilter
                                /></span>
                                <span
                                    v-if="column.oldPlannables && column.oldPlannables.length > 0"
                                    class="ml-2 inline-block align-bottom text-orange"
                                    :title="getOldPlannableTitle(column.oldPlannables)"
                                    ><IconWarning
                                /></span>
                            </div>
                        </template>
                        <template v-slot:body>
                            <IconLoader v-if="isLoading" width="40" height="40" class="text-brown-600 mx-auto animate-spin" />
                            <draggable
                                v-else-if="column.canDrag"
                                :value="column.plannables"
                                group="plannables"
                                class="space-y-2 h-full"
                                @change="onChangeDraggable($event, column)"
                                @start="onStartDraggable($event, column)"
                                @end="onEndDraggable($event, column)"
                            >
                                <CardPlannable
                                    v-for="plannable in column.plannables"
                                    :key="plannable.id"
                                    :plannable="plannable"
                                    @show-operators="onClickOperator(plannable)"
                                    @show-work-logs="onClickWorkLogInfo(plannable)"
                                />
                            </draggable>
                            <div v-else class="space-y-2 h-full">
                                <CardPlannable
                                    v-for="plannable in column.plannables"
                                    :key="plannable.id"
                                    :plannable="plannable"
                                    @show-operators="onClickOperator(plannable)"
                                    @show-work-logs="onClickWorkLogInfo(plannable)"
                                />
                            </div>
                        </template>
                        <template v-slot:emptyContent>
                            {{ column.emptyContent }}
                        </template>
                    </VPlannerColumn>
                </template>

                <ResourceCalendarDay
                    class="flex-shrink-0"
                    v-for="plannerDate in plannerDates"
                    v-bind:key="plannerDate"
                    :date="plannerDate"
                    :plannableTuples="plannableTuplesByDateAndDepartmentId || {}"
                    :departments="sortedDepartments"
                    :currently-dragged-element="currentlyDraggedElement"
                    :workDayStart="workDayStart"
                    :workDayEnd="workDayEnd"
                    :departmentUserSchedulesByDepartmentId="departmentUserSchedulesByDateAndDepartmentId[plannerDate]"
                    :currently-dragged-type="currentlyDraggedType"
                    :is-drag-target-calendar="isDragTargetCalendar"
                    ref="resourceCalendarDay"
                    @menuEdit="onClickMenuEdit"
                    @menuNew="onClickMenuNew"
                    @setCurrentlyDraggedTaskType="onClickSetCurrentlyDraggedTaskType"
                    @setIsDragTargetCalendar="onSetClickTargetCalendar"
                    @setCurrentlyOpenedDropDownMenu="onSetCurrentlyOpenedDropDownMenu"
                />
            </div>
        </section>
        <VModalEditDepartmentUserSchedules
            v-model="showEditDepartmentUserSchedules"
            :departmentUserSchedule="currentlyEditingDepartmentUserSchedule"
            :currentDate="currentlyEditingDepartmentUserScheduleDate"
        />
        <div class="absolute" ref="menu-activator-wrapper">
            <VDropdownMenu :alignment="dropDownAlignment">
                <template v-slot:activator="{ on }">
                    <div class="pointer-events-none" ref="menu-activator" v-on="on" />
                </template>
                <div class="w-[600px] p-2">
                    <template v-if="currentOpenedDropDownMenuData?.departmentUserSchedules">
                        <div
                            v-for="departmentUserSchedule in currentOpenedDropDownMenuData.departmentUserSchedules"
                            :key="'dus' + departmentUserSchedule.id"
                        >
                            <div
                                class="py-1"
                                :class="{
                                    'cursor-pointer': departmentUserSchedule.from && departmentUserSchedule.to,
                                    'hover:bg-blue-300': departmentUserSchedule.from && departmentUserSchedule.to,
                                }"
                                @click="onClickMenuEdit(departmentUserSchedule)"
                            >
                                <div v-if="!departmentUserSchedule.user_id" class="inline-block line-through">
                                    Utilgjengelig
                                    <span v-if="departmentUserSchedule.reason">({{ departmentUserSchedule.reason }})</span>
                                    {{ departmentUserSchedule.getFullReadableDateRange() }}
                                </div>
                                <div v-else-if="departmentUserSchedule.priority === 0" class="inline-block">
                                    <span class="font-medium">{{ departmentUserSchedule?.user?.name }}</span>
                                </div>
                                <div v-else class="inline-block">
                                    <span
                                        v-if="departmentUserSchedule.department_id"
                                        :class="{
                                            'line-through': departmentUserSchedule.department_id !== currentOpenedDropDownMenuData.department.id,
                                        }"
                                    >
                                        <span
                                            v-if="departmentUserSchedule.department_id !== currentOpenedDropDownMenuData.department.id"
                                            class="line-through"
                                        >
                                            {{ departmentUserSchedule?.user?.name }} opererer
                                            {{ departmentUserSchedule.getDepartmentLabel() }}
                                            {{ departmentUserSchedule.getFullReadableDateRange() }}
                                        </span>
                                        <span v-else>
                                            {{ departmentUserSchedule?.user?.name }} opererer denne avdelingen
                                            {{ departmentUserSchedule.getFullReadableDateRange() }}
                                        </span>
                                    </span>
                                    <span v-else class="line-through">
                                        {{ departmentUserSchedule?.user?.name }}
                                        <span v-if="departmentUserSchedule.reason">({{ departmentUserSchedule.reason }})</span>
                                        {{ departmentUserSchedule.getFullReadableDateRange() }}
                                    </span>
                                </div>
                                <IconPencil v-if="departmentUserSchedule.from && departmentUserSchedule.to" class="inline-block w-5 h-5 ml-2" />
                            </div>
                        </div>
                    </template>
                    <div v-else>Ingen brukere koblet til</div>
                    <div
                        v-if="currentOpenedDropDownMenuData?.departmentLabel"
                        class="py-1 cursor-pointer hover:bg-blue-300"
                        @click="
                            onClickMenuNew(
                                {
                                    user_id: null,
                                    user: null,
                                    department: currentOpenedDropDownMenuData.department,
                                    department_id: currentOpenedDropDownMenuData.department?.id,
                                    reason: '',
                                },
                                currentOpenedDropDownMenuData.date
                            )
                        "
                    >
                        Legg til utilgjengelighet for {{ currentOpenedDropDownMenuData.departmentLabel }}
                    </div>
                </div>
            </VDropdownMenu>
        </div>
    </main>
</template>

<script>
import { arrayMoveImmutable } from 'array-move';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import _ from 'lodash';
import VueSelect from 'vue-select';
import draggable from 'vuedraggable';
import { mapActions, mapMutations, mapState } from 'vuex';

import IconCalendar from '@/assets/svg/calendar.svg';
import IconCaretDown from '@/assets/svg/caret-down.svg';
import IconCaretLeft from '@/assets/svg/caret-left.svg';
import IconCaretRight from '@/assets/svg/caret-right.svg';
import IconClose from '@/assets/svg/close.svg';
import IconFatSeparator from '@/assets/svg/fat-separator.svg';
import IconFilter from '@/assets/svg/filter.svg';
import IconLoader from '@/assets/svg/loader.svg';
import IconMenu from '@/assets/svg/menu.svg';
import IconMinusCircle from '@/assets/svg/minus-circle.svg';
import IconOilSeparator from '@/assets/svg/oil-separator.svg';
import IconPencil from '@/assets/svg/pencil.svg';
import IconPlusCircle from '@/assets/svg/plus-circle.svg';
import IconUnknownType from '@/assets/svg/unknown-type.svg';
import IconWarning from '@/assets/svg/warning.svg';
import CardPlannable from '@/components/future/CardPlannable.vue';
import CardSubOrder from '@/components/future/CardSubOrder.vue';
import CardTank from '@/components/future/CardTank';
import PlannerDateSelector from '@/components/future/PlannerDateSelector.vue';
import ResourceCalendarDay from '@/components/future/ResourceCalendarDay.vue';
import TheOperators from '@/components/TheOperators/TheOperators.vue';
import SubOrderOptions from '@/components/TheSubOrderForm/options';
import { ALIGNMENTS } from '@/components/VDropdownMenu';
import VDropdownMenu from '@/components/VDropdownMenu/VDropdownMenu.vue';
import VModalEditDepartmentUserSchedules from '@/components/VModalEditDepartmentUserSchedules/VModalEditDepartmentUserSchedules.vue';
import VPlannerColumn from '@/components/VPlannerColumn/VPlannerColumn.vue';
import { PLANNABLE_STATUSES, SUB_ORDER_STATUSES } from '@/constants';
import DateNavigator from '@/mixins/DateNavigator';
import { ACTIONS } from '@/store/actions';
import Department from '@/store/models/Department';
import DepartmentUserSchedule from '@/store/models/DepartmentUserSchedule';
import Plannable from '@/store/models/Plannable';
import SubOrder from '@/store/models/SubOrder';
import Tank from '@/store/models/Tank';
import { MUTATIONS } from '@/store/mutations';
import { decimalToTime, isString, parseTime, timeToDecimal } from '@/util';

export default {
    name: 'Calendar',
    mixins: [DateNavigator],
    components: {
        VDropdownMenu,
        IconPencil,
        IconMenu,
        VModalEditDepartmentUserSchedules,
        draggable,
        IconFilter,
        IconCaretDown,
        IconWarning,
        CardPlannable,
        IconClose,
        VueSelect,
        CardSubOrder,
        CardTank,
        IconPlusCircle,
        IconMinusCircle,
        IconLoader,
        VPlannerColumn,
        ResourceCalendarDay,
        IconCaretLeft,
        TheOperators,
        IconCaretRight,
        IconCalendar,
        PlannerDateSelector,
    },
    data() {
        return {
            isLoading: true,
            plannableTypes: [
                {
                    label: 'Avtaleoppdrag',
                    value: 'tank',
                },
                ...SubOrderOptions.type_id,
            ],
            selectedPlannableTypes: [],
            tankFilters: [
                {
                    key: 'oil_separator',
                    show: true,
                    icon: IconOilSeparator,
                    values: ['oil_separator'],
                },
                {
                    key: 'fat_separator',
                    show: true,
                    icon: IconFatSeparator,
                    values: ['fat_separator'],
                },
                {
                    key: 'other',
                    show: true,
                    icon: IconUnknownType,
                    values: [null, 'other'],
                },
            ],
            optimisticPlannableResults: [],
            triggerUpdateTest: 1,
            currentlyDraggedElement: null,
            currentlyDraggedType: null,
            workDayStart: '06:00',
            workDayEnd: '15:00',
            showEditDepartmentUserSchedules: false,
            currentlyEditingDepartmentUserSchedule: null,
            currentlyEditingDepartmentUserScheduleDate: null,
            isDragTargetCalendar: false,
            currentOpenedDropDownMenuData: null,
            dropDownAlignment: ALIGNMENTS.LEFT,
        };
    },
    computed: {
        ...mapState(['tenant', 'tentants', 'currentTenantId', 'plannerColumnsExpanded', 'plannerFilterUserIds', 'plannerFakeTimeSpansForPlannables']),
        departmentUserSchedules() {
            const query = DepartmentUserSchedule.query().with('user|department').orderBy('priority');
            return query.get();
        },
        departments() {
            // const maxDepartments = false; // use for development
            let query = Department.query();
            // if (maxDepartments) {
            //    query = query.where((department) => department.id < maxDepartments);
            // }
            if (this.selectedPlannableTypes.length) {
                query = query.where((department) => {
                    return (
                        _.intersection(
                            department.sub_order_types,
                            this.selectedPlannableTypes.map((t) => t.value)
                        ).length > 0
                    );
                });
            }
            return query.get();
        },
        sortedDepartments() {
            return [...this.departments].sort((a, b) => {
                return new Intl.Collator('no', { numeric: true, sensitivity: 'accent' }).compare(a.name, b.name);
            });
        },
        stickyStyles() {
            const result = {
                tanks: '',
                sub_orders: '',
                [PLANNABLE_STATUSES.UNPLANNED]: '',
                priorities_1: '',
            };
            if (this.plannerDayCount > 1) {
                let offset = 0;
                result.tanks = 'z-index:10;position:sticky;left:0';
                offset += this.plannerColumnsExpanded['tanks'] ? 496 : 76;
                result[PLANNABLE_STATUSES.UNPLANNED] = 'z-index:10;position:sticky;left:' + offset + 'px;';
                offset += this.plannerColumnsExpanded['unplanned'] ? 496 : 76;
                result['priorities_0'] = 'z-index:10;position:sticky;left:' + offset + 'px;';
            }
            return result;
        },
        columns() {
            const columns = [];
            columns.push({
                color: 'brown',
                columnKey: PLANNABLE_STATUSES.UNPLANNED,
                emptyContent: 'Ingen oppgaver',
                header: this.$t('plannable.status.unplanned'),
                status: PLANNABLE_STATUSES.UNPLANNED,
                plannables: this.getPlannables(PLANNABLE_STATUSES.UNPLANNED, PLANNABLE_STATUSES.UNPLANNED, null, this.selectedPlannableTypes),
                hasTypeFilter: true,
                sticky: this.plannerDayCount > 1,
                prioritiesAfter: this.plannerDayCount > 1,
                canDrag: true,
                acceptExecutionOrderDrop: true,
            });
            return columns;
        },
        subOrdersFromDraftOrders() {
            return (
                SubOrder.query()
                    .where('status', SUB_ORDER_STATUSES.DRAFT)
                    // .where((subOrder) => {
                    //     const order = Order.find(subOrder.order_uuid);
                    //     return order?.status === ORDER_STATUSES.DRAFT;
                    // })
                    .orderBy('sort_order')
                    .get()
            );
        },
        tanks() {
            let tanks = [];

            // Apply filter
            const visibleTanks = this.tankFilters.filter((tf) => tf.show);
            if (visibleTanks.length === this.tankFilters.lengh) {
                tanks = Tank.all();
            } else if (visibleTanks.length > 0) {
                const types = visibleTanks.reduce((types, visibleTank) => {
                    return [...types, ...visibleTank.values];
                }, []);
                tanks = Tank.query()
                    .where('type', (type) => types.includes(type))
                    .get();
            }

            // Sort
            if (tanks.length > 0) {
                tanks = tanks.sort((a, b) => {
                    if (!a.date && b.date) {
                        return -1;
                    } else if (a.date && !b.date) {
                        return 1;
                    } else {
                        return a.date > b.date ? 1 : -1;
                    }
                });
            }

            return tanks;
        },
        plannables() {
            return Plannable.query()
                .where('date', (date) => this.plannerDates.includes(date))
                .get();
        },
        departmentUserSchedulesByDateAndDepartmentId() {
            let allPlannerDatesMap = this.plannerDates.reduce((carry, date) => {
                carry[date] = [];
                return carry;
            }, {});
            // we now have to loop through all dates and all schedules
            _.forEach(this.departmentUserSchedules, (departmentUserSchedule) => {
                const departmentUserScheduleFromDate = departmentUserSchedule.from ? departmentUserSchedule.getDateFrom() : '0000-00-00';
                const departmentUserScheduleToDate = departmentUserSchedule.to ? departmentUserSchedule.getDateTo() : '9999-99-99';
                _.forEach(this.plannerDates, (plannerDate) => {
                    const departmentId = departmentUserSchedule.department_id || 'unassigned';
                    if (!allPlannerDatesMap[plannerDate][departmentId]) {
                        allPlannerDatesMap[plannerDate][departmentId] = [];
                    }
                    if (departmentUserScheduleFromDate <= plannerDate && departmentUserScheduleToDate >= plannerDate) {
                        allPlannerDatesMap[plannerDate][departmentId].push(departmentUserSchedule);
                    } else {
                        return;
                    }
                    const userId = departmentUserSchedule?.user_id;
                    if (userId) {
                        if (this.departmentUserSchedulesByUserId[userId]) {
                            _.forEach(this.departmentUserSchedulesByUserId[userId], (dus) => {
                                if (
                                    departmentUserSchedule.department_id &&
                                    dus.department_id !== departmentUserSchedule.department_id &&
                                    dus.priority > departmentUserSchedule.priority
                                ) {
                                    const dusFromDate = dus.from ? dus.getDateFrom() : '0000-00-00';
                                    const dusToDate = dus.to ? dus.getDateTo() : '9999-99-99';
                                    let pushDus = dusFromDate <= plannerDate && dusToDate >= plannerDate;
                                    if (pushDus && !_.find(allPlannerDatesMap[plannerDate][departmentUserSchedule.department_id], { id: dus.id })) {
                                        allPlannerDatesMap[plannerDate][departmentUserSchedule.department_id].push(dus);
                                    }
                                    // todo: needs to be sorted by priority
                                }
                            });
                        }
                    }
                });
            });
            return allPlannerDatesMap;
        },
        departmentUserSchedulesByUserId() {
            let allPlannerDatesMap = {};
            // we now have to loop through all dates and all schedules
            _.forEach(this.departmentUserSchedules, (departmentUserSchedule) => {
                const userId = departmentUserSchedule.user_id || 'undefined';
                if (!allPlannerDatesMap[userId]) {
                    allPlannerDatesMap[userId] = [];
                }
                allPlannerDatesMap[userId].push(departmentUserSchedule);
            });
            return allPlannerDatesMap;
        },
        plannableTuplesByDateAndDepartmentId() {
            this.triggerUpdateTest; // intentionally do nothing
            // todo. maybe this should be time_spans instead
            // note: this will results in some values being undefined if there is no set date.
            //note: this results in array indices
            const plannables = this.getPlannables(
                [PLANNABLE_STATUSES.COMPLETED, PLANNABLE_STATUSES.PLANNED, PLANNABLE_STATUSES.UNPLANNED, PLANNABLE_STATUSES.IN_PROGRESS],
                'Calendar',
                this.plannerDateRange,
                this.selectedPlannableTypes
            );
            let fromTimes = new Set();
            let allPlannerDatesMap = this.plannerDates.reduce((carry, date) => {
                carry[date] = [];
                return carry;
            }, {});
            const result = plannables.reduce((carry, plannable) => {
                const date = (carry[plannable.date || 'undefined'] = carry[plannable.date || 'undefined'] || {});

                // check if there are time spans available
                if (plannable.time_spans && _.size(plannable.time_spans)) {
                    _.forEach(plannable.time_spans, (timeSpan, uuid) => {
                        fromTimes.add(timeSpan.from);
                        if (timeSpan.department_id) {
                            const type = (date[timeSpan.department_id] = date[timeSpan.department_id] || []);
                            type.push({
                                plannable: plannable,
                                timeSpanUUID: uuid,
                                date: timeSpan.date || plannable.date,
                                to: timeSpan.to,
                                from: timeSpan.from,
                                departmentId: timeSpan.department_id,
                                userId: timeSpan.user_id,
                                plannableId: plannable.id,
                            });
                        } else {
                            const type = (date['unassigned'] = date['unassigned'] || []);
                            type.push({
                                plannable: plannable,
                                timeSpanUUID: uuid,
                                date: timeSpan.date || plannable.date,
                                to: timeSpan.to,
                                from: timeSpan.from,
                                departmentId: timeSpan.department_id,
                                userId: timeSpan.user_id,
                                plannableId: plannable.id,
                            });
                        }
                    });
                } else {
                    const type = (date['unassigned'] = date['unassigned'] || []);
                    const tempPlannableTuple = {
                        plannable: plannable,
                        timeSpanUUID: null,
                        date: plannable.date,
                        to: null,
                        from: parseTime(plannable?.sub_order?.start_time) || null,
                        departmentId: null,
                        userId: null,
                        plannableId: plannable.id,
                    };
                    type.push(tempPlannableTuple);
                }
                // Return the result object for this iteration
                return carry;
            }, allPlannerDatesMap);
            let timeStampDecimal = 6;
            for (let dateKey in result) {
                let dayObject = result[dateKey];
                let unassignedPlannableTuples = dayObject['unassigned'];
                if (unassignedPlannableTuples) {
                    for (let i = 0; i < unassignedPlannableTuples.length; i++) {
                        let unassignedPlannableTuple = unassignedPlannableTuples[i];
                        const duration = parseInt(unassignedPlannableTuple?.plannable?.sub_order?.estimated_duration || '2');
                        if (!unassignedPlannableTuple.plannable.time_spans) {
                            let from = unassignedPlannableTuple.from;
                            if (!from) {
                                from = decimalToTime(timeStampDecimal);
                                timeStampDecimal += 0.5;
                            }
                            let to = unassignedPlannableTuple.to || decimalToTime(timeToDecimal(from) + duration);
                            const newTimeSpan = {
                                date: dateKey,
                                department_id: null,
                                from,
                                to,
                                user_id: null,
                            };
                            if (!this.plannerFakeTimeSpansForPlannables[unassignedPlannableTuple.plannable.id]) {
                                this.setFakeTimeSpansForPlannable({
                                    plannableId: unassignedPlannableTuple.plannable.id,
                                    timeSpan: newTimeSpan,
                                });
                                unassignedPlannableTuple.to = newTimeSpan.to;
                                unassignedPlannableTuple.from = newTimeSpan.from;
                            } else {
                                unassignedPlannableTuple.to = this.plannerFakeTimeSpansForPlannables[unassignedPlannableTuple.plannable.id].to; // keep the current one
                                unassignedPlannableTuple.from = this.plannerFakeTimeSpansForPlannables[unassignedPlannableTuple.plannable.id].from; // keep the current one
                            }
                        }
                    }
                }
            }
            return result;
        },
    },
    methods: {
        ...mapActions({
            updatePlannableStatus: ACTIONS.UPDATE_PLANNABLE_STATUS,
            createPlannableForTank: ACTIONS.CREATE_PLANNABLE_FOR_TANK,
        }),
        ...mapMutations({
            resetPlannerFilterUserIds: MUTATIONS.RESET_PLANNER_FILTER_USER_IDS,
            togglePlannerColumnExpanded: MUTATIONS.TOGGLE_PLANNER_COLUMN_EXPANDED,
            setFakeTimeSpansForPlannable: MUTATIONS.SET_FAKE_TIME_SPANS_FOR_PLANNABLE,
        }),
        getOldPlannables(status) {
            let plannableQuery = Plannable.query()
                .where((plannable) => {
                    if (plannable.tank_id) {
                        return true;
                    } else if (plannable.sub_order) {
                        // return plannable.sub_order.order_status === ORDER_STATUSES.ACTIVE;
                        return plannable.sub_order.status === SUB_ORDER_STATUSES.ACTIVE;
                    } else {
                        return false;
                    }
                })
                .where((subOrder) => {
                    return subOrder.date < this.today;
                });
            if (Array.isArray(status)) {
                plannableQuery.where('status', (value) => status.includes(value));
            } else {
                plannableQuery.where('status', status);
            }
            return plannableQuery.orderBy('date').get();
        },

        getOldPlannableTitle(plannables) {
            let result = plannables.length + ' oppdrag før dagens dato\n';
            let dateToCountMap = {};
            for (const plannable of plannables) {
                if (!Object.prototype.hasOwnProperty.call(dateToCountMap, plannable.date)) {
                    dateToCountMap[plannable.date] = 1;
                } else {
                    dateToCountMap[plannable.date]++;
                }
            }
            for (const date in dateToCountMap) {
                let dateStr = format(parseISO(date), 'E do LLL', {
                    locale: nb,
                });
                result += '\n' + dateToCountMap[date] + ' oppdrag på ' + dateStr;
            }
            return result;
        },
        /*
            date should be Date object
        */
        getPlannables(status, columnKey, date, types) {
            if (this.isUpdatingOrderStatus && this.optimisticPlannableResults[columnKey]) {
                return this.optimisticPlannableResults[columnKey];
            }

            const query = Plannable.query()
                .where((plannable) => {
                    if (plannable.tank_id) {
                        return true;
                    } else if (plannable.sub_order) {
                        // return plannable.sub_order.order_status === ORDER_STATUSES.ACTIVE;
                        return plannable.sub_order.status === SUB_ORDER_STATUSES.ACTIVE;
                    } else {
                        return false;
                    }
                })
                .orderBy('sort_order');
            if (Array.isArray(status)) {
                query.where('status', (value) => status.includes(value));
            } else {
                query.where('status', status);
            }

            if (date) {
                // date is either an ISO-formatted string formatted as 'yyyy-MM-dd' or an object with start and end ISO formatted dates
                if (isString(date)) {
                    query.where('date', date);
                } else {
                    query.where((plannable) => plannable.date >= date.start && plannable.date <= date.end);
                }
            }

            if (types && types.length > 0) {
                const hasTankType = types.some((type) => type.value === 'tank');
                const otherTypeIds = types.filter((type) => type.value !== 'tank').map((type) => type.value);
                query.where((plannable) => {
                    if (plannable.tank_id && hasTankType) {
                        return true;
                    }
                    if (plannable.sub_order_uuid && otherTypeIds.length > 0) {
                        if (otherTypeIds.includes(plannable.sub_order.type_id)) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            if (
                this.plannerFilterUserIds.length > 0 &&
                (status === PLANNABLE_STATUSES.PLANNED || status === PLANNABLE_STATUSES.IN_PROGRESS || status === PLANNABLE_STATUSES.COMPLETED)
            ) {
                query.where((plannable) => {
                    for (const operatorKey in plannable.assigned_operators) {
                        if (this.plannerFilterUserIds.includes(plannable.assigned_operators[operatorKey].user_id)) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            return query.with('work_log_info').get();
        },
        onChangeSelectedPlannableTypes(value) {
            this.selectedPlannableTypes = value;
        },
        async onEndDraggable() {
            this.currentlyDraggedElement = null;
            this.currentlyDraggedType = null;
        },
        async onStartDraggable(event, column) {
            const listIndex = event.oldIndex;
            const plannable = column.plannables[listIndex];
            this.currentlyDraggedElement = plannable;
            this.currentlyDraggedType = plannable?.sub_order?.type_id || null;
        },
        async onStartDraggableTank(event, tanks) {
            const index = event.oldIndex;
            this.currentlyDraggedType = 'tank_report';
            this.currentlyDraggedElement = tanks[index];
        },
        /*
        vuedraggable's event is an object with one of three keys at the top level:
        'moved', which is fired when an item is dragged to a new order in the same column,
        and 'added' and 'removed', which are each fired once when an item is dragged to a
        new column. In other words, moving an item to a new column will result in two
        events being fired.
        */
        async onChangeDraggable(event, column) {
            const eventKey = Object.keys(event)[0];
            /*
            We only make an API call on the 'moved' or 'added' events
            */
            if (eventKey === 'moved' || eventKey === 'added') {
                const element = event[eventKey].element;
                if (element instanceof Plannable) {
                    const plannable = element;
                    let plannables;

                    // If a sub-order was reordered within a single column
                    if (eventKey === 'moved') {
                        // Move sub-order to new position in same array
                        plannables = arrayMoveImmutable(column.plannables, event.moved.oldIndex, event.moved.newIndex);
                        // Else a sub-order was moved to a new column
                    } else if (column && column.canDrag && column.plannables) {
                        // Add sub-order to array at new index
                        plannables = [...column.plannables];
                        plannables.splice(event.added.newIndex, 0, event.added.element);
                    }

                    this.optimisticPlannableResults[column.columnKey] = plannables;
                    this.isUpdatingOrderStatus = true;

                    await this.updatePlannableStatus({
                        date: column.date || null,
                        status: column.status,
                        orderedIdList: plannables.map((plannable) => plannable.id),
                        id: plannable.id,
                    });

                    this.optimisticPlannableResults = {};
                    this.isUpdatingOrderStatus = false;
                } else if (element instanceof Tank) {
                    if (!this.isDragTargetCalendar && column && column.canDrag) {
                        await this.createPlannableForTank({
                            status: column.status,
                            date: column.date || null,
                            tankId: element.id,
                        });
                    }
                }
            } else if (event.removed) {
                /*
                We don't make an API call for the 'removed' event since an 'added' event
                is fired at the same time and we make the API call for the 'added' event.
                We do however make an optimistic array of sub-orders to display in the
                column the item was removed from while the API call is in progress.
                */

                const element = event[eventKey].element;
                if (element instanceof Plannable) {
                    // Remove sub-order from array
                    const plannables = [...column.plannables];
                    plannables.splice(event.removed.oldIndex, 1);

                    this.optimisticPlannableResults[column.columnKey] = plannables;
                }
            }
        },
        onClickMenuNew(departmentUserSchedule, date) {
            this.showEditDepartmentUserSchedules = !this.showEditDepartmentUserSchedules;
            this.currentlyEditingDepartmentUserSchedule = departmentUserSchedule;
            this.currentlyEditingDepartmentUserScheduleDate = date || this.plannerDates?.[0];
        },
        onClickMenuEdit(departmentUserSchedule, date) {
            this.showEditDepartmentUserSchedules = !this.showEditDepartmentUserSchedules;
            this.currentlyEditingDepartmentUserSchedule = departmentUserSchedule;
            this.currentlyEditingDepartmentUserScheduleDate = date || this.plannerDates?.[0];
        },
        onClickSetCurrentlyDraggedTaskType(type) {
            this.currentlyDraggedType = type;
        },
        checkMove() {
            return true;
        },
        onSetClickTargetCalendar(active) {
            this.isDragTargetCalendar = active;
        },
        onSetCurrentlyOpenedDropDownMenu(data) {
            this.currentOpenedDropDownMenuData = data;
            let leftAdjustments = 0;
            let dropDownSize = 600;
            let dropDownAlignment = ALIGNMENTS.CENTER;
            const boundingClientRect = data.srcElement.getBoundingClientRect();
            [ALIGNMENTS.CENTER, ALIGNMENTS.LEFT, ALIGNMENTS.RIGHT].every((alignment) => {
                dropDownAlignment = alignment;
                switch (alignment) {
                    case ALIGNMENTS.CENTER:
                        leftAdjustments = 13;
                        dropDownSize = 300;
                        break;
                    case ALIGNMENTS.LEFT:
                        leftAdjustments = -20;
                        dropDownSize = 600;
                        break;
                    case ALIGNMENTS.RIGHT:
                        leftAdjustments = 45;
                        dropDownSize = 0;
                }
                // we want to make sure this fits on the screen.
                // if there is no space on the right side, use right alignment to make sure it fits.
                const viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth);
                return boundingClientRect.left + leftAdjustments + dropDownSize > viewWidth;
            });
            this.dropDownAlignment = dropDownAlignment;
            this.$refs['menu-activator-wrapper'].style.top = boundingClientRect.top - 50 + 'px';
            this.$refs['menu-activator-wrapper'].style.left = boundingClientRect.left + leftAdjustments + 'px';
            this.$refs['menu-activator'].click();
        },
    },
    watch: {
        plannerFakeTimeSpansForPlannables: {
            handler() {
                this.triggerUpdateTest++;
            },
            deep: true,
            immediate: false,
        },
    },
    async mounted() {
        this.isLoading = false;
    },
};
</script>

<style lang="scss">
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.container {
    height: 100%;
    width: 100%;
}

.left-container {
    overflow: hidden;
    position: relative;
    height: 99vh;
    display: inline-block;
    width: 80%;
}
</style>
