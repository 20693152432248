<template>
    <div class="h-6 w-6 rounded-full flex justify-center items-center" :class="properties.backgroundColor" :title="properties.title">
        <component :is="properties.component" class="text-white" />
    </div>
</template>

<script>
import IconThumbsUp from '@/assets/svg/thumbs-up.svg';
import IconTimelapse from '@/assets/svg/timelapse.svg';
import IconUnavailable from '@/assets/svg/unavailable.svg';
import { STATUSES } from '@/store/models/User';

export default {
    components: {
        IconTimelapse,
        IconThumbsUp,
        IconUnavailable,
    },
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    computed: {
        properties() {
            const res = {};
            switch (this.status) {
                case STATUSES.AVAILABLE:
                    res.component = IconThumbsUp;
                    res.backgroundColor = 'bg-green';
                    res.title = 'Ledig';
                    break;
                case STATUSES.BUSY:
                    res.component = IconTimelapse;
                    res.backgroundColor = 'bg-orange';
                    res.title = 'På oppdrag';
                    break;
                case STATUSES.UNAVAILABLE:
                    res.component = IconUnavailable;
                    res.backgroundColor = 'bg-grey';
                    res.title = 'Ikke til stede';
                    break;

                default:
                    break;
            }

            return res;
        },
    },
};
</script>
