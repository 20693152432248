<template>
    <vue-final-modal
        v-slot="{ close }"
        v-bind="$attrs"
        classes="flex justify-center items-center"
        content-class="relative flex flex-col w-[600px] max-h-full mx-4 p-8 rounded bg-white"
        esc-to-close
        @opened="onOpened"
        v-on="$listeners"
    >
        <h2 class="h2 text-blue-700">Send melding til {{ user && user.name }}</h2>
        <form @submit.prevent="onClickSend" class="flex flex-col mt-8 space-y-4">
            <div class="border rounded-[4px] text-blue-600 overflow-hidden">
                <VTextInput v-model="inputValue" id="message" ref="input" />
            </div>
            <button :disabled="isSendingMessage" class="btn-blue" @click="onClickSend(close)">Send</button>
        </form>
        <div class="mt-8 overflow-y-auto">
            <p v-if="messages.length === 0" class="text-grey text-center">Ingen meldinger ennå</p>
            <ul v-else class="space-y-6">
                <li v-for="message in messages" :key="message.uuid" class="space-y-2">
                    <p class="body-sm text-grey">
                        {{ formatUnixTime(message.created_at) }}
                    </p>
                    <p class="rounded-md p-4 body bg-blue-300 text-blue-700">
                        {{ message.text }}
                    </p>
                </li>
            </ul>
        </div>
        <button class="absolute top-8 right-8 pa-2 text-blue-700" @click="close">
            <IconClose />
        </button>
    </vue-final-modal>
</template>

<script>
import { format, fromUnixTime } from 'date-fns';
import { nb } from 'date-fns/locale';
import { v4 as uuidv4 } from 'uuid';
import { VueFinalModal } from 'vue-final-modal';
import { mapActions } from 'vuex';

import IconClose from '@/assets/svg/close.svg';
import VTextInput from '@/components/VTextInput';
import { ACTIONS } from '@/store/actions';
import ChatChannel from '@/store/models/ChatChannel';
import ChatMessage from '@/store/models/ChatMessage';
import User from '@/store/models/User';

const MESSAGE_LIMIT = 3;

export default {
    components: {
        IconClose,
        VTextInput,
        VueFinalModal,
    },
    props: {
        operatorId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            inputValue: null,
            isSendingMessage: false,
        };
    },
    computed: {
        chatChannel() {
            return ChatChannel.query()
                .where((channel) => {
                    return channel.role_participants.includes('backoffice') && channel.user_participants.includes(this.operatorId);
                })
                .first();
        },
        messages() {
            if (this.chatChannel) {
                const chatMessages = ChatMessage.query()
                    .where('chat_channel_uuid', this.chatChannel.uuid)
                    .orderBy('created_at', 'desc')
                    .limit(MESSAGE_LIMIT)
                    .get();
                const unreadChatMessageUuids = [];
                for (const chatMessage of chatMessages) {
                    if (chatMessage.unread_by_roles && chatMessage.unread_by_roles.includes('backoffice')) {
                        unreadChatMessageUuids.push(chatMessage.uuid);
                    }
                }
                if (unreadChatMessageUuids.length > 0) {
                    this.markChatMessagesRead({
                        chatChannelUuid: this.chatChannel.uuid,
                        unreadChatMessageUuids,
                    });
                }
                return chatMessages;
            } else {
                return [];
            }
        },
        user() {
            return User.find(this.operatorId);
        },
    },
    methods: {
        ...mapActions({
            markChatMessagesRead: ACTIONS.MARK_CHAT_MESSAGES_READ,
            addChatMessage: ACTIONS.ADD_CHAT_MESSAGE,
        }),
        formatUnixTime(time) {
            const date = fromUnixTime(time);
            return format(date, 'HH:mm do MMMM yyyy', {
                locale: nb,
            });
        },
        async onClickSend() {
            this.isSendingMessage = true;
            const chatMessageUuid = uuidv4();
            await this.addChatMessage({
                chat_channel_uuid: this.chatChannel.uuid,
                chat_message_uuid: chatMessageUuid,
                message_text: this.inputValue,
            });
            this.isSendingMessage = false;
            this.inputValue = null;
        },
        onOpened() {
            this.$refs.input.focus();
        },
    },
};
</script>
