import { Model } from '@vuex-orm/core';

export default class TankAgreement extends Model {
    static fields() {
        return {
            id: this.number(),
            tenant_id: this.number(),
            customer_id: this.number(),
            name: this.string(),
            description: this.string(),
            contact: this.string(),
            email: this.string(),
            phone: this.string().nullable(),
            po_number: this.string(),
            active_from: this.number(),
            active_to: this.number(),
            created_at: this.number(),
            updated_at: this.number(),
        };
    }
}

TankAgreement.entity = 'tank_agreements';
TankAgreement.primaryKey = 'id';
