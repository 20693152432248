import { Model } from '@vuex-orm/core';

export default class DisposalLocation extends Model {
    static fields() {
        return {
            address: this.string(),
            description: this.string(),
            id: this.number(),
            name: this.string(),
            waste_type_ids: this.attr([]),
        };
    }
}

DisposalLocation.entity = 'disposal locations';
DisposalLocation.primaryKey = 'id';
