<template>
    <div class="relative inline-block" ref="wrapper">
        <slot name="activator" :on="{ click: onClickActivator }" />
        <div
            v-if="show"
            class="dropdown-menu-content absolute z-10"
            :class="{
                'left-0 before:left-8': alignment === ALIGNMENTS.LEFT,
                'left-1/2 transform -translate-x-1/2 before:left-1/2': alignment === ALIGNMENTS.CENTER,
                'right-0 before:right-4': alignment === ALIGNMENTS.RIGHT,
            }"
            :style="{
                top: top || null,
            }"
        >
            <div class="rounded-lg bg-white shadow overflow-hidden">
                <slot name="default" />
            </div>
        </div>
    </div>
</template>

<script>
/*
Note: If dropdown is left- or right-aligned, the caret will be positioned assuming the
activator is 64px wide. This will need to be dynamic if we need to use an activator
other than the 64px wide user avatar.
*/
export const ALIGNMENTS = {
    CENTER: 'CENTER',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
};

export default {
    data() {
        return {
            ALIGNMENTS,
            show: false,
        };
    },
    props: {
        alignment: {
            type: String,
            default: ALIGNMENTS.CENTER,
        },
        top: {
            type: String,
            default: null,
        },
    },
    methods: {
        onClickActivator() {
            this.show = !this.show;

            this.$emit(this.show ? 'opened' : 'closed');
        },
        onMousedown(event) {
            if (!this.$refs.wrapper || (!this.$refs.wrapper.contains(event.target) && this.show)) {
                this.show = false;
                this.$emit('closed');
            }
        },
    },
    mounted() {
        document.addEventListener('mousedown', this.onMousedown);
    },
    beforeDestroy() {
        document.removeEventListener('mousedown', this.onMousedown);
    },
};
</script>

<style lang="scss">
.dropdown-menu-content {
    top: calc(100% + 10px);

    &:before {
        content: '';
        position: absolute;
        top: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent white transparent;
        transform: translateX(-50%);
    }
}
</style>
