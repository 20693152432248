import { addDays, format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import { mapMutations, mapState } from 'vuex';

import { MUTATIONS } from '@/store/mutations';

export default {
    data() {
        return {
            pendingWeekRange: null,
        };
    },
    computed: {
        ...mapState(['plannerDateStart', 'plannerDateEnd', 'today']),
        calendarAttrs() {
            return [
                {
                    key: 'today',
                    dot: {
                        color: 'red',
                    },
                    dates: parseISO(this.today),
                },
            ];
        },
        plannerDateValue() {
            if (this.pendingWeekRange) {
                return this.pendingWeekRange;
            } else {
                return {
                    start: this.plannerDateStart,
                    end: this.plannerDateEnd,
                };
            }
        },
        formattedPlannerDate() {
            if (this.plannerDateStart) {
                const dateStr = format(parseISO(this.plannerDateStart), 'E do LLL', { locale: nb });
                if (this.plannerDateEnd && this.plannerDateEnd !== this.plannerDateStart) {
                    return dateStr + ' - ' + format(parseISO(this.plannerDateEnd), 'E do LLL', { locale: nb });
                }
                return dateStr;
            } else {
                return 'Dato ikke valgt';
            }
        },
        plannerDayCount() {
            const from = parseISO(this.plannerDateStart);
            const to = parseISO(this.plannerDateEnd);
            // plannerDateStart and plannerDateEnd are inclusive, so need to add 1
            return Math.round((to - from) / 1000 / 60 / 60 / 24) + 1;
        },
        plannerDates() {
            const result = [this.plannerDateStart];
            let date = this.plannerDateStart;
            while (date < this.plannerDateEnd) {
                date = format(addDays(parseISO(date), 1), 'yyyy-MM-dd', { locale: nb });
                result.push(date);
            }
            return result;
        },
        plannerDateRange() {
            return {
                start: this.plannerDateStart,
                end: this.plannerDateEnd,
            };
        },
    },
    methods: {
        ...mapMutations({
            setPlannerDate: MUTATIONS.SET_PLANNER_DATE,
        }),
        onClickNextDay() {
            this.setPlannerDate({
                start: format(addDays(parseISO(this.plannerDateStart), this.plannerDayCount >= 7 ? 7 : 1), 'yyyy-MM-dd', { locale: nb }),
                end: format(addDays(parseISO(this.plannerDateEnd), this.plannerDayCount >= 7 ? 7 : 1), 'yyyy-MM-dd', { locale: nb }),
            });
        },
        onClickPreviousDay() {
            this.setPlannerDate({
                start: format(addDays(parseISO(this.plannerDateStart), this.plannerDayCount >= 7 ? -7 : -1), 'yyyy-MM-dd', { locale: nb }),
                end: format(addDays(parseISO(this.plannerDateEnd), this.plannerDayCount >= 7 ? -7 : -1), 'yyyy-MM-dd', { locale: nb }),
            });
        },
        async onInputDate($event) {
            await this.setPlannerDate({
                start: format($event.start, 'yyyy-MM-dd', { locale: nb }),
                end: format($event.end, 'yyyy-MM-dd', { locale: nb }),
            });
            this.pendingWeekRange = null;
        },
        onInputWeek($event) {
            // Setting pendingWeekRange will cause plannerDateValue to update the selected date in the datepicker.
            // This again will cause onInputDate to be called with the new range, setting the date an extra time.
            // The pendingWeekRange roundabout will prevent the extra date change.
            this.pendingWeekRange = {
                start: format($event.days[0].date, 'yyyy-MM-dd', { locale: nb }),
                end: format($event.days[$event.days.length - 1].date, 'yyyy-MM-dd', { locale: nb }),
            };
            this.$refs.datepicker.hidePopover();
        },
    },
};
