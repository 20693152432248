<template>
    <label
        class="v-checkbox cursor-pointer flex items-center"
        :class="color === 'blue' ? (disabled ? 'text-blue-400' : 'text-blue-700') : disabled ? 'text-brown-400' : 'text-brown-700'"
    >
        <input
            type="checkbox"
            :checked="isChecked"
            :disabled="disabled"
            @change="onChange"
            class="absolute w-4 h-4 opacity-[0.00001]"
            :value="value"
            :data-key="dataKey"
            @focus="onFocus"
        />
        <svg class="w-4 h-4 mr-2 flex-none" width="32" height="32" viewBox="-4 -4 39 39" aria-hidden="true" focusable="false">
            <!-- The background -->
            <rect width="35" height="35" x="-2" y="-2" stroke="currentColor" fill="currentColor" stroke-width="3" rx="2" ry="2"></rect>
            <!-- The checkmark-->
            <polyline class="cb-cm" points="4,14 12,23 28,5" stroke="transparent" stroke-width="4" fill="none"></polyline>
        </svg>

        <span><slot name="default"></slot></span>
    </label>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: 'blue',
        },
        modelValue: {
            type: [Array, Boolean],
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dataKey: {
            type: String,
            default: null,
        },
    },
    computed: {
        isChecked() {
            if (this.modelValue instanceof Array) {
                return this.modelValue.includes(this.value);
            }

            return this.modelValue;
        },
    },
    methods: {
        onChange(event) {
            let isChecked = event.target.checked;

            if (this.modelValue instanceof Array) {
                let newValue = [...this.modelValue];
                if (isChecked) {
                    newValue.push(this.value);
                } else {
                    newValue.splice(newValue.indexOf(this.value), 1);
                }
                this.$emit('change', newValue, event);
            } else {
                this.$emit('change', isChecked, event);
            }
        },
        onFocus() {
            // For some reason, focus on this checkbox item causes #app to scroll, which should not happen, as it is overflow:hidden
            document.getElementById('app').scrollTop = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
// this component is based off of:
// https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/

// style changes inside the svg when the checkbox is checked
.v-checkbox input[type='checkbox']:checked + svg {
    .cb-cm {
        stroke: #fff;
    }
}

.v-checkbox input[type='checkbox']:focus + svg {
    box-shadow: 0 0 0 2px rgba(39, 94, 254, 0.3);
}

.v-checkbox input[type='checkbox']:focus:not(:focus-visible) + svg {
    outline: none;
}
</style>
