export const ORDER_STATUSES = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    COMPLETED: 'completed',
};

export const SUB_ORDER_STATUSES = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled',
};

export const PLANNABLE_STATUSES = {
    UNPLANNED: 'unplanned',
    PLANNED: 'planned',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
};

// Deprecated! Define this stuff in models!
export const WORK_LOG_STATUSES = {
    AVAILABLE: 'available',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    APPROVED: 'approved',
    INVOICED: 'invoiced',
};

export const PRIORITIES = {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
};
