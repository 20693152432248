import { format, fromUnixTime } from 'date-fns';
import { nb } from 'date-fns/locale';
import Vue from 'vue';

export const getNewOrUpdatedKeys = (oldMap, newMap) => {
    const result = [];
    for (const key in newMap) {
        if (!Object.prototype.hasOwnProperty.call(oldMap, key) || newMap[key] !== oldMap[key]) {
            result.push(key);
        }
    }
    return result;
};

export const getDeletedKeys = (oldMap, newMap) => {
    const result = [];
    for (const key in oldMap) {
        if (!Object.prototype.hasOwnProperty.call(newMap, key)) {
            result.push(key);
        }
    }
    return result;
};

export const capitalizeString = (string, locale = 'nb-NO') => {
    const char = string.charAt(0);
    const uppercaseChar = char.toLocaleUpperCase(locale);
    if (uppercaseChar !== char) {
        return uppercaseChar + string.substring(1);
    } else {
        return string;
    }
};

export const isString = (value) => {
    return typeof value === 'string' || value instanceof String;
};

export const applyReactiveChangesRecursively = (object, changes) => {
    applyReactiveUpdatesRecursively(object, changes.updates);
    applyReactiveDeletesRecursively(object, changes.deletes);
};

export const applyReactiveUpdatesRecursively = (object, updates) => {
    if (!updates) {
        return;
    }
    for (const key in updates) {
        const value = updates[key];
        // typeof null = 'object' so need to test separately
        if (value !== null && typeof value === 'object') {
            if (!Object.prototype.hasOwnProperty.call(object, key) || object[key] == null || typeof object[key] !== 'object') {
                Vue.set(object, key, {});
            }
            applyReactiveUpdatesRecursively(object[key], value);
        } else {
            Vue.set(object, key, value);
        }
    }
};

export const applyReactiveDeletesRecursively = (object, deletes) => {
    if (!deletes) {
        return;
    }
    for (const key in deletes) {
        const value = deletes[key];
        if (typeof value === 'object') {
            applyReactiveDeletesRecursively(object[key], value);
        } else {
            Vue.delete(object, key);
        }
    }
};

export const getCleanWorkLog = () => {
    return {
        images: {},
        videos: {},
        waste_types: {},
        work_rows: {},
        work_types: {
            leak_test: false,
            pressure_test: false,
            disinfection: false,
            laser_measurement: false,
            pipe_inspection: false,
            route_search: false,
            colorant: false,
            report: false,
            memory_stick: false,
            neutralizing: false,
            cleaning_plugs: false,
            measuring: false,
        },
        work_descriptions: {},
    };
};

export const timeToDecimal = (t) => {
    const arr = t.split(':');
    const dec = parseInt((arr[1] / 6) * 10, 10);
    return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
};

export const decimalToTime = (d) => {
    const h = Math.floor(d);
    const m = d - h;
    return `${h}`.padStart(2, '0') + ':' + `${m * 60}`.padEnd(2, '0');
};

export const timestampToTimeSlot = (d) => {
    if (!d) {
        return d;
    }
    const dateObj = new Date(d * 1000);
    let h = dateObj.getHours();
    let m = dateObj.getMinutes();
    if (m >= 45) {
        m = 0;
        h++;
    } else if (m !== 0 && m !== 30) {
        if (m >= 15) {
            m = 30;
        } else {
            m = 0;
        }
    }
    return `${h}`.padStart(2, '0') + ':' + `${m}`.padEnd(2, '0');
};

export const allTimeslots = () => {
    return Array(48)
        .fill()
        .map((e, i) => (Math.floor(i / 2) + '').padStart(2, '0') + ':' + (i % 2 === 0 ? '00' : '30'));
};

export const allTimeslotsIncludingMidnight = () => {
    return Array(49)
        .fill()
        .map((e, i) => (Math.floor(i / 2) + '').padStart(2, '0') + ':' + (i % 2 === 0 ? '00' : '30'));
};

/**
 * @param {string} time
 * @return {(boolean|string)}
 */
export const parseTime = (time) => {
    if (!time) {
        return false;
    }
    const re = new RegExp('^(\\d{1,2})[:.]?(\\d{1,2})?$');
    let match = time.match(re);
    if (!match) {
        return false;
    }
    let hourOk = false;
    let minOk = false;
    let hour, min;
    if (parseInt(match[1], 10) >= 0 && parseInt(match[1], 10) <= 23) {
        hourOk = true;
        hour = match[1].padStart(2, '0');
    }
    if (match.length > 2) {
        if (parseInt(match[2], 10) >= 0 && parseInt(match[2], 10) <= 59) {
            minOk = true;
            // to make the minutes work with timestamps, they should be floored to the half hour.
            if (parseInt(match[2], 10) < 30) {
                min = '00';
            } else {
                min = '30';
            }
        }
    } else {
        minOk = true;
        min = '00';
    }
    if (minOk && hourOk) {
        return `${hour}:${min}`;
    }
    return false;
};

export const formatUnixTime = (time) => {
    const date = fromUnixTime(time);
    return format(date, 'do MMMM yyyy', {
        locale: nb,
    });
};

// export const getDateFromTimestamp = (d) => {
//     return (new Date(d * 1000)).get
// };
