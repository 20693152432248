import { Model } from '@vuex-orm/core';

import SubOrder from './SubOrder';

export default class MapFile extends Model {
    static fields() {
        return {
            ext: this.string(),
            name: this.string(),
            needs_approval: this.boolean(),
            original_filename: this.string(),
            role: this.string(),
            sha1: this.string(),
            sub_order: this.belongsTo(SubOrder, 'sub_order_uuid'),
            sub_order_uuid: this.string(),
            uuid: this.string(),
        };
    }
}

MapFile.entity = 'map files';
MapFile.primaryKey = 'uuid';
