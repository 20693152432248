<template>
    <div
        class="p-4 rounded-lg relative flex flex-col transition-all"
        :class="{
            'w-[480px]': isExpanded,
            'w-[60px]': !isExpanded,
            'bg-brown-400': !isEmpty && color === 'brown',
            'bg-blue-400': !isEmpty && color === 'blue',
            'bg-blue-600 border border-dashed': isEmpty,
            'border-blue-400': isEmpty && color === 'blue',
            'border-brown-400': isEmpty && color === 'brown',
            'text-brown-700': !isEmpty && color === 'brown',
            'text-blue-700': !isEmpty && color === 'blue',
            'text-brown-400': isEmpty && color === 'brown',
            'text-blue-400': isEmpty && color === 'blue',
        }"
    >
        <h3 class="h3 transition-transform origin-[0.75rem_0.75rem]" :class="{ 'rotate-90': !isExpanded }">
            <slot name="header" />
        </h3>
        <template v-if="isExpanded">
            <div class="mt-6 flex-grow overflow-y-auto"><slot name="body" /></div>
            <div v-if="isEmpty" class="absolute top-1/2 left-0 right-0 text-center uppercase text-h3 font-light transform -translate-y-1/2">
                <slot name="emptyContent" />
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: true,
        },
        isEmpty: {
            type: Boolean,
            default: false,
        },
        isExpanded: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
