import { sortBy } from 'lodash';

import { PLANNABLE_STATUSES } from '@/constants';
import Plannable from '@/store/models/Plannable';

export default {
    tankPlannables: () => {
        return Plannable.query()
            .where((plannable) => {
                return !!plannable.tank_id;
            })
            .get();
    },

    tankIdToPlannablesMap: (state, getters) => {
        return getters.tankPlannables.reduce((map, plannable) => {
            if (map.has(plannable.tank_id)) {
                map.get(plannable.tank_id).push(plannable);
            } else {
                map.set(plannable.tank_id, [plannable]);
            }
            return map;
        }, new Map());
    },

    tankIdToPlannedDateMap: (state, getters) => {
        return getters.tankPlannables.reduce((map, plannable) => {
            const date = plannable.status === PLANNABLE_STATUSES.UNPLANNED || !plannable.date ? state.today : plannable.date;
            if (!map.has(plannable.tank_id) || map.get(plannable.tank_id) < date) {
                map.set(plannable.tank_id, date);
            }
            return map;
        }, new Map());
    },

    sortedTenants: (state) => {
        return sortBy(state.tenants, ['name']);
    },

    currentTenant: (state) => {
        return state.tenants ? state.tenants.find((tenant) => tenant.id === state.currentTenantId) : null;
    },
};
