<template>
    <div class="flex items-center z-20">
        <button @click="onClickPreviousDay">
            <IconCaretLeft class="text-white" />
        </button>
        <DatePicker
            ref="datepicker"
            :attributes="calendarAttrs"
            class="w-40 flex flex-shrink-0 items-center"
            locale="nb-NO"
            show-iso-weeknumbers
            is-range
            :popover="{ visibility: 'focus' }"
            :value="plannerDateValue"
            @input="onInputDate"
            @weeknumberclick="onInputWeek"
        >
            <template v-slot="{ togglePopover }">
                <button class="body w-full px-3 py-3 flex items-center text-white" @click="togglePopover">
                    <IconCalendar class="mr-2" height="24" width="24" />
                    <span>
                        {{ formattedPlannerDate }}
                    </span>
                </button>
            </template>
        </DatePicker>
        <button @click="onClickNextDay">
            <IconCaretRight class="text-white" />
        </button>
    </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

import IconCalendar from '@/assets/svg/calendar.svg';
import IconCaretLeft from '@/assets/svg/caret-left.svg';
import IconCaretRight from '@/assets/svg/caret-right.svg';
import DateNavigator from '@/mixins/DateNavigator';

export default {
    mixins: [DateNavigator],
    components: {
        IconCaretRight,
        IconCaretLeft,
        IconCalendar,
        DatePicker,
    },
    props: {},
    computed: {},
};
</script>
