<template>
    <VAnchoredDropdown @opened="onOpenedDropdown" @closed="onClosedDropdown">
        <template v-slot:activator="{ on }">
            <button v-on="on" class="align-middle">
                <IconPostit width="36px" height="36px" />
            </button>
            <span
                v-if="postitNotes.length > 0"
                class="inline-block h-6 w-6 rounded-full text-center"
                :class="hasOverduePostitNotes ? 'bg-orange' : 'bg-grey'"
            >
                <template v-if="postitNotes.length < 10">{{ postitNotes.length }}</template>
                <template v-else>9+</template>
            </span>
        </template>
        <div class="w-[1008px] flex body text-black whitespace-normal">
            <div class="w-[336px] flex-shrink-0 flex flex-col p-4">
                <ul class="flex-grow">
                    <li
                        v-for="postitNote in postitNotes"
                        :key="postitNote.id"
                        @click="onSelectPostitNote(postitNote.id)"
                        class="p-1"
                        :class="{ 'bg-yellow': postitNote.id === selectedPostitNoteId }"
                    >
                        {{ postitNote.title }}
                    </li>
                </ul>
                <button class="flex-shrink-0 small-btn-blue" @click="onCreatePostitNote">Lag ny</button>
            </div>
            <div class="flex-grow p-4 bg-yellow space-y-4">
                <template v-if="selectedPostitNote || isEditing">
                    <input
                        class="h2 block"
                        type="text"
                        :value="selectedPostitNoteValues.title"
                        :disabled="!isEditing"
                        placeholder="Tittel"
                        data-key="title"
                        @change="onUpdateInputValue"
                    />
                    <textarea
                        class="block w-full min-h-[200px]"
                        placeholder="Tekst"
                        :disabled="!isEditing"
                        data-key="text"
                        @change="onUpdateInputValue"
                        v-model="selectedPostitNoteValues.text"
                    ></textarea>
                    <DatePicker
                        v-if="isEditing"
                        :disabled="!isEditing"
                        class="block"
                        locale="nb-NO"
                        show-iso-weeknumbers
                        :popover="{ visibility: 'focus' }"
                        :value="selectedPostitNoteValues.due_date ? parseISO(selectedPostitNoteValues.due_date) : ''"
                        :min-date="new Date()"
                        @input="onUpdateDatePickerValue('due_date', $event)"
                    >
                        <template v-slot="{ inputEvents }">
                            <button class="w-full text-left" v-on="inputEvents">
                                <IconCalendar class="inline-block mr-2" />
                                <span>{{
                                    selectedPostitNoteValues?.due_date
                                        ? format(parseISO(selectedPostitNoteValues.due_date), 'dd/MM-yyyy', { locale: nb })
                                        : '-'
                                }}</span>
                                <IconWarning v-if="isPostitNoteOverdue(selectedPostitNote)" class="inline-block ml-2 text-orange" />
                            </button>
                        </template>
                    </DatePicker>
                    <div v-else>
                        <IconCalendar class="inline-block mr-2" />
                        <span>{{
                            selectedPostitNoteValues.due_date
                                ? format(parseISO(selectedPostitNoteValues.due_date), 'dd/MM-yyyy', { locale: nb })
                                : '-'
                        }}</span>
                        <IconWarning v-if="isPostitNoteOverdue(selectedPostitNote)" class="inline-block ml-2 text-orange" />
                    </div>

                    <div class="flex space-x-10">
                        <button v-if="!isEditing" @click="onStartEditingPostitNote" class="small-btn-blue">Rediger</button>
                        <button v-if="!isEditing && selectedPostitNote" @click="onCompletePostitNote" class="small-btn-blue">Utført/ferdig</button>
                        <button v-if="isEditing && hasUpdates" @click="onCommitPostitNoteUpdates" class="small-btn-blue">Lagre</button>
                        <button v-if="isEditing" @click="onCancelPostitNoteUpdates" class="small-btn-blue">Glem endringer</button>
                    </div>
                </template>
                <span v-else>Velg notat eller lag ny til venstre</span>
            </div>
        </div>
    </VAnchoredDropdown>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Vue from 'vue';
import { mapActions } from 'vuex';

import IconCalendar from '@/assets/svg/calendar.svg';
import IconPostit from '@/assets/svg/postit.svg';
import IconWarning from '@/assets/svg/warning.svg';
import VAnchoredDropdown from '@/components/VAnchoredDropdown/VAnchoredDropdown';
import { ACTIONS } from '@/store/actions';
import PostitNote from '@/store/models/PostitNote';

export default {
    components: { IconCalendar, IconPostit, IconWarning, VAnchoredDropdown, DatePicker },
    data() {
        return {
            numberOpenDropdowns: 0,
            selectedPostitNoteId: null,
            isEditing: false,
            isSaving: false,
            updates: {},
            nb,
        };
    },
    computed: {
        selectedPostitNote() {
            return this.selectedPostitNoteId ? PostitNote.find(this.selectedPostitNoteId) : null;
        },
        selectedPostitNoteValues() {
            if (this.isEditing) {
                return {
                    title: Object.prototype.hasOwnProperty.call(this.updates, 'title')
                        ? this.updates.title
                        : this.selectedPostitNote
                        ? this.selectedPostitNote.title
                        : '',
                    text: Object.prototype.hasOwnProperty.call(this.updates, 'text')
                        ? this.updates.text
                        : this.selectedPostitNote
                        ? this.selectedPostitNote.text
                        : '',
                    due_date: Object.prototype.hasOwnProperty.call(this.updates, 'due_date')
                        ? this.updates.due_date
                        : this.selectedPostitNote
                        ? this.selectedPostitNote.due_date
                        : '',
                };
            } else {
                return this.selectedPostitNote;
            }
        },
        hasUpdates() {
            for (const key in this.updates) {
                // If any keys exists, assume it's an update
                return true;
            }
            return false;
        },
        postitNotes() {
            return PostitNote.all();
        },
        hasOverduePostitNotes() {
            const todayStr = format(new Date(), 'yyyy-MM-dd');
            return this.postitNotes.find((item) => item.due_date != null && item.due_date < todayStr);
        },
    },
    methods: {
        ...mapActions({
            createPostitNote: ACTIONS.CREATE_POSTIT_NOTE,
            updatePostitNote: ACTIONS.UPDATE_POSTIT_NOTE,
        }),
        isPostitNoteOverdue(postitNote) {
            const todayStr = format(new Date(), 'yyyy-MM-dd');
            return postitNote && postitNote.due_date != null && postitNote.due_date < todayStr;
        },
        onUpdateInputValue(event) {
            const key = event.target.attributes['data-key'].value;
            const value = event.target.value;
            Vue.set(this.updates, key, value);
        },
        onUpdateDatePickerValue(key, event) {
            const value = format(event, 'yyyy-MM-dd');
            Vue.set(this.updates, key, value);
        },
        onClosedDropdown() {
            this.numberOpenDropdowns--;
        },
        onOpenedDropdown() {
            this.numberOpenDropdowns++;
        },
        onCreatePostitNote() {
            this.selectedPostitNoteId = null;
            this.isEditing = true;
        },
        async onCompletePostitNote() {
            try {
                await this.updatePostitNote({ postitNoteId: this.selectedPostitNote.id, updates: { done: true } });
            } finally {
                this.selectedPostitNoteId = null;
            }
        },
        onSelectPostitNote(postitNoteId) {
            this.selectedPostitNoteId = postitNoteId;
        },
        onStartEditingPostitNote() {
            this.isEditing = true;
        },
        onCancelPostitNoteUpdates() {
            this.isEditing = false;
            this.updates = {};
        },
        async onCommitPostitNoteUpdates() {
            console.log('commitPostitUpdates');
            this.isSaving = true;
            try {
                if (this.selectedPostitNote) {
                    await this.updatePostitNote({
                        postitNoteId: this.selectedPostitNote.id,
                        updates: { ...this.updates },
                    });
                } else {
                    const postitNote = await this.createPostitNote({ ...this.updates });
                    this.selectedPostitNoteId = postitNote.id;
                }
            } finally {
                this.isEditing = false;
                this.isSaving = false;
                this.updates = {};
            }
        },
        parseISO,
        format,
    },
};
</script>
