<template>
    <div @drop="onDrop" :class="anyOperatorRefused ? 'outline outline-2 outline-offset-[-2px] outline-red' : ''">
        <CardTank
            v-if="tank"
            :tank="tank"
            :plannable="plannable"
            :loading="loading"
            @show-operators="$emit('show-operators')"
            @show-work-logs="$emit('show-work-logs')"
        />
        <CardSubOrder
            v-else-if="subOrder"
            :subOrder="subOrder"
            :plannable="plannable"
            :loading="loading"
            @show-operators="$emit('show-operators')"
            @show-work-logs="$emit('show-work-logs')"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import CardSubOrder from '@/components/future/CardSubOrder';
import CardTank from '@/components/future/CardTank';
import { PLANNABLE_STATUSES } from '@/constants';
import { ACTIONS } from '@/store/actions';

export default {
    components: {
        CardTank,
        CardSubOrder,
    },
    props: {
        plannable: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            loading: {
                operators: false,
                executionOrder: false,
            },
        };
    },
    computed: {
        tank() {
            return this.plannable.tank;
        },
        subOrder() {
            return this.plannable.sub_order;
        },
        acceptExecutionOrderDrop() {
            return this.plannable.status !== PLANNABLE_STATUSES.COMPLETED;
        },
        anyOperatorRefused() {
            const operators = Object.values(this.plannable.assigned_operators);
            return operators.some((operator) => operator.refused_at);
        },
    },
    methods: {
        ...mapActions({
            assignOperators: ACTIONS.ASSIGN_PLANNABLE_OPERATORS,
            setPlannableOperatorExecutionOrder: ACTIONS.SET_PLANNABLE_OPERATOR_EXECUTION_ORDER,
        }),
        /*
        Note: we set @dragover.prevent and @dragenter.prevent in <CardTamplate />
        because for some reason it's required, as described:
        https://learnvue.co/2020/01/how-to-add-drag-and-drop-to-your-vuejs-project/
        */
        async onDrop(event) {
            if (event.dataTransfer.getData('operatorId')) {
                // This is a drop of an operator onto the card
                const operatorId = Number(event.dataTransfer.getData('operatorId'));

                const currentOperatorIds = Object.keys(this.plannable.assigned_operators).map((key) => Number(key));

                if (currentOperatorIds.includes(operatorId)) {
                    return;
                }

                this.loading.operators = true;
                await this.assignOperators({
                    plannableId: this.plannable.id,
                    operatorIds: currentOperatorIds.concat(operatorId),
                });
                this.loading.operators = false;
            } else if (this.acceptExecutionOrderDrop && event.dataTransfer.getData('executionOrder')) {
                // This is a drop of execution order possibly onto an operator
                const executionOrder = Number(event.dataTransfer.getData('executionOrder'));
                const operatorRecipientNode = event.target.closest('.execution-order-recipient');
                if (operatorRecipientNode && operatorRecipientNode.dataset.operator_id) {
                    let operatorIdStr = operatorRecipientNode.dataset.operator_id;
                    const operatorId = Number(operatorIdStr);

                    this.loading.executionOrder = true;
                    await this.setPlannableOperatorExecutionOrder({
                        plannableId: this.plannable.id,
                        operatorId,
                        executionOrder,
                    });
                    this.loading.executionOrder = false;
                }
            }
        },
    },
};
</script>
