import { Model } from '@vuex-orm/core';

export default class WasteType extends Model {
    static fields() {
        return {
            description: this.string(),
            id: this.number(),
            name: this.string(),
            disposal_location_ids: this.attr([]),
        };
    }
}

WasteType.entity = 'waste types';
WasteType.primaryKey = 'id';
