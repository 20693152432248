import { Model } from '@vuex-orm/core';

import SubOrder from './SubOrder';

export default class DiggingDeclarationFile extends Model {
    static fields() {
        return {
            ext: this.string(),
            name: this.string(),
            needs_approval: this.boolean(),
            original_filename: this.string(),
            role: this.string(),
            sha1: this.string(),
            sub_order: this.belongsTo(SubOrder, 'sub_order_uuid'),
            sub_order_uuid: this.string(),
            uuid: this.string(),
        };
    }
}

DiggingDeclarationFile.entity = 'digging declaration files';
DiggingDeclarationFile.primaryKey = 'uuid';
