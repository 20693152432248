<template>
    <div class="relative flex-grow h-20">
        <div class="absolute top-0 right-0 left-0">
            <!--
            Normally if an element has overflow-x: auto, any y overflow will be hidden.
            To get around this so that the dropdowns aren't cut off by hidden overflow,
            we give the scrollable element a large height while a dropdown is open.
            -->
            <div class="flex space-x-4 pr-12 pl-2 whitespace-nowrap overflow-x-auto py-2" :class="{ 'h-screen': numberOpenDropdowns > 0 }">
                <VDropdownMenu
                    v-for="(operator, i) in operators"
                    :key="operator.id"
                    :alignment="getDropdownAlignmentForIndex(i)"
                    top="74px"
                    @opened="onOpenedDropdown"
                    @closed="onClosedDropdown"
                >
                    <template v-slot:activator="{ on }">
                        <button v-on="on" draggable @dragstart="onDragStart($event, operator.id)">
                            <VAvatar :size="64" :title="operator.name">
                                <template v-slot:badge-tr>
                                    <BadgeStatusOperator :status="operator.status" />
                                </template>
                                <template v-slot:badge-br v-if="operator.unread_chat_message_count">
                                    <span class="inline-block h-6 w-6 rounded-full bg-orange text-center text-white">
                                        <template v-if="operator.unread_chat_message_count < 10">
                                            {{ operator.unread_chat_message_count }}
                                        </template>
                                        <template v-else>9+</template>
                                    </span>
                                </template>
                                <template v-slot:badge-bl>
                                    <span
                                        @click.stop="togglePlannerFilterUserId(operator.id)"
                                        class="h-6 w-6 rounded-full flex justify-center items-center bg-gray text-black"
                                        :class="plannerFilterUserIds.includes(operator.id) ? 'bg-green' : 'bg-grey'"
                                        :title="plannerFilterUserIds.includes(operator.id) ? 'Fjern filter' : 'Filtrér'"
                                        ><IconFilter class="text-white" /></span
                                ></template>
                                {{ operator.initials }}
                            </VAvatar>
                        </button>
                    </template>

                    <!-- POPUP -->
                    <div class="popup">
                        <VAvatar class="mx-auto" :size="64">
                            {{ operator.initials }}
                        </VAvatar>
                        <div class="flex items-center justify-center mt-4 space-x-2">
                            <p class="h3 text-blue-700">{{ operator.name }}</p>
                            <BadgeStatusOperator :status="operator.status" />
                        </div>
                        <p class="body-sm mt-2" :class="statuses[operator.status].color">
                            {{ statuses[operator.status].text }}
                        </p>
                        <div v-for="dl in operator.work_logs" :key="dl.uuid" class="flex items-center mx-4 mt-2">
                            <BadgeStatusWorkLog :status="dl.status" />
                            <div class="ml-2 text-left whitespace-normal body-compact">
                                <template v-if="dl.sub_order">
                                    {{ $t('sub_order.type.' + dl.sub_order.type_id) }}, {{ dl.sub_order.customer }}<br />
                                    {{ dl.sub_order.address }}
                                </template>
                                <template v-else-if="dl.tank">
                                    Avtaleoppdrag,
                                    {{ dl.tank.address }}
                                    {{ dl.tank.customer }}
                                </template>
                                <template v-else> Ukjent</template>
                            </div>
                        </div>
                        <div v-if="departmentUserSchedulesByUserId" class="divide-y-1 mt-6">
                            <div v-for="(departmentUserSchedule, i) in departmentUserSchedulesByUserId[operator.id]" :key="i">
                                <div
                                    v-if="!departmentUserSchedule.department_id"
                                    class="flex items-center mx-4 hover:bg-blue-300 cursor-pointer"
                                    @click="onClickMenuEdit(departmentUserSchedule)"
                                >
                                    <IconAccountClock class="w-8 h-8 inline-block flex-grow-0 mr-2" />
                                    <div class="flex-1 text-left">
                                        <div>{{ departmentUserSchedule.reason || 'Fravær' }}</div>
                                        <div>{{ departmentUserSchedule.getFullReadableDateRange() }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="divide-y-1 mt-2 mx-4 py-2 text-left cursor-pointer hover:bg-blue-300" @click="onClickMenuNew(operator)">
                            Meld fravær
                            <IconCaretRight class="inline-block right-0" />
                        </div>
                        <div class="divide-y-1 divide-blue-400 mt-6 border-t border-blue-400">
                            <button
                                class="w-full bg-blue-300 hover:bg-blue-400 text-blue-600 py-5 px-6 flex items-center"
                                @click="onClickSendMessage(operator.id)"
                            >
                                <IconSpeechBubble class="mr-4 text-blue-600" />
                                Send melding
                            </button>
                        </div>
                    </div>
                </VDropdownMenu>
            </div>
            <VModalMessages :operator-id="currentOperatorId" v-model="isModalOpen" @closed="onClosedModal" />
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import IconAccountClock from '@/assets/svg/account-clock.svg';
import IconCaretRight from '@/assets/svg/caret-right.svg';
import IconFilter from '@/assets/svg/filter.svg';
import IconSpeechBubble from '@/assets/svg/speech-bubble.svg';
import BadgeStatusOperator from '@/components/future/BadgeStatusOperator';
import BadgeStatusWorkLog from '@/components/future/BadgeStatusWorkLog';
import VAvatar from '@/components/VAvatar';
import VDropdownMenu, { ALIGNMENTS } from '@/components/VDropdownMenu';
import VModalMessages from '@/components/VModalMessages';
import User from '@/store/models/User';
import { MUTATIONS } from '@/store/mutations';

export default {
    components: {
        BadgeStatusOperator,
        BadgeStatusWorkLog,
        IconAccountClock,
        IconCaretRight,
        IconFilter,
        IconSpeechBubble,
        VAvatar,
        VDropdownMenu,
        VModalMessages,
    },
    data() {
        return {
            currentOperatorId: null,
            isModalOpen: false,
            numberOpenDropdowns: 0,
            statuses: {
                unavailable: {
                    text: 'Ikke til stede',
                    color: 'text-grey',
                },
                busy: {
                    text: 'På oppdrag',
                    color: 'text-orange',
                },
                available: {
                    text: 'Ledig',
                    color: 'text-green',
                },
            },
        };
    },
    props: {
        departmentUserSchedulesByUserId: {
            type: Object,
            required: false,
        },
    },
    computed: {
        ...mapState(['plannerFilterUserIds']),
        operators() {
            return User.all()
                .filter((user) => {
                    return user.is_operator;
                })
                .sort((a, b) => {
                    if (a.sort !== b.sort) {
                        return a.sort > b.sort ? 1 : -1;
                    }
                    return 0;
                });
        },
    },
    methods: {
        ...mapMutations({
            togglePlannerFilterUserId: MUTATIONS.TOGGLE_PLANNER_FILTER_USER_ID,
        }),
        getDropdownAlignmentForIndex(index) {
            if (index < 2) {
                return ALIGNMENTS.LEFT;
            } else if (index > Math.max(3, this.operators.length - 3)) {
                return ALIGNMENTS.RIGHT;
            } else {
                return ALIGNMENTS.CENTER;
            }
        },
        onClickSendMessage(operatorId) {
            this.currentOperatorId = operatorId;
            this.isModalOpen = true;
        },
        onClosedDropdown() {
            this.numberOpenDropdowns--;
        },
        onClosedModal() {
            this.currentOperatorId = null;
        },
        onOpenedDropdown() {
            this.numberOpenDropdowns++;
        },
        onDragStart(event, operatorId) {
            event.dataTransfer.effectAllowed = 'link';
            event.dataTransfer.setData('operatorId', operatorId);
        },
        onClickMenuEdit(departmentUserSchedule) {
            // not including date
            this.$emit('menuEdit', departmentUserSchedule);
        },
        onClickMenuNew(operator) {
            this.$emit('menuNew', {
                user_id: operator.id,
                user: operator,
            });
        },
    },
};
</script>

<style scoped>
.popup {
    width: 336px;
    max-height: 70vh;
    padding-top: 1.5rem;
    text-align: center;
    overflow: scroll;
}
</style>
