var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-3",class:{ 'bg-red-300': _vm.isInvalid }},[_c('div',{staticClass:"ml-6 body-sm h-4"},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]})],2),_c('vue-select',_vm._b({staticClass:"v-select-component body",class:{
            'v-select--blue': _vm.color === 'blue',
            'v-select--brown': _vm.color === 'brown',
            'v-select--invalid': _vm.isInvalid,
        },attrs:{"components":{ Deselect: _vm.IconClose },"input-id":_vm.inputId,"options":_vm.isPaginated ? _vm.paginatedOptions : _vm.options,"filterable":!_vm.isPaginated,"placeholder":_vm.placeholder,"value":_vm.value,"selectOnTab":true},on:{"input":_vm.onInput,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"open-indicator",fn:function({ attributes }){return [_c('span',_vm._b({class:{
                    'text-blue-700': _vm.color === 'blue',
                    'text-brown-700': _vm.color === 'brown',
                }},'span',attributes,false),[_c('IconCaretDown')],1)]}},{key:"selected-option-container",fn:function({ deselect, multiple, option }){return [_vm._t("selected-option-container",function(){return [(multiple)?_c('div',{staticClass:"body p-2 space-x-2 border rounded-[4px] mx-1 mb-1 flex items-center",class:{
                        'text-blue-700 bg-blue-300': _vm.color === 'blue',
                        'text-brown-700 bg-brown-300': _vm.color === 'brown',
                    }},[_c('span',[_vm._v(_vm._s(option.label))]),_c('button',{attrs:{"type":"button","formnovalidate":""},on:{"click":function($event){return deselect(option)}}},[_c('IconClose')],1)]):_c('div',{staticClass:"vs__selected"},[_vm._v(_vm._s(option.label))])]},{"deselect":deselect,"multiple":multiple,"option":option})]}},{key:"selected-option",fn:function(option){return [_vm._t("selected-option",function(){return [_vm._v(" "+_vm._s(option.label)+" bubble ")]},{"option":option})]}},{key:"option",fn:function(option){return [_vm._t("option",function(){return [_vm._v(" "+_vm._s(option.label)+" ")]},{"option":option})]}}],null,true)},'vue-select',{
            clearable: false,
            taggable: true,
            ..._vm.$attrs,
        },false),[(_vm.isPaginated && _vm.options.length > _vm.paginationLimit)?_c('li',{staticClass:"flex w-full",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('button',{staticClass:"text-body h-14 m-2 flex-grow px-0",class:_vm.color === 'blue' ? 'btn-blue' : 'btn-brown',attrs:{"type":"button","formnovalidate":"","disabled":!_vm.hasPrevPage},on:{"click":function($event){_vm.pageOffset -= _vm.paginationLimit}}},[_vm._v(" Prev ")]),_c('button',{staticClass:"text-body h-14 m-2 flex-grow px-0",class:_vm.color === 'blue' ? 'btn-blue' : 'btn-brown',attrs:{"type":"button","formnovalidate":"","disabled":!_vm.hasNextPage},on:{"click":function($event){_vm.pageOffset += _vm.paginationLimit}}},[_vm._v(" Next ")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }