import './index.css';

import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import LoadScript from 'vue-plugin-load-script';
import Toasted from 'vue-toasted';
import { firestorePlugin } from 'vuefire';

import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import { ACTIONS } from './store/actions';

Vue.config.productionTip = false;

Vue.use(LoadScript);
Vue.use(VueAxios, axios);
Vue.use(Toasted, {
    className: '!bg-red-300 !text-red-600',
    duration: 3000,
    keepOnHover: true,
    position: 'bottom-center',
});

const firebaseApp = initializeApp({
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
});
console.log('main.js', firebaseApp);
const firestoreDB = getFirestore();
Vue.use(firestorePlugin);
// const settings = {
//     timestampsInSnapshots: true,
// };
// firestore.settings(settings);
Vue.prototype.$firestoreDB = firestoreDB;
Vue.prototype.$firestoreRoot = null;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

/*
This action fires an API call which will redirect us to the login page in the case of a
401 response
*/
store.dispatch(ACTIONS.LOAD_USER);
