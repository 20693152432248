import sha1 from 'sync-sha1/sha1';
import Vue from 'vue';

export const MUTATIONS = {
    SET_TODAY: 'SET_TODAY',
    SET_USER: 'SET_USER',
    SET_REVISIONS_DOCUMENT: 'SET_REVISIONS_DOCUMENT',
    SET_CURRENTLY_DRAGGED_PLANNABLE_TUPLE: 'SET_CURRENTLY_DRAGGED_TIME_SPAN_UUID',
    SET_CURRENTLY_RESIZING_PLANNABLE_TUPLE: 'SET_CURRENTLY_RESIZING_PLANNABLE_TUPLE',
    SET_FAKE_TIME_SPANS_FOR_PLANNABLE: 'SET_FAKE_TIME_SPANS_FOR_PLANNABLE',
    SET_DRAG_DESTINATION_DEPARMENT_ID: 'SET_DRAG_DESTINATION_DEPARMENT_ID',
    SET_HIGHLIGHTED_PLANNABLE: 'SET_HIGHLIGHTED_PLANNABLE',
    OBJECT_MAP_LOADED: 'OBJECT_MAP_LOADED',
    UPDATE_REVISION_MAP_ENTRIES: 'UPDATE_REVISION_MAP_ENTRIES',
    DELETE_REVISION_MAP_ENTRIES: 'DELETE_REVISION_MAP_ENTRIES',
    SET_PLANNER_DATE: 'SET_PLANNER_DATE',
    TOGGLE_PLANNER_FILTER_USER_ID: 'TOGGLE_PLANNER_FILTER_USER_ID',
    RESET_PLANNER_FILTER_USER_IDS: 'RESET_PLANNER_FILTER_USER_IDS',
    TOGGLE_PLANNER_COLUMN_EXPANDED: 'TOGGLE_PLANNER_COLUMN_EXPANDED',
    UPDATE_CURRENT_TENANT_ID: 'UPDATE_CURRENT_TENANT_ID',
    UPDATE_COMPLETED_WORK_LOG_COUNT: 'UPDATE_COMPLETED_WORK_LOG_COUNT',
};

/**
 * Assuming a revision map [$id => $revision, ...]
 * Converting it to string `$id=$revision,...`
 * And return sha1 of that string
 */
export const getRevisionsHash = (revisions) => {
    let str = '';
    for (const key in revisions) {
        str += key + '=' + revisions[key] + ',';
    }
    str = str.slice(0, -1);
    const hash = sha1(str).toString('hex');
    return hash;
};

export default {
    [MUTATIONS.SET_TODAY](state, today) {
        state.today = today;
    },
    [MUTATIONS.SET_USER](state, data) {
        state.user = data.user;
        state.tenant = data.tenant;
        state.tenants = data.tenants;
        state.modules = data.modules;
        state.currentTenantId = state.tenant.id;
        state.config = data.config;
        localStorage.setItem('currentTenantId', state.tenant.id);
    },
    [MUTATIONS.OBJECT_MAP_LOADED](state) {
        state.isObjectsLoaded = true;
    },
    [MUTATIONS.UPDATE_CURRENT_TENANT_ID](state, tenantId) {
        localStorage.setItem('currentTenantId', tenantId);
        state.currentTenantId = tenantId;
        window.location.reload();
    },
    [MUTATIONS.SET_REVISIONS_DOCUMENT](state, data) {
        state.revisionsDocument = data;
    },
    [MUTATIONS.DELETE_REVISION_MAP_ENTRIES](state, { type, keys }) {
        for (const key of keys) {
            Vue.delete(state.revisionMaps[type], key);
        }
        state.revisionHashes[type] = getRevisionsHash(state.revisionMaps[type]);
    },
    [MUTATIONS.UPDATE_REVISION_MAP_ENTRIES](state, { type, map }) {
        for (const key in map) {
            Vue.set(state.revisionMaps[type], key, map[key]);
        }
        state.revisionHashes[type] = getRevisionsHash(state.revisionMaps[type]);
    },
    [MUTATIONS.SET_PLANNER_DATE](state, { start, end }) {
        state.plannerDateStart = start;
        state.plannerDateEnd = end;
    },
    [MUTATIONS.TOGGLE_PLANNER_FILTER_USER_ID](state, userId) {
        const index = state.plannerFilterUserIds.indexOf(userId);
        if (index < 0) {
            state.plannerFilterUserIds.push(userId);
        } else {
            state.plannerFilterUserIds.splice(index, 1);
        }
    },
    [MUTATIONS.RESET_PLANNER_FILTER_USER_IDS](state) {
        state.plannerFilterUserIds.splice(0, state.plannerFilterUserIds.length);
    },
    [MUTATIONS.TOGGLE_PLANNER_COLUMN_EXPANDED](state, columnKey) {
        state.plannerColumnsExpanded[columnKey] = !state.plannerColumnsExpanded[columnKey];
    },
    [MUTATIONS.UPDATE_COMPLETED_WORK_LOG_COUNT](state, count) {
        state.completedWorkLogCount = count;
    },
    [MUTATIONS.SET_CURRENTLY_DRAGGED_PLANNABLE_TUPLE](state, plannableTuple) {
        state.plannerCurrentlyDraggedPlannableTuple = plannableTuple;
    },
    [MUTATIONS.SET_DRAG_DESTINATION_DEPARMENT_ID](state, departmentId) {
        state.plannerDragDestinationDepartmentId = departmentId;
    },
    [MUTATIONS.SET_HIGHLIGHTED_PLANNABLE](state, plannable) {
        state.plannerHighlightedPlannable = plannable;
    },
    [MUTATIONS.SET_FAKE_TIME_SPANS_FOR_PLANNABLE](state, { plannableId, timeSpan }) {
        if (timeSpan) {
            Vue.set(state.plannerFakeTimeSpansForPlannables, plannableId, timeSpan);
        } else {
            Vue.delete(state.plannerFakeTimeSpansForPlannables, plannableId);
        }
    },
    [MUTATIONS.SET_CURRENTLY_RESIZING_PLANNABLE_TUPLE](state, plannableTuple) {
        state.plannerCurrentlyResizingPlannableTuple = plannableTuple;
    },
};
