<template>
    <VSelect
        v-bind="{
            clearable: true,
            multiple: true,
            label: 'Velg operatør',
            placeholder: '-',
            taggable: false,
            ...$attrs,
        }"
        :options="options"
        :value="value"
        v-on="$listeners"
    >
        <template v-slot:option="{ option }">
            <div class="flex items-center w-full">
                <VAvatar background="bg-white" :size="50">
                    {{ option.initials }}
                </VAvatar>
                <span class="ml-4">{{ option.label }}</span>
                <BadgeStatusOperator class="ml-auto" :status="option.status" />
            </div>
        </template>
    </VSelect>
</template>

<script>
import BadgeStatusOperator from '@/components/future/BadgeStatusOperator';
import VAvatar from '@/components/VAvatar';
import VSelect from '@/components/VSelect';
import User from '@/store/models/User';

export default {
    components: {
        VAvatar,
        BadgeStatusOperator,
        VSelect,
    },
    props: {
        assignedOperatorIds: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        options() {
            return User.all().map((user) => ({
                initials: user.initials,
                label: user.name,
                status: user.status,
                value: user.id,
            }));
        },
        value() {
            return this.assignedOperatorIds.map((assignedOperatorId) => this.options.find((option) => option.value === assignedOperatorId));
        },
    },
};
</script>
