export const EQUIPMENT_OPTIONS = {
    CRAWLER_CAMERA: {
        label: 'Kameratraktor',
        value: 'crawler_camera',
    },
    INSPECTION_CAMERA: {
        label: 'Stakekamera',
        value: 'inspection_camera',
    },
    K: {
        label: 'Kombi',
        value: 'K',
    },
    MK: {
        label: 'Mini-kombi',
        value: 'MK',
    },
    OTHER: {
        label: 'Annet',
        value: 'other',
    },
    S: {
        label: 'Slamsuger',
        value: 'S',
    },
    SCANNING_MANHOLES: {
        label: 'Skanning kummer',
        value: 'scanning_manholes',
    },
    SCANNING_PIPE: {
        label: 'Skanning rør',
        value: 'scanning_pipe',
    },
};

export const MAPPING_OF_OPTIONS = {
    DISTRICT_HEATING: { label: 'Fjernvarme', value: 'district_heating' },
    DRAINAGE: { label: 'Drenering', value: 'drainage' },
    GAS: { label: 'Gass', value: 'gas' },
    OTHER: { label: 'Annet', value: 'other' },
    PUMP_LINE: { label: 'Pumpeledning', value: 'pump_line' },
    ROOF_WATER: { label: 'Takvann', value: 'roof_water' },
    SURFACE_WATER: { label: 'Overvann', value: 'surface_water' },
    VACUUM_DRAIN: { label: 'Vakuumrenne', value: 'vacuum_drain' },
    WASTE_WATER: { label: 'Spillvann', value: 'waste_water' },
    WATER: { label: 'Vann', value: 'water' },
};

export default {
    repeat: [
        {
            label: 'Aldri',
            value: 0,
        },
        {
            label: 'Daglig/ukedager',
            value: 1,
        },
        {
            label: 'Annenhver uke',
            value: 14,
        },
        {
            label: '1 måned',
            value: 30,
        },
        {
            label: '2 måneder',
            value: 60,
        },
        {
            label: '3 måneder',
            value: 90,
        },
        {
            label: '4 måneder',
            value: 120,
        },
        {
            label: '5 måneder',
            value: 150,
        },
        {
            label: '6 måneder',
            value: 180,
        },
        {
            label: '1 år',
            value: 360,
        },
    ],
    sub_category_2: [
        {
            label: 'Høytrykk',
            value: 'high_pressure',
        },
        {
            label: 'Steam',
            value: 'steam',
        },
        {
            label: 'UHT',
            value: 'uht',
        },
    ],
    type_id: [
        {
            label: 'Rørinspeksjon',
            mappingOfOptions: [
                MAPPING_OF_OPTIONS.SURFACE_WATER,
                MAPPING_OF_OPTIONS.WASTE_WATER,
                MAPPING_OF_OPTIONS.ROOF_WATER,
                MAPPING_OF_OPTIONS.DRAINAGE,
                MAPPING_OF_OPTIONS.OTHER,
            ],
            subCategoryOptions: [
                {
                    equipmentOptions: [
                        EQUIPMENT_OPTIONS.CRAWLER_CAMERA,
                        EQUIPMENT_OPTIONS.INSPECTION_CAMERA,
                        EQUIPMENT_OPTIONS.SCANNING_PIPE,
                        EQUIPMENT_OPTIONS.SCANNING_MANHOLES,
                        EQUIPMENT_OPTIONS.OTHER,
                    ],
                    label: 'Kartlegging',
                    value: 'mapping',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.CRAWLER_CAMERA, EQUIPMENT_OPTIONS.INSPECTION_CAMERA],
                    label: 'Trasésøk',
                    value: 'route_search',
                },
                {
                    equipmentOptions: [
                        EQUIPMENT_OPTIONS.CRAWLER_CAMERA,
                        EQUIPMENT_OPTIONS.INSPECTION_CAMERA,
                        EQUIPMENT_OPTIONS.SCANNING_PIPE,
                        EQUIPMENT_OPTIONS.SCANNING_MANHOLES,
                        EQUIPMENT_OPTIONS.OTHER,
                    ],
                    label: 'Tilstandskontroll',
                    value: 'condition_check',
                },
                {
                    equipmentOptions: [
                        EQUIPMENT_OPTIONS.CRAWLER_CAMERA,
                        EQUIPMENT_OPTIONS.INSPECTION_CAMERA,
                        EQUIPMENT_OPTIONS.SCANNING_PIPE,
                        EQUIPMENT_OPTIONS.SCANNING_MANHOLES,
                        EQUIPMENT_OPTIONS.OTHER,
                    ],
                    label: 'Sluttkontroll',
                    value: 'final_check',
                },
                {
                    equipmentOptions: [
                        EQUIPMENT_OPTIONS.CRAWLER_CAMERA,
                        EQUIPMENT_OPTIONS.INSPECTION_CAMERA,
                        EQUIPMENT_OPTIONS.SCANNING_PIPE,
                        EQUIPMENT_OPTIONS.SCANNING_MANHOLES,
                        EQUIPMENT_OPTIONS.OTHER,
                    ],
                    label: 'Anleggskontroll',
                    value: 'facility_check',
                },
            ],
            value: 'inspection',
        },
        {
            label: 'Spyling',
            mappingOfOptions: [
                MAPPING_OF_OPTIONS.SURFACE_WATER,
                MAPPING_OF_OPTIONS.WASTE_WATER,
                MAPPING_OF_OPTIONS.ROOF_WATER,
                MAPPING_OF_OPTIONS.DRAINAGE,
                MAPPING_OF_OPTIONS.VACUUM_DRAIN,
                MAPPING_OF_OPTIONS.OTHER,
            ],
            subCategoryOptions: [
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K],
                    label: 'Storspyling',
                    value: 'large_flushing',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.MK],
                    label: 'Kjøkkenavløp',
                    value: 'kitchen_drains',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.MK],
                    label: 'Stikkledning',
                    value: 'branch_line',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.MK],
                    label: 'Fasadevask',
                    value: 'facade_cleaning',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.MK],
                    label: 'Tankvask',
                    value: 'tank_cleaning',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK],
                    label: 'Rotkutting',
                    value: 'root_cutting',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK],
                    label: 'Rustspyling',
                    value: 'rust_flushing',
                },
            ],
            value: 'flushing',
        },
        {
            label: 'Slamsuging',
            subCategoryOptions: [
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'Septik',
                    value: 'septic',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'Fettutskiller',
                    value: 'fat_separator',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'Organsisk annet',
                    value: 'organic_other',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'Oljeholdig masse',
                    value: 'oily_mass',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'Oljeholdig flytende',
                    value: 'oily_liquid',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'Ren masse/sand',
                    value: 'sand',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'Gjødsel',
                    value: 'fertilizer',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'ADR',
                    value: 'adr',
                },
                {
                    equipmentOptions: [EQUIPMENT_OPTIONS.K, EQUIPMENT_OPTIONS.MK, EQUIPMENT_OPTIONS.S, EQUIPMENT_OPTIONS.OTHER],
                    label: 'Avtaleoppdrag',
                    value: 'tank_report',
                },
                {
                    equipmentOptions: [],
                    label: 'Annet',
                    value: 'other',
                },
            ],
            value: 'pumping',
        },
        {
            label: 'Supersuger',
            subCategoryOptions: [
                {
                    equipmentOptions: [],
                    label: 'Sug masse',
                    value: 'mass_suction',
                },
                {
                    equipmentOptions: [],
                    label: 'Sug væske',
                    value: 'liquid_suction',
                },
                {
                    equipmentOptions: [],
                    label: 'Blåsing',
                    value: 'blowing',
                },
                {
                    equipmentOptions: [],
                    label: 'Tankvask',
                    value: 'tank_cleaning',
                },
                {
                    equipmentOptions: [],
                    label: 'Annet',
                    value: 'other',
                },
            ],
            value: 'super_suction',
        },
        {
            label: 'Vakuumgraving',
            subCategoryOptions: [
                {
                    equipmentOptions: [],
                    label: 'Graving',
                    value: 'digging',
                },
                {
                    equipmentOptions: [],
                    label: 'Masseforflytting',
                    value: 'mass_transfer',
                },
                {
                    equipmentOptions: [],
                    label: 'Rengjøring',
                    value: 'cleaning',
                },
                {
                    equipmentOptions: [],
                    label: 'Annet',
                    value: 'other',
                },
            ],
            value: 'vacuum_digging',
        },
        {
            label: 'Rørfornying',
            mappingOfOptions: [MAPPING_OF_OPTIONS.SURFACE_WATER, MAPPING_OF_OPTIONS.WASTE_WATER, MAPPING_OF_OPTIONS.OTHER],
            subCategoryOptions: [
                {
                    equipmentOptions: [],
                    label: 'Service',
                    value: 'service',
                },
                {
                    equipmentOptions: [],
                    label: 'Punkt',
                    value: 'point',
                },
                {
                    equipmentOptions: [],
                    label: 'Prosjekt',
                    value: 'project',
                },
                {
                    equipmentOptions: [],
                    label: 'Annet',
                    value: 'other',
                },
            ],
            value: 'post_renewal',
        },
        {
            label: 'Trykkprøving',
            mappingOfOptions: [
                MAPPING_OF_OPTIONS.SURFACE_WATER,
                MAPPING_OF_OPTIONS.WASTE_WATER,
                MAPPING_OF_OPTIONS.WATER,
                MAPPING_OF_OPTIONS.DISTRICT_HEATING,
                MAPPING_OF_OPTIONS.GAS,
                MAPPING_OF_OPTIONS.PUMP_LINE,
                MAPPING_OF_OPTIONS.OTHER,
            ],
            subCategoryOptions: [
                {
                    equipmentOptions: [],
                    label: 'Trykkprøving',
                    value: 'pressure_test',
                },
                {
                    equipmentOptions: [],
                    label: 'Tetthetsprøving',
                    value: 'leak_test',
                },
                {
                    equipmentOptions: [],
                    label: 'Desinfisering',
                    value: 'disinfection',
                },
                {
                    equipmentOptions: [],
                    label: 'Nøytralisering',
                    value: 'neutralizing',
                },
                {
                    equipmentOptions: [],
                    label: 'Rensing',
                    value: 'cleansing',
                },
                {
                    equipmentOptions: [],
                    label: 'Vannprøve',
                    value: 'water_sample',
                },
                {
                    equipmentOptions: [],
                    label: 'Annet',
                    value: 'other',
                },
            ],
            value: 'pressure_testing',
        },
    ],
};
