import { Model } from '@vuex-orm/core';

export const STATUSES = {
    AVAILABLE: 'available',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    APPROVED: 'approved',
};

export default class WorkLog extends Model {
    static fields() {
        return {
            uuid: this.string(),
            status: this.string(),
            date: this.string(),
            user_id: this.number(),
            plannable_id: this.number(),
            sub_order_uuid: this.string().nullable(),
            tank_id: this.number().nullable(),

            tenant_id: this.number(),
            address1: this.string(),
            address2: this.string(),
            revision: this.number(),
            created_by_user_id: this.number(),
            updated_by_user_id: this.number(),
            department: this.attr(null),
            fixed_price: this.boolean(),
            customer_no_report: this.boolean(),
            po_number: this.string(),

            additional_operator_count: this.number(),
            accepted_at: this.number(),
            started_at: this.number(),
            completed_at: this.number(),
            updated_by: this.string(),
            created_at: this.number(),
            updated_at: this.number(),

            waste_types: this.attr({}),
            work_rows: this.attr({}),
            work_types: this.attr({}),

            work_descriptions: this.attr({}),

            note: this.string(),

            signatures: this.attr({}),
            images: this.attr({}),
            videos: this.attr({}),
        };
    }

    getStatusTime() {
        if (this.status === STATUSES.IN_PROGRESS) {
            return this.started_at;
        } else if (this.status === STATUSES.COMPLETED) {
            return this.completed_at;
        } else {
            return null;
        }
    }
}

WorkLog.entity = 'work_log';
WorkLog.primaryKey = 'uuid';
