import { Model } from '@vuex-orm/core';

import File from './File';

export default class Order extends Model {
    static fields() {
        return {
            assignment_address: this.string().nullable(),
            assignment_comment: this.string().nullable(),
            assignment_contacts: this.attr({}),
            attachments: this.hasMany(File, 'order_uuid'),
            created_at: this.string().nullable(),
            created_by_user_name: this.string(),
            created_by_user_id: this.number(),
            customer: this.attr({}),
            has_separate_assignment_address: this.boolean(),
            invoice_contact: this.attr({}),
            json: this.string().nullable(),
            po_number: this.string().nullable(),
            revision: this.number(),
            sequence_no: this.number(),
            status: this.string(),
            sub_order_uuids: this.attr(),
            updated_at: this.string().nullable(),
            uuid: this.string(),
        };
    }

    getAssignmentAddress() {
        let address = this.has_separate_assignment_address ? this.assignment_address : null;
        if (!address) {
            address = this.invoice_contact?.address;
        }
        return address;
    }
}

Order.entity = 'orders';
Order.primaryKey = 'uuid';
