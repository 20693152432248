<template>
    <vue-final-modal
        v-slot="{ close }"
        v-bind="$attrs"
        classes="flex justify-center items-center"
        content-class="relative flex flex-col w-[1000px] max-h-full mx-4 p-8 rounded bg-white"
        esc-to-close
        v-on="$listeners"
    >
        <template v-if="departmentUserSchedule && editingDepartmentUserSchedule">
            <h3 class="h3 mb-2">
                <template v-if="isEditingDepartmentStatus">
                    <template v-if="isNew">
                        Legg til regel for
                        {{ departmentUserSchedule.department.label || departmentUserSchedule.department.name }}
                    </template>
                    <template v-else>
                        Endre regel for
                        {{ departmentUserSchedule.department.label || departmentUserSchedule.department.name }}
                    </template>
                </template>
                <template v-else-if="isEditingUserStatus">
                    <template v-if="isNew"> Legg til fravær for {{ departmentUserSchedule.user.name }}</template>
                    <template v-else> Endre fravær for {{ departmentUserSchedule.user.name }}</template>
                </template>
                <template v-else-if="isEditingUserDepartmentRelation">
                    <template v-if="isNew">
                        Midlertidig flytting av {{ departmentUserSchedule.user.name }} til
                        {{ departmentUserSchedule.department.label || departmentUserSchedule.department.name }}
                    </template>
                    <template v-else>
                        Endre flytting av {{ departmentUserSchedule.user.name }} til
                        {{ departmentUserSchedule.department.label || departmentUserSchedule.department.name }}
                    </template>
                </template>
            </h3>
            <div class="flex flex-col-reverse items-left">
                <div class="flex inline-flex items-center">
                    Til:
                    <DatePicker
                        ref="datepicker"
                        :attributes="calendarAttrs"
                        class="w-40 flex flex-shrink-0 items-center"
                        locale="nb-NO"
                        show-iso-weeknumbers
                        :popover="{ visibility: 'focus' }"
                        :value="editingDepartmentUserSchedule.toDate"
                        :tabindex="3"
                        @input="onInputDateTo"
                    >
                        <template v-slot="{ togglePopover }">
                            <button class="body w-full px-3 py-3 flex items-center text-black" @click="togglePopover">
                                <IconCalendar class="mr-2" height="24" width="24" />
                                <span>
                                    {{ editingDepartmentUserSchedule.toDate }}
                                </span>
                            </button>
                        </template>
                    </DatePicker>
                    <span
                        ><vue-select
                            class="w-48 inline-block"
                            v-model="editingDepartmentUserSchedule.toTime"
                            :options="allTimeslotsIncludingMidnight"
                            name="to"
                            :tabindex="4"
                    /></span>
                </div>
                <div class="flex inline-flex items-center z-20">
                    Fra:
                    <DatePicker
                        ref="datepicker"
                        :attributes="calendarAttrs"
                        class="w-40 flex flex-shrink-0 items-center"
                        locale="nb-NO"
                        show-iso-weeknumbers
                        :popover="{ visibility: 'focus' }"
                        :value="editingDepartmentUserSchedule.fromDate"
                        :tabindex="1"
                        @input="onInputDateFrom"
                    >
                        <template v-slot="{ togglePopover }">
                            <button class="body w-full px-3 py-3 flex items-center text-black" @click="togglePopover">
                                <IconCalendar class="mr-2" height="24" width="24" />
                                <span>
                                    {{ editingDepartmentUserSchedule.fromDate }}
                                </span>
                            </button>
                        </template>
                    </DatePicker>
                    <span
                        ><vue-select
                            class="w-48 inline-block"
                            v-model="editingDepartmentUserSchedule.fromTime"
                            :options="allTimeslotsIncludingMidnight"
                            name="from"
                            :tabindex="2"
                    /></span>
                </div>
            </div>
            <div class="hidden">ID: {{ departmentUserSchedule.id }}</div>
            <div class="hidden">Prioritet: {{ departmentUserSchedule.priority }}</div>
            <label class="mt-2"
                >Begrunnelse:
                <input
                    class="ml-2 border border-grey rounded py-1 px-2"
                    tabindex="5"
                    v-model="editingDepartmentUserSchedule.reason"
                    type="text"
                    name="reason"
                />
            </label>
            <div class="hidden">Type: {{ departmentUserSchedule.type }}</div>

            <div class="mt-4">
                <button
                    v-if="!isNew"
                    class="small-btn-red float-right"
                    @click="
                        deleteDepartmentUserSchedule(departmentUserSchedule);
                        close();
                    "
                >
                    <IconDelete class="inline-block" />
                    Slett denne
                </button>

                <button
                    class="small-btn-blue"
                    @click="
                        saveDepartmentUserSchedule();
                        close();
                    "
                >
                    Lagre
                </button>
            </div>
        </template>

        <button class="absolute top-8 right-8 pa-2 text-blue-700" @click="close">
            <IconClose />
        </button>
    </vue-final-modal>
</template>

<script>
import { format, formatISO9075, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';
import { mapActions, mapState } from 'vuex';

import IconCalendar from '@/assets/svg/calendar.svg';
import IconClose from '@/assets/svg/close.svg';
import IconDelete from '@/assets/svg/delete.svg';
import { ACTIONS } from '@/store/actions';
// import VSelect from '@/components/VSelect';
import { allTimeslotsIncludingMidnight, timestampToTimeSlot } from '@/util';

export default {
    components: {
        DatePicker,
        IconCalendar,
        IconClose,
        IconDelete,
        VueFinalModal,
        VueSelect,
    },
    data() {
        return {
            editingDepartmentUserSchedule: null,
            showDepartmentInfo: false,
        };
    },
    watch: {
        departmentUserSchedule(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.prepareEditor();
            }
        },
    },
    props: {
        departmentUserSchedule: {
            type: Object,
            required: false,
        },
        currentDate: {
            type: String,
            required: false,
        },
    },
    computed: {
        ...mapState(['tenant', 'today']),
        isNew() {
            return !this.departmentUserSchedule?.id;
        },
        isEditingUserStatus() {
            return !this.departmentUserSchedule?.department_id && this.departmentUserSchedule?.user_id;
        },
        isEditingDepartmentStatus() {
            return this.departmentUserSchedule?.department_id && !this.departmentUserSchedule?.user_id;
        },
        isEditingUserDepartmentRelation() {
            return this.departmentUserSchedule?.department_id && this.departmentUserSchedule?.user_id;
        },
        formatISO9075,
        allTimeslotsIncludingMidnight,
        calendarAttrs() {
            return [
                {
                    key: 'today',
                    dot: {
                        color: 'red',
                    },
                    dates: parseISO(this.today),
                },
            ];
        },
    },
    methods: {
        ...mapActions({
            deleteDepartmentUserScheduleAction: ACTIONS.DELETE_DEPARTMENT_USER_SCHEDULE,
            createDepartmentUserScheduleAction: ACTIONS.CREATE_DEPARTMENT_USER_SCHEDULE,
            updateDepartmentUserScheduleAction: ACTIONS.UPDATE_DEPARTMENT_USER_SCHEDULE,
        }),
        timestampToTimeSlot,
        onInputDateFrom(event) {
            this.editingDepartmentUserSchedule.fromDate = format(event, 'yyyy-MM-dd', { locale: nb });
        },
        onInputDateTo(event) {
            this.editingDepartmentUserSchedule.toDate = format(event, 'yyyy-MM-dd', { locale: nb });
        },
        async deleteDepartmentUserSchedule(departmentUserSchedule) {
            if (departmentUserSchedule && departmentUserSchedule.id) {
                await this.deleteDepartmentUserScheduleAction(departmentUserSchedule.id);
            }
        },
        saveDepartmentUserSchedule() {
            // TODO: Priority should be actually calculated
            const priority = 1;
            if (this.isNew) {
                this.createDepartmentUserScheduleAction({
                    from: new Date(this.editingDepartmentUserSchedule.fromDate + ' ' + this.editingDepartmentUserSchedule.fromTime).getTime() / 1000,
                    to: new Date(this.editingDepartmentUserSchedule.toDate + ' ' + this.editingDepartmentUserSchedule.toTime).getTime() / 1000,
                    user_id: this.departmentUserSchedule.user_id,
                    department_id: this.departmentUserSchedule?.department?.id || null,
                    reason: this.editingDepartmentUserSchedule.reason,
                    type: this.editingDepartmentUserSchedule.type,
                    priority,
                    tenant_id: this.tenant.id,
                });
            } else {
                this.updateDepartmentUserScheduleAction({
                    id: this.departmentUserSchedule.id,
                    values: {
                        from:
                            new Date(this.editingDepartmentUserSchedule.fromDate + ' ' + this.editingDepartmentUserSchedule.fromTime).getTime() /
                            1000,
                        to: new Date(this.editingDepartmentUserSchedule.toDate + ' ' + this.editingDepartmentUserSchedule.toTime).getTime() / 1000,
                        user_id: this.departmentUserSchedule.user_id,
                        department_id: this.departmentUserSchedule?.department?.id || null,
                        reason: this.editingDepartmentUserSchedule.reason,
                        type: this.editingDepartmentUserSchedule.type,
                        priority,
                        tenant_id: this.tenant.id,
                    },
                });
            }
        },
        prepareEditor() {
            this.editingDepartmentUserSchedule = {
                fromDate: (this.departmentUserSchedule?.from && this.departmentUserSchedule.getDateFrom()) || this.currentDate,
                toDate: (this.departmentUserSchedule?.to && this.departmentUserSchedule.getDateTo()) || this.currentDate,
                fromTime: (this.departmentUserSchedule?.from && this.departmentUserSchedule.getTimeSlotFrom()) || '00:00',
                toTime: (this.departmentUserSchedule?.to && this.departmentUserSchedule.getTimeSlotTo()) || '24:00',
                reason: this.departmentUserSchedule?.reason || '',
                type: this.departmentUserSchedule?.type || '',
            };
        },
    },
};
</script>

<style scoped lang="scss"></style>
