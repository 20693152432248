import { Model } from '@vuex-orm/core';

import AdditionalFile from './AdditionalFile';
import AnalyzedFile from './AnalyzedFile';
import DiggingDeclarationFile from './DiggingDeclarationFile';
import MapFile from './MapFile';

export default class SubOrder extends Model {
    static fields() {
        return {
            access_instructions: this.string(),
            additional_files: this.hasMany(AdditionalFile, 'sub_order_uuid'),
            additional_information: this.string(),
            address: this.string().nullable(),
            altitude_meters: this.string(),
            amount: this.string(),
            amount_unit: this.string(),
            analyzed: this.boolean(),
            analyzed_files: this.hasMany(AnalyzedFile, 'sub_order_uuid'),
            assigned_operators: this.attr({}),
            blowing: this.boolean(),
            created_at: this.string().nullable(),
            customer: this.string().nullable(),
            date: this.string().nullable(),
            date_end: this.string().nullable(),
            declared: this.boolean(),
            digging_declaration: this.boolean(),
            digging_declaration_files: this.hasMany(DiggingDeclarationFile, 'sub_order_uuid'),
            dimension: this.string(),
            disposal_location: this.number(),
            dry_mass: this.string(),
            equipment: this.string().nullable(),
            equipment_other_description: this.string().nullable(),
            estimated_duration: this.string().nullable(),
            execution_period: this.string().nullable(),
            flushing_instructions: this.string(),
            flushing: this.boolean(),
            gap_measurement: this.boolean(),
            has_map: this.boolean(),
            hose_dimension: this.string(),
            hose_laying: this.string(),
            inspection: this.boolean(),
            is_access_instructions_unknown: this.boolean(),
            is_dimension_unknown: this.boolean(),
            is_length_unknown: this.boolean(),
            length: this.string(),
            map_files: this.hasMany(MapFile, 'sub_order_uuid'),
            map_instructions: this.string(),
            mapping_of: this.string(),
            mapping_other_description: this.string(),
            order_sequence_no: this.number(),
            order_uuid: this.string(),
            price_per_m3: this.string().nullable(),
            price_type: this.string().nullable(),
            repeat: this.number(),
            repeat_weekdays: this.string(),
            revision: this.number(),
            should_send_report: this.boolean(),
            sort_order: this.number().nullable(),
            start_time: this.string().nullable(),
            status: this.string(),
            sub_category: this.string(),
            sub_category_2: this.string(),
            title: this.string(),
            tower_car: this.boolean(),
            type_id: this.string(),
            updated_at: this.string().nullable(),
            urgency: this.string().nullable(),
            uuid: this.string(),
            waste_type: this.number(),
        };
    }
}

SubOrder.entity = 'sub_orders';
SubOrder.primaryKey = 'uuid';
