<template>
    <CardTemplate
        :header="tank.address"
        task="Avtaletømming"
        :customer="tank.customer"
        :date="plannable && plannable.date ? plannable.date : tank.date"
        :priority="tank.priority"
        :plannable="plannable"
        :loading="loading"
        @show-operators="$emit('show-operators')"
        @show-work-logs="$emit('show-work-logs')"
    >
        <template v-slot:filter-icons>
            <component :is="tankTypeIcon" class="inline-block w-4 h-4" />
        </template>

        <template v-slot:link>
            <a :href="tankAgreementUrl" target="_blank">
                #{{ tank.agreement_id }}
                <IconOpenInNew class="inline-block w-[16px]" />
            </a>
        </template>
    </CardTemplate>
</template>

<script>
import IconFatSeparator from '@/assets/svg/fat-separator.svg';
import IconOilSeparator from '@/assets/svg/oil-separator.svg';
import IconOpenInNew from '@/assets/svg/open-in-new.svg';
import IconUnknownType from '@/assets/svg/unknown-type.svg';
import CardTemplate from '@/components/future/CardTemplate';

export default {
    components: {
        CardTemplate,
        IconOilSeparator,
        IconOpenInNew,
        IconFatSeparator,
        IconUnknownType,
    },
    props: {
        tank: {
            type: Object,
            default: function () {
                return {};
            },
        },
        plannable: {
            type: Object,
        },
        loading: {
            type: Object,
        },
    },
    computed: {
        tankTypeIcon() {
            switch (this.tank.type) {
                case 'oil_separator':
                    return IconOilSeparator;
                case 'fat_separator':
                    return IconFatSeparator;
                default:
                    return IconUnknownType;
            }
        },
        tankAgreementUrl() {
            return process.env.VUE_APP_ADMIN_URL + '/admin/tankAgreement/' + this.tank.agreement_id;
        },
    },
};
</script>
