<template>
    <div>
        <h3 class="h3 mt-4">Ordre</h3>
        <dl class="order_information_dl">
            <dt>Kunde</dt>
            <dd>{{ order?.customer?.name }}</dd>
            <dt>Oppdragsadresse</dt>
            <dd v-if="order.has_separate_assignment_address && order.assignment_address">
                {{ order.assignment_address }}
            </dd>
            <dd v-else>{{ order.invoice_contact?.address }}</dd>
            <dt>Kommentar</dt>
            <dd>{{ order.assignment_comment }}</dd>
        </dl>
    </div>
</template>

<script>
export default {
    name: 'WorkLogOrderInfo',
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss"></style>
