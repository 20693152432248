<template>
    <div class="flex flex-col h-full overflow-hidden relative" id="app" v-if="user">
        <template v-if="tenantHasPlannerModule && user && tenant && (user.admin || tenant.pivot.is_admin)">
            <the-header class="flex-shrink-0" @openCompletedWorkLogsModal="isCompletedWorkLogsModalOpen = true" />
            <router-view v-if="isObjectsLoaded" :key="$route.fullPath" />
            <template v-else>
                <div style="height: calc(50vh - 40px)"></div>
                <IconLoader width="80" height="80" class="text-blue-400 mx-auto align-middle animate-spin" />
            </template>
            <VModalWorkLogs
                :statusList="[WORK_LOG_STATUSES.COMPLETED, WORK_LOG_STATUSES.APPROVED]"
                v-model="isCompletedWorkLogsModalOpen"
                :loadCompletedWorkLogs="isCompletedWorkLogsModalOpen"
            />
        </template>
        <template v-else>
            <main class="px-4 md:px-12 py-4 md:py-14 h-full overflow-auto">
                <section>
                    <div class="container-xl-plus">
                        <div class="bg-blue-400 rounded-lg px-5 py-4 text-blue-600 text-center">
                            <h1 class="h1">Tilgang mangler</h1>
                            <h2 v-if="tenantHasPlannerModule" class="mt-8">{{ user.name }} har ikke tilgang til VA Planner</h2>
                            <h2 v-else class="mt-8">{{ tenant.name }} har ikke tilgang til VA Planner</h2>
                            <a :href="adminUrl" class="inline-block mt-8 underline">Gå tilbake til Zapit Admin</a>
                            <template v-if="sortedTenants.length > 1">
                                <p class="my-2">.. eller bytt til:</p>
                                <ul>
                                    <template v-for="selectableTenant in sortedTenants">
                                        <li :key="selectableTenant.id" v-if="selectableTenant.id !== tenant.id">
                                            <a href @click.prevent="updateCurrentTenantId(selectableTenant.id)">{{ selectableTenant.name }}</a>
                                        </li>
                                    </template>
                                </ul>
                            </template>
                        </div>
                    </div>
                </section>
            </main>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import IconLoader from '@/assets/svg/loader.svg';
import TheHeader from '@/components/TheHeader';
import VModalWorkLogs from '@/components/VModalWorkLogs';
import { WORK_LOG_STATUSES } from '@/constants';
import { MUTATIONS } from '@/store/mutations';

export default {
    data() {
        return {
            isCompletedWorkLogsModalOpen: false,
            WORK_LOG_STATUSES: WORK_LOG_STATUSES,
        };
    },
    computed: {
        ...mapState(['user', 'modules', 'tenant', 'isObjectsLoaded']),
        ...mapGetters(['sortedTenants']),
        tenantHasPlannerModule() {
            return this.modules != null && this.modules.some((module) => module.id === 'planner');
        },
        adminUrl() {
            return process.env.VUE_APP_ADMIN_URL;
        },
    },
    methods: {
        ...mapMutations({
            updateCurrentTenantId: MUTATIONS.UPDATE_CURRENT_TENANT_ID,
        }),
    },
    components: {
        TheHeader,
        VModalWorkLogs,
        IconLoader,
    },
};
</script>
