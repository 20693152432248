import { Model } from '@vuex-orm/core';

export default class PostitNote extends Model {
    static fields() {
        return {
            id: this.number(),
            user_id: this.number(),
            title: this.string(),
            text: this.string(),
            due_date: this.string().nullable(),
            done_at: this.number().nullable(),
            created_at: this.number(),
            updated_at: this.number(),
        };
    }
}

PostitNote.entity = 'postit_notes';
PostitNote.primaryKey = 'id';
