<template>
    <div v-if="currentWorkLog" class="flex-auto overflow-auto flex flex-col">
        <div class="mb-4">
            {{ currentWorkLog.address1 }},
            {{ currentWorkLog.address2 }}
        </div>
        <div v-if="currentWorkLog.invoiced_at !== null" class="h3 mb-8">
            <IconInvoice width="32" class="inline" /> Fakturert
            <span v-if="currentWorkLog?.order_integration_id === '24seven'"> - Ordrenr i 24SevenOffice: {{ currentWorkLog?.order_remote_id }}</span>
            <a v-if="printableWorkLogUrl" class="float-right" :href="printableWorkLogUrl" target="_blank"><IconPrint /></a>
        </div>
        <div v-else-if="isEditing" class="mb-8">
            <button @click="cancelEditing" class="small-btn-blue">Glem endringer</button>
            <button v-if="hasUpdates" @click="saveUpdates" class="ml-4 small-btn-blue">Lagre endringer</button>
        </div>
        <div v-else class="mb-8 flex-grow-0">
            <button v-if="currentWorkLog.invoiced_at === null" @click="startEditing" class="small-btn-blue">Rediger</button>
            <button v-if="currentWorkLog.invoiced_at === null" @click="deleteItem" class="ml-4 small-btn-blue">Slett</button>
            <button
                v-if="!tenantHas24SevenIntegration && currentWorkLog.status === 'approved' && currentWorkLog.invoiced_at === null"
                @click="setInvoiced"
                class="ml-4 small-btn-green"
            >
                Sett fakturert
            </button>
            <button v-if="currentWorkLog.status === 'completed'" @click="approve" class="ml-4 small-btn-blue">Godkjenn</button>
            <button v-if="currentWorkLog.status === 'completed'" @click="start" class="ml-4 small-btn-blue">Tilbake til operatør</button>
            <button v-if="currentWorkLog.status === 'available' || currentWorkLog.status === 'assigned'" @click="start" class="ml-4 small-btn-blue">
                Start
            </button>
            <button v-if="currentWorkLog.status === 'in_progress'" @click="complete" class="ml-4 small-btn-blue">Ferdigstill</button>
            <a v-if="printableWorkLogUrl" class="float-right" :href="printableWorkLogUrl" target="_blank"><IconPrint /></a>
        </div>
        <div v-if="currentWorkLog" class="overflow-auto mb-4">
            <div class="flex mb-4">
                <span class="flex-grow-0 mr-2">Merking:</span>
                <input type="text" :value="currentWorkLog.po_number" :disabled="!isEditing" :data-key="'po_number'" @input="onUpdateInputValue" />
            </div>
            <div class="flex">
                <div>
                    Avdeling<br />
                    <select :disabled="!isEditing" data-key="department" @change="onUpdateDepartmentValue">
                        <option value="">Ingen avdeling valgt</option>
                        <option
                            v-for="department in departments"
                            :key="department.id"
                            :value="department.id"
                            :selected="currentWorkLog.department && currentWorkLog.department.id === department.id"
                        >
                            {{ department.code + ' - ' + department.name }}
                        </option>
                    </select>
                </div>
                <div class="self-end">
                    <v-checkbox
                        class="ml-8"
                        :model-value="currentWorkLog.fixed_price"
                        :disabled="!isEditing"
                        data-key="fixed_price"
                        @change="onUpdateCheckboxValue"
                    >
                        Oppmøte</v-checkbox
                    >
                </div>
                <div class="self-end">
                    <v-checkbox
                        class="ml-8"
                        :model-value="currentWorkLog.customer_no_report"
                        :disabled="!isEditing"
                        data-key="customer_no_report"
                        @change="onUpdateCheckboxValue"
                    >
                        Kunde ønsker ikke rapport</v-checkbox
                    >
                </div>
            </div>
            <div class="flex">
                <div>
                    Hjelpemenn<br />
                    <select :disabled="!isEditing" data-key="additional_operator_count" @change="onUpdateAdditionalOperatorCountValue">
                        <option :value="0" :selected="currentWorkLog.additional_operator_count === 0">0</option>
                        <option :value="1" :selected="currentWorkLog.additional_operator_count === 1">1</option>
                        <option :value="2" :selected="currentWorkLog.additional_operator_count === 2">2</option>
                        <option :value="3" :selected="currentWorkLog.additional_operator_count === 3">3</option>
                        <option :value="4" :selected="currentWorkLog.additional_operator_count === 4">4</option>
                    </select>
                </div>
                <div v-if="isEditing" class="ml-8">
                    Legg til fraksjon<br />
                    <select @change="onAddWasteTypeFromSelect">
                        <option value="">-- Velg --</option>
                        <option v-for="wasteType in availableWasteTypes" :key="wasteType.id" :value="wasteType.id">
                            {{ wasteType.name }}
                        </option>
                    </select>
                </div>
            </div>
            <table class="work-log__work_rows_table w-full">
                <tr>
                    <th>Dato</th>
                    <th v-for="wasteTypeKey in Object.keys(currentWorkLog.waste_types)" :key="wasteTypeKey">
                        {{ currentWorkLog.waste_types[wasteTypeKey].name }}
                        <select
                            class="w-full"
                            :value="currentWorkLog.waste_types[wasteTypeKey].product_no"
                            v-if="isEditing"
                            :data-key="'waste_types.' + wasteTypeKey + '.product_no'"
                            @change="onUpdateSelectValue"
                        >
                            <option value="">-- Velg --</option>
                            <option v-for="product in products" :key="product.id" :value="product.no">{{ product.no }} / {{ product.name }}</option>
                        </select>
                        <div v-else>{{ currentWorkLog.waste_types[wasteTypeKey].product_no }}</div>
                        <div v-if="isEditing"><IconDelete style="margin: 0 auto" @click="onDeleteWasteType(wasteTypeKey)" /></div>
                    </th>
                    <th>Totalt<br />Timer</th>
                    <th>Hvorav<br />+50%</th>
                    <th>Hvorav<br />+100%</th>
                </tr>
                <tbody v-for="(workRow, workRowUuid) in currentWorkLog.work_rows" :key="workRowUuid" class="border-t-2">
                    <tr>
                        <td class="whitespace-nowrap">
                            <DatePicker
                                v-if="isEditing"
                                :disabled="!isEditing"
                                class=""
                                locale="nb-NO"
                                show-iso-weeknumbers
                                :popover="{ visibility: 'focus' }"
                                :value="parseISO(workRow.date)"
                                :max-date="new Date()"
                                @input="onUpdateDatePickerValue('work_rows.' + workRowUuid + '.date', $event)"
                            >
                                <template v-slot="{ inputEvents }">
                                    <button class="w-full text-left" v-on="inputEvents">
                                        <span>{{ workRow.date ? format(parseISO(workRow.date), 'dd/MM-yyyy', { locale: nb }) : '-' }}</span>
                                    </button>
                                </template>
                            </DatePicker>
                            <span v-else>{{ workRow.date ? format(parseISO(workRow.date), 'dd/MM-yyyy', { locale: nb }) : '-' }}</span>
                        </td>
                        <td v-for="wasteTypeKey in Object.keys(currentWorkLog.waste_types)" :key="wasteTypeKey">
                            <input
                                type="text"
                                class="w-full min-w-[60px]"
                                :value="workRow.waste_amounts ? workRow.waste_amounts[wasteTypeKey] : ''"
                                :disabled="!isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.waste_amounts.' + wasteTypeKey"
                                @input="onUpdateInputValue"
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                class="w-full min-w-[60px]"
                                :value="workRow.hours_1"
                                :disabled="!isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.hours_1'"
                                @input="onUpdateInputValue"
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                class="w-full min-w-[60px]"
                                :value="workRow.hours_1_50pct"
                                :disabled="!isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.hours_1_50pct'"
                                @input="onUpdateInputValue"
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                class="w-full min-w-[60px]"
                                :value="workRow.hours_1_100pct"
                                :disabled="!isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.hours_1_100pct'"
                                @input="onUpdateInputValue"
                            />
                        </td>
                        <td v-if="isEditing" style="border-width: 0"><IconDelete @click="onDeleteWorkRow(workRowUuid)" /></td>
                    </tr>
                    <tr v-for="i in currentWorkLog.additional_operator_count" :key="'additional_operator_' + i">
                        <th :colspan="1 + Object.keys(currentWorkLog.waste_types).length" class="text-right whitespace-nowrap">
                            Hjelpemann {{ i }}:
                        </th>
                        <td>
                            <input
                                type="number"
                                class="w-full min-w-[60px]"
                                :value="workRow['hours_' + (i + 1)]"
                                :disabled="!isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.hours_' + (i + 1)"
                                @input="onUpdateInputValue"
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                class="w-full min-w-[60px]"
                                :value="workRow['hours_' + (i + 1) + '_50pct']"
                                :disabled="!isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.hours_' + (i + 1) + '_50pct'"
                                @input="onUpdateInputValue"
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                class="w-full min-w-[60px]"
                                :value="workRow['hours_' + (i + 1) + '_100pct']"
                                :disabled="!isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.hours_' + (i + 1) + '_100pct'"
                                @input="onUpdateInputValue"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-right">Notat:</th>
                        <td :colspan="3 + Object.keys(currentWorkLog.waste_types).length" :title="workRow.note">
                            <input
                                type="text"
                                class="w-full"
                                :value="workRow.note"
                                :disabled="!isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.note'"
                                @input="onUpdateInputValue"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="text-right">Produkt:</th>
                        <td :colspan="3 + Object.keys(currentWorkLog.waste_types).length">
                            <select
                                class="w-full"
                                :value="workRow.product_no"
                                v-if="isEditing"
                                :data-key="'work_rows.' + workRowUuid + '.product_no'"
                                @change="onUpdateSelectValue"
                            >
                                <option value="">-- Velg --</option>
                                <option v-for="product in products" :key="product.id" :value="product.no">
                                    {{ product.no }} / {{ product.name }}
                                </option>
                            </select>
                            <span v-else>{{ workRow.product_no }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button v-if="isEditing" @click="onAddWorkRow">
                <IconPlusCircle />
            </button>
            <table class="work-log__work_types_table">
                <tr v-for="(r, rowIndex) in 3" :key="rowIndex">
                    <td v-for="(c, colIndex) in 4" :key="colIndex">
                        <v-checkbox
                            :model-value="currentWorkLog.work_types[workTypes[colIndex * 3 + rowIndex].key]"
                            :disabled="!isEditing"
                            :data-key="'work_types.' + workTypes[colIndex * 3 + rowIndex].key"
                            @change="onUpdateCheckboxValue"
                        >
                            {{ workTypes[colIndex * 3 + rowIndex].label }}</v-checkbox
                        >
                    </td>
                </tr>
            </table>
            <table class="work-log__work_descriptions_table">
                <tr>
                    <th>OV/SP/AF</th>
                    <th>Materiale</th>
                    <th>Dimensjon</th>
                    <th>Ant. meter</th>
                    <th>Fra kum</th>
                    <th>Til kum</th>
                    <th>Tilleggsarb.</th>
                    <th>Avvik</th>
                    <th>Notat</th>
                </tr>
                <tr v-for="(workDescription, uuid) in currentWorkLog.work_descriptions" :key="uuid">
                    <td>
                        <select
                            class="w-full min-w-[60px]"
                            :value="workDescription.type"
                            v-if="isEditing"
                            :data-key="'work_descriptions.' + uuid + '.type'"
                            @change="onUpdateSelectValue"
                        >
                            <option>OV</option>
                            <option>SP</option>
                            <option>AF</option>
                        </select>
                        <span v-else>{{ workDescription.type }}</span>
                    </td>
                    <td>
                        <select
                            class="w-full min-w-[60px]"
                            :value="workDescription.material"
                            v-if="isEditing"
                            :data-key="'work_descriptions.' + uuid + '.material'"
                            @change="onUpdateSelectValue"
                        >
                            <option v-for="value in materialOptions" :key="value">{{ value }}</option>
                        </select>
                        <span v-else>{{ workDescription.material }}</span>
                    </td>
                    <td>
                        <input
                            type="text"
                            class="w-full min-w-[60px]"
                            :value="workDescription.dimension"
                            :disabled="!isEditing"
                            :data-key="'work_descriptions.' + uuid + '.dimension'"
                            @input="onUpdateInputValue"
                        />
                    </td>
                    <td class="text-right">
                        <input
                            type="text"
                            class="w-full min-w-[60px] text-right"
                            pattern="[0-9]+([.,][0-9]+)?"
                            :value="workDescription.length"
                            :disabled="!isEditing"
                            :data-key="'work_descriptions.' + uuid + '.length'"
                            @input="onUpdateInputValue"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="w-full min-w-[60px]"
                            :value="workDescription.from"
                            :disabled="!isEditing"
                            :data-key="'work_descriptions.' + uuid + '.from'"
                            @input="onUpdateInputValue"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            class="w-full min-w-[60px]"
                            :value="workDescription.to"
                            :disabled="!isEditing"
                            :data-key="'work_descriptions.' + uuid + '.to'"
                            @input="onUpdateInputValue"
                        />
                    </td>
                    <td style="text-align: center">
                        <v-checkbox
                            style="display: inline-block; vertical-align: middle; margin-left: 0.5rem"
                            :model-value="workDescription.extra"
                            :disabled="!isEditing"
                            :data-key="'work_descriptions.' + uuid + '.extra'"
                            @change="onUpdateCheckboxValue"
                        ></v-checkbox>
                    </td>
                    <td style="text-align: center">
                        <v-checkbox
                            style="display: inline-block; vertical-align: middle; margin-left: 0.5rem"
                            :model-value="workDescription.deviation"
                            :disabled="!isEditing"
                            :data-key="'work_descriptions.' + uuid + '.deviation'"
                            @change="onUpdateCheckboxValue"
                        ></v-checkbox>
                    </td>
                    <td :title="workDescription.note" class="w-full">
                        <input
                            type="text"
                            class="w-full"
                            :value="workDescription.note"
                            :disabled="!isEditing"
                            :data-key="'work_descriptions.' + uuid + '.note'"
                            @input="onUpdateInputValue"
                        />
                    </td>
                    <td v-if="isEditing" style="border-width: 0"><IconDelete @click="onDeleteWorkDescription(uuid)" /></td>
                </tr>
                <tr>
                    <th colspan="3"></th>
                    <th class="text-right">{{ sumWorkDescriptionLength }}</th>
                </tr>
            </table>
            <button v-if="isEditing" @click="onAddWorkDescription">
                <IconPlusCircle />
            </button>
            <h3 class="mt-4">Kommentar</h3>
            <textarea :disabled="!isEditing" data-key="note" @input="onUpdateInputValue" :value="currentWorkLog.note"></textarea>

            <template v-if="currentWorkLog.signatures && Object.keys(currentWorkLog.signatures).length > 0">
                <h3 class="h3 mt-4">{{ Object.keys(currentWorkLog.signatures).length }} signatur(er)</h3>
                <ul>
                    <li v-for="(signature, uuid) in currentWorkLog.signatures" :key="uuid">
                        Signert av
                        {{ signature.signed_by_operator ? 'operatør' : 'kunde' }}
                        {{ signature.signed_before_completed ? 'før ferdigstillelse' : '' }}
                        <img style="width: 200px" :src="getSignatureUrl(signature.signature_file_sha1)" />
                    </li>
                </ul>
            </template>
            <h3 v-else class="h3 mt-4">(Ingen signaturer)</h3>

            <template v-if="currentWorkLog.images && Object.keys(currentWorkLog.images).length > 0">
                <h3 class="mt-4">{{ Object.keys(currentWorkLog.images).length }} bilde(r)</h3>
                <ul>
                    <li v-for="(image, uuid) in currentWorkLog.images" :key="uuid">
                        <img style="width: 200px" :src="getImageUrl(image.imageAnnotatedFileSha1, 'jpg')" />
                    </li>
                </ul>
            </template>
            <h3 v-else class="h3 mt-4">(Ingen bilder)</h3>

            <template v-if="currentWorkLog.videos && Object.keys(currentWorkLog.videos).length > 0">
                <h3 class="mt-4">{{ Object.keys(currentWorkLog.videos).length }} video(er)</h3>
                <ul>
                    <li v-for="(video, uuid) in currentWorkLog.videos" :key="uuid">
                        <video style="width: 200px" :src="getVideoUrl(video.sha1, video.extension)" />
                    </li>
                </ul>
            </template>
            <h3 v-else class="h3 mt-4">(Ingen videoer)</h3>
        </div>
        <h2 v-if="showOrderLines" class="bg-grey px-4 py-2 flex-grow-0">
            Ordrelinjer
            <template v-if="orderLineCount > 0">({{ orderLineCount }})</template>
        </h2>
        <OrderLines
            v-if="showOrderLines"
            class="max-h-[25vh] flex-shrink-0 overflow-y-auto"
            :workLog="currentWorkLog"
            :products="products"
            :units="units"
            :productCategories="productCategories"
            :isEditing="isEditing"
            @onUpdateInputValue="onUpdateInputValue"
            @onUpdateSelectValue="onUpdateSelectValue"
            @onAddOrderLineFromSelect="onAddOrderLineFromSelect"
            @onDeleteOrderLine="onDeleteOrderLine"
        />
    </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import IconDelete from '@/assets/svg/delete.svg';
import IconInvoice from '@/assets/svg/invoice.svg';
import IconPlusCircle from '@/assets/svg/plus-circle.svg';
import IconPrint from '@/assets/svg/print.svg';
import OrderLines from '@/components/future/OrderLines.vue';
import VCheckbox from '@/components/VCheckbox/VCheckbox.vue';
import { ACTIONS } from '@/store/actions';
import AutocompleteValue from '@/store/models/AutocompleteValue';
import Department from '@/store/models/Department';
import WasteType from '@/store/models/WasteType';
import { applyReactiveUpdatesRecursively, getCleanWorkLog } from '@/util';

export default {
    name: 'WorkLogEditor',
    components: { VCheckbox, OrderLines, IconInvoice, IconPlusCircle, IconDelete, IconPrint, DatePicker },
    props: {
        workLog: {
            type: Object,
            default: null,
        },
        products: {
            type: Array,
            default: null,
        },
        units: {
            type: Array,
            default: () => {
                return [];
            },
        },
        productCategories: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            currentWorkLog: null,
            isEditing: false,
            updates: {},
            deletes: {},
            workTypes: [
                { key: 'leak_test', label: 'Tetthetsprøve' },
                { key: 'pressure_test', label: 'Trykkprøve' },
                { key: 'disinfection', label: 'Desinfisering' },
                { key: 'laser_measurement', label: 'Lasermåling' },
                { key: 'pipe_inspection', label: 'Rørinspeksjon' },
                { key: 'route_search', label: 'Trasésøk' },
                { key: 'colorant', label: 'Fargestoff' },
                { key: 'report', label: 'Rapport' },
                { key: 'memory_stick', label: 'Minnepenn' },
                { key: 'neutralizing', label: 'Nøytralisering' },
                { key: 'cleaning_plugs', label: 'Renseplugger' },
                { key: 'measuring', label: 'Innmåling' },
            ],
            nb,
        };
    },
    computed: {
        ...mapState(['modules']),
        tenantHas24SevenIntegration() {
            return this.modules != null && this.modules.some((module) => module.id === '24seven_integration');
        },
        printableWorkLogUrl() {
            if (this.currentWorkLog) {
                return process.env.VUE_APP_ADMIN_URL + '/workLog/' + this.currentWorkLog.uuid + '/view';
            } else {
                return null;
            }
        },
        hasUpdates() {
            for (const key in this.updates) {
                // If any keys exists, assume it's an update
                return true;
            }
            for (const key in this.deletes) {
                return true;
            }
            return false;
        },
        departments() {
            return Department.all();
        },
        materialOptions() {
            return AutocompleteValue.query()
                .where('field', 'inspection_material')
                .get()
                .map(({ value }) => value);
        },
        availableWasteTypes() {
            const usedWasteTypeIds = new Set();
            if (this.currentWorkLog && this.currentWorkLog.waste_types) {
                for (const wasteTypeUuid in this.currentWorkLog.waste_types) {
                    usedWasteTypeIds.add(this.currentWorkLog.waste_types[wasteTypeUuid].id);
                }
            }
            const result = WasteType.query()
                .where((wasteType) => {
                    return !usedWasteTypeIds.has(wasteType.id);
                })
                .get();
            return result;
        },
        showOrderLines() {
            return this.currentWorkLog && this.products && this.products.length > 0;
        },
        orderLineCount() {
            if (this.currentWorkLog?.order_lines) {
                return Object.keys(this.currentWorkLog.order_lines).length;
            } else {
                return 0;
            }
        },
        sumWorkDescriptionLength() {
            return Object.values(this.currentWorkLog?.work_descriptions || {}).reduce(function (carry, workDescription) {
                return carry + (parseFloat(String(workDescription.length || 0).replace(',', '.')) || 0);
            }, 0);
        },
    },
    methods: {
        ...mapActions({
            updateWorkLog: ACTIONS.UPDATE_WORK_LOG,
            deleteWorkLog: ACTIONS.DELETE_WORK_LOG,
            approveWorkLog: ACTIONS.APPROVE_WORK_LOG,
            startWorkLog: ACTIONS.START_WORK_LOG,
            completeWorkLog: ACTIONS.COMPLETE_WORK_LOG,
            setWorkLogInvoiced: ACTIONS.SET_WORK_LOG_INVOICED,
        }),
        getSignatureUrl(sha1) {
            return process.env.VUE_APP_API_URL + '/api/planner/media/signature/' + sha1 + '.jpg';
        },
        getImageUrl(sha1, ext) {
            return process.env.VUE_APP_API_URL + '/api/planner/media/image/' + sha1 + '.' + ext;
        },
        getVideoUrl(sha1, ext) {
            return process.env.VUE_APP_API_URL + '/api/planner/media/video/' + sha1 + '.' + ext;
        },
        onUpdateCheckboxValue(value, event) {
            const key = event.target.attributes['data-key'].value;
            this.updateValue(key, value);
        },
        onUpdateInputValue(event) {
            const key = event.target.attributes['data-key'].value;
            const value = event.target.value;
            this.updateValue(key, value);
        },
        onUpdateDatePickerValue(key, event) {
            const value = format(event, 'yyyy-MM-dd');
            this.updateValue(key, value);
        },
        onUpdateSelectValue(event) {
            const key = event.target.attributes['data-key'].value;
            const value = event.target.value;
            this.updateValue(key, value);
        },
        onUpdateDepartmentValue(event) {
            const key = event.target.attributes['data-key'].value;
            let value = null;
            if (event.target.value) {
                const department = Department.find(parseInt(event.target.value));
                value = {
                    id: department.id,
                    code: department.code,
                    name: department.name,
                    description: department.description,
                };
            }
            this.updateValue(key, value);
        },
        onUpdateAdditionalOperatorCountValue(event) {
            const key = event.target.attributes['data-key'].value;
            const value = parseInt(event.target.value);
            this.updateValue(key, value);
        },
        onAddWasteTypeFromSelect(event) {
            const value = parseInt(event.target.value);
            const wasteType = WasteType.find(value);
            this.updateValue('waste_types.' + uuidv4(), {
                id: wasteType.id,
                name: wasteType.name,
            });
            event.target.selectedIndex = 0;
        },
        onDeleteWasteType(wasteTypeKey) {
            if (!this.deletes.waste_types) {
                Vue.set(this.deletes, 'waste_types', {});
            }
            Vue.set(this.deletes.waste_types, wasteTypeKey, true);
            Vue.delete(this.currentWorkLog.waste_types, wasteTypeKey);
        },
        onAddOrderLineFromSelect(event) {
            const value = parseInt(event.target.value);
            const product = this.products?.find((product) => product.id === value);
            if (product) {
                product.category = this?.productCategories?.find((productCategory) => productCategory.id === product.category_id);
            }
            const orderLine = {
                product,
                price: parseFloat(product.price || 0),
                name: product.name,
                unit: product.unit,
                amount: 1,
                total: parseFloat(product.price || 0),
            };
            this.updateValue('order_lines.' + uuidv4(), orderLine);
            event.target.selectedIndex = 0;
        },
        onDeleteOrderLine(orderLineUuid) {
            if (!this.deletes.order_lines) {
                Vue.set(this.deletes, 'order_lines', {});
            }
            Vue.set(this.deletes.order_lines, orderLineUuid, true);
            Vue.delete(this.currentWorkLog.order_lines, orderLineUuid);
        },
        updateValue(key, value) {
            const keyParts = key.split('.');
            let updateRef = this.updates;
            let objectRef = this.currentWorkLog;
            for (let i = 0; i < keyParts.length; i++) {
                const part = keyParts[i];
                if (i === keyParts.length - 1) {
                    // last part - just set the value
                    Vue.set(updateRef, part, value);
                    Vue.set(objectRef, part, value);
                } else {
                    // if object does not have property, create empty object for it
                    if (!Object.prototype.hasOwnProperty.call(updateRef, part)) {
                        Vue.set(updateRef, part, {});
                    }
                    if (!Object.prototype.hasOwnProperty.call(objectRef, part)) {
                        Vue.set(objectRef, part, {});
                    }
                    updateRef = updateRef[part];
                    objectRef = objectRef[part];
                }
                if (i === 2 && keyParts[0] === 'order_lines' && (keyParts[2] === 'amount' || keyParts[2] === 'price')) {
                    // Also update order line total if amount or price has been changed
                    let amount = parseFloat(String(this.currentWorkLog.order_lines[keyParts[1]].amount || 0).replace(',', '.'));
                    let price = parseFloat(String(this.currentWorkLog.order_lines[keyParts[1]].price || 0).replace(',', '.'));
                    this.updateValue('order_lines.' + keyParts[1] + '.total', amount * price);
                }
            }
        },
        onAddWorkRow() {
            this.updateValue('work_rows.' + uuidv4(), { note: '' });
        },
        onDeleteWorkRow(workRowUuid) {
            if (!this.deletes.work_rows) {
                Vue.set(this.deletes, 'work_rows', {});
            }
            Vue.set(this.deletes.work_rows, workRowUuid, true);
            Vue.delete(this.currentWorkLog.work_rows, workRowUuid);
        },
        onAddWorkDescription() {
            this.updateValue('work_descriptions.' + uuidv4(), {});
        },
        onDeleteWorkDescription(workDescriptionUuid) {
            if (!this.deletes.work_descriptions) {
                Vue.set(this.deletes, 'work_descriptions', {});
            }
            Vue.set(this.deletes.work_descriptions, workDescriptionUuid, true);
            Vue.delete(this.currentWorkLog.work_descriptions, workDescriptionUuid);
        },
        startEditing() {
            this.currentWorkLog = JSON.parse(JSON.stringify(this.workLog));
            this.updates = {};
            this.deletes = {};
            this.isEditing = true;
            this.$emit('startEditing');
        },
        cancelEditing() {
            this.updates = {};
            this.deletes = {};
            this.currentWorkLog = this.workLog;
            this.isEditing = false;
            this.$emit('stopEditing');
        },
        async saveUpdates() {
            const uuid = this.currentWorkLog.uuid;
            const updatedWorkLog = await this.updateWorkLog({
                workLogUuid: uuid,
                updates: this.updates,
                deletes: this.deletes,
            });
            this.updates = {};
            this.deletes = {};
            this.isEditing = false;
            const workLog = getCleanWorkLog();
            applyReactiveUpdatesRecursively(workLog, updatedWorkLog);
            this.currentWorkLog = workLog;
            this.$emit('workLogUpdated', workLog);
            this.$emit('stopEditing');
        },
        async approve() {
            const uuid = this.currentWorkLog.uuid;
            const updatedWorkLog = await this.approveWorkLog(uuid);
            const workLog = getCleanWorkLog();
            applyReactiveUpdatesRecursively(workLog, updatedWorkLog);
            this.$emit('workLogUpdated', workLog);
            this.currentWorkLog = workLog;
        },
        async start() {
            const uuid = this.currentWorkLog.uuid;
            const updatedWorkLog = await this.startWorkLog(uuid);
            const workLog = getCleanWorkLog();
            applyReactiveUpdatesRecursively(workLog, updatedWorkLog);
            this.$emit('workLogUpdated', workLog);
            this.currentWorkLog = workLog;
        },
        async complete() {
            const uuid = this.currentWorkLog.uuid;
            const updatedWorkLog = await this.completeWorkLog(uuid);
            const workLog = getCleanWorkLog();
            applyReactiveUpdatesRecursively(workLog, updatedWorkLog);
            this.$emit('workLogUpdated', workLog);
            this.currentWorkLog = workLog;
        },
        async setInvoiced() {
            const uuid = this.currentWorkLog.uuid;
            const updatedWorkLog = await this.setWorkLogInvoiced(uuid);
            const workLog = getCleanWorkLog();
            applyReactiveUpdatesRecursively(workLog, updatedWorkLog);
            this.$emit('workLogUpdated', workLog);
            this.currentWorkLog = workLog;
        },
        async deleteItem() {
            if (confirm('Vil du slette denne kjørelisten?')) {
                const workLog = this.currentWorkLog;
                await this.deleteWorkLog(workLog.uuid);
                this.$emit('workLogDeleted', workLog);
            }
        },
        format,
        parseISO,
    },
    watch: {
        workLog(newValue) {
            if (!this.isEditing) {
                this.currentWorkLog = newValue;
            }
        },
    },
    mounted() {
        this.currentWorkLog = this.workLog;
    },
};
</script>

<style lang="scss"></style>
