import Vue from 'vue';
import VueRouter from 'vue-router';

import Calendar from '../views/Calendar.vue';
import Planner from '../views/Planner.vue';

Vue.use(VueRouter);

const routes = [
    { path: '/', redirect: '/plan' },
    {
        path: '/plan',
        name: 'Planner',
        component: Planner,
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: Calendar,
    },
    {
        path: '/orders',
        name: 'orders',
        component: () => import(/* webpackChunkName: "order-new" */ '../views/Orders.vue'),
    },
    {
        path: '/order/new',
        name: 'order-new',
        component: () => import(/* webpackChunkName: "order-new" */ '../views/OrderNew.vue'),
    },
    {
        path: '/order/:uuid',
        name: 'order-detail',
        component: () => import(/* webpackChunkName: "order-detail" */ '../views/OrderDetail.vue'),
        props: true,
    },
    {
        path: '/map',
        name: 'map',
        component: () => import(/* webpackChunkName: "map-view" */ '../views/MapPage.vue'),
    },
    {
        path: '/chat',
        component: () => import(/* webpackChunkName: "chat" */ '../views/Chat.vue'),
        children: [
            {
                path: '',
                name: 'chat-channels',
                component: () => import(/* webpackChunkName: "chat" */ '../views/ChatChannels'),
            },
            {
                path: ':uuid',
                name: 'chat-messages',
                component: () => import(/* webpackChunkName: "chat" */ '../views/ChatMessages'),
            },
        ],
    },
];

const router = new VueRouter({
    linkActiveClass: 'font-medium underline',
    mode: 'history',
    hash: false,
    routes,
});

export default router;
