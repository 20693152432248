<template>
    <div
        class="resource-department h-[98rem]"
        :class="{
            'pr-2': !department,
            '[&_.timeslot]:border-r-[1px]': !department,
            relative: !department,
            'z-[6]': !department,
            'bg-blue-400': !department,
        }"
        :style="{
            width: [halfCellWidth * (1 + (overlappingTimespans.length || 1)) + (department ? 0 : 8) + 'px'],
            left: adjustStickyColumnsLeft + 'px',
        }"
    >
        <div class="right-0 sticky top-0 z-[5] bg-blue-400 px-2" @drop="onDropOperatorOnDepartment($event)" @dragover.prevent>
            <div draggable="true" class="">
                {{ departmentLabel }}
                <div v-if="department" class="absolute inline-block right-2 h-8" @click="onClickOpenDropDownMenu">
                    <IconMenu class="pointer-events-none" />
                </div>
            </div>
        </div>
        <div class="relative" @dragover="onDragOver" ref="departmentColumn">
            <div
                class="h-8 relative border-[1px] border-dotted border-t-0 border-r-0 first:border-t-[1px] border-blue-500 timeslot"
                :class="{
                    'bg-blue-350': availableTimeSlots[timeslot] && timeslot >= workDayStart && timeslot < workDayEnd,
                    'bg-red-600': !availableTimeSlots[timeslot],
                }"
                v-for="timeslot in allTimeslots"
                :key="timeslot"
                @drop="onTimespanDrop($event, timeslot)"
                @dragover.prevent="onDragOver"
                @dragenter.prevent="onTimespanDragEnter"
                @dragleave.prevent="onTimespanDragLeave"
                @dragend="onDragEnd"
            ></div>
            <div
                class="absolute top-0 left-0 right-0 bottom-0 pointer-events-none"
                :class="{
                    'bg-black/30': !departmentSupportCurrentlyDraggedTaskType(
                        plannerCurrentlyDraggedPlannableTuple?.plannable?.sub_order?.type_id || null
                    ),
                }"
            ></div>

            <template v-for="(sortedPlannables, j) in overlappingTimespans">
                <div
                    v-for="plannableTuple in sortedPlannables"
                    :key="plannableTuple.timeSpanUUID"
                    class="absolute bg-brown-300 mb-[1px] rounded border border-blue-500 border-1 hover:z-[1] overflow-hidden"
                    :class="{
                        'opacity-70': plannerCurrentlyDraggedPlannableTuple || currentlyDraggedElement,
                        'opacity-90': !(plannerCurrentlyDraggedPlannableTuple || currentlyDraggedElement),
                        'bg-brown-400': plannablesAreTheConnected(plannerHighlightedPlannable, plannableTuple.plannable),
                    }"
                    :style="getTimeSpanStyle(plannableTuple, j)"
                    :ref="`drag-handle-${plannableTuple.timeSpanUUID}`"
                    draggable
                    @dragover.prevent="onDragOver"
                    @dragstart="onTimespanDragStart($event, plannableTuple)"
                    @dragenter.prevent
                    @dragleave.prevent
                    @mousedown="onMouseDownDragHandle($event, plannableTuple)"
                    @mouseover="onMouseOver($event, plannableTuple)"
                    @mouseleave="onMouseLeave"
                    @drop="onDrop"
                    @drag="onDrag"
                >
                    <div class="absolute top-0 left-0 right-0 cursor-move" @drag="onDrag">
                        <template v-if="plannableTuple.plannable.tank">
                            <div v-if="plannableTuple.plannable.tank.name" class="text-blue-600 text-sm" :title="plannableTuple.plannable.tank.name">
                                {{ plannableTuple.plannable.tank.name }}
                            </div>
                            <div class="text-blue-600 text-sm" :title="plannableTuple.plannable.tank.address">
                                {{ plannableTuple.plannable.tank.address }}
                            </div>
                            <div class="text-blue-600 text-sm" :title="plannableTuple.plannable.tank.customer">
                                {{ plannableTuple.plannable.tank.customer }}
                            </div>
                        </template>
                        <template v-else-if="plannableTuple.plannable.sub_order">
                            <div
                                v-if="plannableTuple.plannable.sub_order.title"
                                class="text-blue-600 text-sm"
                                :title="plannableTuple.plannable.sub_order.title"
                            >
                                {{ plannableTuple.plannable.sub_order.title }}
                            </div>
                            <div
                                class="text-blue-600 text-sm"
                                v-if="plannableTuple.plannable.sub_order.address"
                                :title="plannableTuple.plannable.sub_order.address"
                            >
                                {{ plannableTuple.plannable.sub_order.address }}
                            </div>
                            <div
                                v-if="plannableTuple.plannable.sub_order.customer"
                                class="text-blue-500 text-sm"
                                :title="plannableTuple.plannable.sub_order.customer"
                            >
                                {{ plannableTuple.plannable.sub_order.customer }}
                            </div>
                            <div v-if="plannableTuple.plannable.sub_order.flushing">
                                +
                                <IconFlushing class="inline-block h-4" />
                            </div>
                            <div v-if="plannableTuple.plannable.sub_order.inspection">
                                +
                                <IconInspection class="inline-block h-4" />
                            </div>
                            <div v-if="plannableHasStartTimeOrEstimatedDuration(plannableTuple.plannable)" class="text-blue-500 text-sm mt-2">
                                {{ getStartTimeDurationStringFromPlannable(plannableTuple.plannable) }}
                            </div>
                        </template>
                        <div v-if="plannableTuple.plannable.tank || plannableTuple.plannable.sub_order" class="inline-flex mt-2">
                            <VAvatar
                                v-for="operator in activeOnDepartmentAssignedOperators[plannableTuple.timeSpanUUID || plannableTuple.plannable.id]"
                                class="execution-order-recipient inline-block bg-blue-350"
                                :data-operator_id="operator.user_id"
                                :size="40"
                                :key="operator.user_id"
                                :refusedAt="operator.refused_at"
                            >
                                <template v-slot:badge-br v-if="operator.execution_order">
                                    <span
                                        class="inline-block h-4 w-4 rounded-full text-center text-black text-micro leading-4"
                                        :class="operator.execution_order < 0 ? 'bg-green' : 'bg-blue-400'"
                                    >
                                        {{ operator.execution_order < 0 ? 'F' : operator.execution_order }}
                                    </span>
                                </template>

                                <template v-slot:badge-tr v-if="operator.driving_list">
                                    <span
                                        v-if="operator.driving_list.isInProgress"
                                        class="h-4 w-4 rounded-full flex justify-center items-center bg-orange text-white"
                                        :title="'Påbegynt ' + formatUnixTime(operator.driving_list.unix_time)"
                                    >
                                        <IconTimelapse />
                                    </span>
                                    <span
                                        v-else-if="operator.driving_list.isCompleted"
                                        class="h-4 w-4 rounded-full flex justify-center items-center bg-green text-black p-1"
                                        :title="'Ferdig ' + formatUnixTime(operator.driving_list.unix_time)"
                                    >
                                        <IconCheckmark />
                                    </span>
                                </template>
                                {{ operator.initials }}
                            </VAvatar>
                            <div
                                v-if="notActiveOnDepartmentAssignedOperators[plannableTuple.timeSpanUUID || plannableTuple.plannable.id].length"
                                class="execution-order-recipient inline-flex text-blue-600 bg-blue-350 rounded-full w-10 h-10 items-center justify-center"
                                :class="{
                                    'ml-2': activeOnDepartmentAssignedOperators[plannableTuple.timeSpanUUID || plannableTuple.plannable.id].length,
                                }"
                                :title="
                                    notActiveOnDepartmentAssignedOperators[plannableTuple.timeSpanUUID || plannableTuple.plannable.id]
                                        .map((operator) => operator.initials)
                                        .join(', ')
                                "
                            >
                                <IconAddUser />
                            </div>
                        </div>

                        <IconTimelapse
                            v-if="plannableTuple.plannable.status === PLANNABLE_STATUSES.IN_PROGRESS"
                            class="text-orange absolute top-0 right-0"
                        />
                        <IconCheckmark
                            v-if="plannableTuple.plannable.status === PLANNABLE_STATUSES.COMPLETED"
                            class="text-green absolute top-0 right-0"
                        />
                    </div>
                    <div
                        v-if="
                            department &&
                            plannerCurrentlyDraggedPlannableTuple &&
                            plannableTuple !== plannerCurrentlyDraggedPlannableTuple &&
                            plannablesAreTheConnected(plannableTuple.plannable, plannerCurrentlyDraggedPlannableTuple.plannable)
                        "
                        class="absolute bottom-0 cursor-ns-resize"
                        @drop="onDropJoin($event, plannableTuple, plannerCurrentlyDraggedPlannableTuple)"
                    >
                        <IconJoinUp class="inline-block pointer-events-none" />
                    </div>
                    <div v-else :ref="`resize-handle-${plannableTuple.timeSpanUUID}`" class="absolute bottom-0 cursor-ns-resize" @drag="onDrag">
                        <IconResize class="pointer-events-none" />
                    </div>
                    <div
                        v-for="(timeSlot, i) in getTimeSpanSlots(plannableTuple)"
                        :key="i"
                        class="absolute"
                        :style="`top:${i * 2 + 2}rem; right:0px`"
                        :tooltip="timeSlot"
                        :class="{
                            'opacity-0': timeSlot !== showElementOnHover,
                        }"
                        @mouseover="showElementOnHover = timeSlot"
                        @mouseleave="showElementOnHover = null"
                    >
                        <IconSplitDown
                            v-if="department && !plannerCurrentlyDraggedPlannableTuple"
                            @click="splitPlannable(plannableTuple, timeSlot)"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapMutations, mapState } from 'vuex';

import IconAddUser from '@/assets/svg/add-user.svg';
import IconCheckmark from '@/assets/svg/checkmark.svg';
import IconFlushing from '@/assets/svg/flushing.svg';
import IconInspection from '@/assets/svg/inspection.svg';
import IconJoinUp from '@/assets/svg/join-up.svg';
import IconMenu from '@/assets/svg/menu.svg';
import IconResize from '@/assets/svg/resize.svg';
import IconSplitDown from '@/assets/svg/split-down.svg';
import IconTimelapse from '@/assets/svg/timelapse.svg';
import VAvatar from '@/components/VAvatar/VAvatar.vue';
import { PLANNABLE_STATUSES } from '@/constants';
import { ACTIONS } from '@/store/actions';
import Plannable from '@/store/models/Plannable';
import Tank from '@/store/models/Tank';
import User from '@/store/models/User';
import { MUTATIONS } from '@/store/mutations';
import { allTimeslots, decimalToTime, formatUnixTime, timestampToTimeSlot, timeToDecimal } from '@/util';

const CELL_WIDTH = 144;

/**
 * @typedef {Object} Plannable
 */

/**
 * PlannableTuple
 * @typedef {Object} PlannableTuple
 * @property {string} from - starting date
 * @property {string} to - ending date
 * @property {string} timeSpanUUID - UUID of the timespan associated with this plannable tuple
 * @property {string} date - date
 * @property {number} departmentId - department id
 * @property {number} userId - user id
 * @property {Plannable} plannable - the actual plannable.
 * @property {number} plannable_id - plannable id.
 */

export default {
    components: {
        IconAddUser,
        // IconLoader,
        VAvatar,
        IconCheckmark,
        IconFlushing,
        IconInspection,
        IconJoinUp,
        IconMenu,
        IconResize,
        IconSplitDown,
        IconTimelapse,
    },
    data() {
        return {
            halfCellWidth: CELL_WIDTH / 2,
            dragTarget: null,
            resizing: false,
            showElementOnHover: null,
            currentlyColoredTimeStampElement: null,
            showEditDepartmentUserSchedules: false,
            currentlyEditingDepartmentUserSchedule: null,
            PLANNABLE_STATUSES,
        };
    },
    computed: {
        ...mapState([
            'plannerCurrentlyDraggedPlannableTuple',
            'plannerDragDestinationDepartmentId',
            'plannerHighlightedPlannable',
            'plannerCurrentlyResizingPlannableTuple',
            'plannerFakeTimeSpansForPlannables',
        ]),
        departmentLabel() {
            return this.department ? this.department.label || this.department.name || 'Ikke navngitt' : 'Ikke tilordnet';
        },
        allTimeslots,
        sortedPlannables() {
            return [...this.plannableTuples].sort((a, b) => {
                let ret = a.from <= b.from ? -1 : a.from === b.from ? 0 : 1;
                return ret;
            });
        },
        overlappingTimespans() {
            const overlap = (a, b) => {
                return (a.to > b.from && a.from <= b.from) || (a.from < b.to && a.from >= b.from);
            };
            // columns is an array of arrays. entries in the second array overlaps something in the first. and so on.
            let columns = [];
            // In the sorted array, if start time of an interval
            // is less than end of previous interval, then there
            // is an overlap
            outer: for (let i = 0; i < this.sortedPlannables.length; i++) {
                const plannable = this.sortedPlannables[i];
                for (let j = 0; j < columns.length; j++) {
                    const column = columns[j];
                    const lastValueInColumn = column.length ? column[column.length - 1] : null;
                    if (lastValueInColumn === null || !overlap(lastValueInColumn, plannable)) {
                        column.push(plannable);
                        continue outer;
                    }
                }
                columns.push([plannable]);
            }
            return columns;
        },
        availableTimeSlots() {
            let allTimeSlotsMap = this.allTimeslots.reduce((carry, date) => {
                carry[date] = this.timeSlotsArePerDefaultAvailable || false;
                return carry;
            }, {});
            if (this.departmentUserSchedules) {
                this.departmentUserSchedules.forEach((departmentUserSchedule) => {
                    if (
                        // from must be unset or in the past
                        (!departmentUserSchedule.from || departmentUserSchedule.getDateFrom() <= this.date) &&
                        // to must be unset or in the future
                        (!departmentUserSchedule.to || departmentUserSchedule.getDateTo() >= this.date)
                    ) {
                        let timeSlotFrom =
                            (departmentUserSchedule?.from && departmentUserSchedule.getDateFrom() < this.date) || !departmentUserSchedule?.from
                                ? '00:00'
                                : timestampToTimeSlot(departmentUserSchedule.from);
                        let timeSlotTo =
                            (departmentUserSchedule?.to && departmentUserSchedule.getDateTo() > this.date) || !departmentUserSchedule?.to
                                ? '24:00'
                                : timestampToTimeSlot(departmentUserSchedule.to);
                        for (let i = timeToDecimal(timeSlotFrom); i < timeToDecimal(timeSlotTo); i += 0.5) {
                            if (this.department.id === departmentUserSchedule.department_id) {
                                allTimeSlotsMap[decimalToTime(i)] =
                                    departmentUserSchedule.user_id && departmentUserSchedule.department_id ? departmentUserSchedule.user_id : false;
                            } else {
                                allTimeSlotsMap[decimalToTime(i)] = false; // if the user is in another department, mark as unavailable, always;
                            }
                        }
                    }
                });
            }
            return allTimeSlotsMap;
        },
        assignedOperators() {
            let timeSpanMap = {};
            this.plannableTuples.forEach((plannableTuple) => {
                timeSpanMap[plannableTuple.timeSpanUUID || plannableTuple.plannable.id] = this.getAssignedOperatorsInTimeSpan(plannableTuple);
            });
            return timeSpanMap;
        },
        activeOnDepartmentAssignedOperators() {
            // this filters on activeOnDepartment being true
            let timeSpanMap = {};
            _.forOwn(this.assignedOperators, (value, key) => {
                timeSpanMap[key] = value.filter((operator) => operator.activeOnDepartment);
            });
            return timeSpanMap;
        },
        notActiveOnDepartmentAssignedOperators() {
            // this filters on activeOnDepartment being false
            let timeSpanMap = {};
            _.forOwn(this.assignedOperators, (value, key) => {
                timeSpanMap[key] = value.filter((operator) => !operator.activeOnDepartment);
            });
            return timeSpanMap;
        },
    },
    props: {
        date: {
            type: String,
            required: true,
        },
        department: {
            validator: (v) => typeof v === 'object' || v === null,
            required: true,
        },
        showTimeSlots: {
            type: Boolean,
            default: false,
        },
        plannableTuples: {
            type: Array,
            required: true,
        },
        allPlannableTuples: {
            type: Object,
            required: true,
        },
        currentlyDraggedElement: {
            type: Object,
            default: null,
        },
        workDayStart: {
            type: String,
            required: true,
        },
        workDayEnd: {
            type: String,
            required: true,
        },
        departmentUserSchedules: {
            type: Array,
            nullable: true,
        },
        timeSlotsArePerDefaultAvailable: {
            type: Boolean,
            nullable: true,
        },
        adjustStickyColumnsLeft: {
            type: Number,
            default: 0,
        },
        currentlyDraggedType: {
            type: String,
            default: null,
        },
        isDragTargetCalendar: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions({
            resizePlannableTimeSpan: ACTIONS.RESIZE_PLANNABLE_TIME_SPAN,
            movePlannableTimeSpan: ACTIONS.MOVE_PLANNABLE_TIME_SPAN,
            createPlannableTimeSpan: ACTIONS.CREATE_PLANNABLE_TIME_SPAN,
            splitPlannableTimeSpan: ACTIONS.SPLIT_PLANNABLE_TIME_SPAN,
            joinPlannableTimeSpans: ACTIONS.JOIN_PLANNABLE_TIME_SPANS,
            createPlannableForTank: ACTIONS.CREATE_PLANNABLE_FOR_TANK,
        }),
        ...mapMutations({
            setCurrentlyDraggedPlannableTuple: MUTATIONS.SET_CURRENTLY_DRAGGED_PLANNABLE_TUPLE,
            setDragDestinationDepartmentId: MUTATIONS.SET_DRAG_DESTINATION_DEPARMENT_ID,
            setHighlightedPlannable: MUTATIONS.SET_HIGHLIGHTED_PLANNABLE,
            setCurrentlyResizingPlannableTuple: MUTATIONS.SET_CURRENTLY_RESIZING_PLANNABLE_TUPLE,
            setFakeTimeSpansForPlannable: MUTATIONS.SET_FAKE_TIME_SPANS_FOR_PLANNABLE,
        }),
        getTimeSpanStyle(plannableTuple, indent) {
            let timeSpan =
                plannableTuple?.plannable?.time_spans?.[plannableTuple.timeSpanUUID] ||
                this.plannerFakeTimeSpansForPlannables[plannableTuple.plannable.id] ||
                null;
            if (!timeSpan) {
                return '';
            }
            const rowHeight = 2; // rem
            const top = timeToDecimal(timeSpan.from) * rowHeight * 2;
            const bottom = (timeToDecimal('24:00') - timeToDecimal(timeSpan.to)) * rowHeight * 2;
            const left = 1 + this.halfCellWidth * indent;
            const right = this.halfCellWidth * (this.overlappingTimespans.length - indent - 1);
            return `top:${top}rem;bottom:${bottom}rem;left:${left}px;right:${right}px;`;
        },
        getTimeSpanFromAllPlannables(timeSpanUUID) {
            let returnValue = false;
            _.forOwn(this.allPlannableTuples, (plannabledate) => {
                _.forOwn(plannabledate, (plannables) => {
                    _.forOwn(plannables, (plannableTuple) => {
                        if (plannableTuple.timeSpanUUID === timeSpanUUID) {
                            returnValue = plannableTuple.plannable?.time_spans?.[timeSpanUUID];
                        }
                    });
                });
            });
            return returnValue;
        },
        getPlannableTupleFromAllPlannables(plannableId) {
            let returnValue = false;
            _.forOwn(this.allPlannableTuples, (plannabledate) => {
                _.forOwn(plannabledate, (plannableTuples) => {
                    _.forOwn(plannableTuples, (plannableTuple) => {
                        if (plannableTuple.plannableId === plannableId) {
                            returnValue = plannableTuple;
                        }
                    });
                });
            });
            return returnValue;
        },
        /**
         * @param event
         * @param {PlannableTuple} plannableTuple
         */
        onTimespanDragStart(event, plannableTuple) {
            // we need to do this as well to make sure the class is set on own element
            if (
                this.$refs['drag-handle-' + plannableTuple.timeSpanUUID].includes(event.target) &&
                event.offsetY <= event.target.getElementsByClassName('cursor-move')[0].offsetHeight
            ) {
                this.setCurrentlyDraggedPlannableTuple(plannableTuple);
                this.updateCurrentlyDraggedTaskType(plannableTuple?.plannable?.sub_order?.type_id || null);
                event.dataTransfer.effectAllowed = 'link';
                if (plannableTuple.timeSpanUUID) {
                    event.dataTransfer.setData('timespanUUID', plannableTuple.timeSpanUUID);
                }
                event.dataTransfer.setData('plannableId', plannableTuple.plannable.id);
            } else {
                event.preventDefault();
            }
        },
        getTimeSpanDuration(timeSpan) {
            return timeToDecimal(timeSpan.to) - timeToDecimal(timeSpan.from);
        },
        async onTimespanDrop(event, timeslot) {
            const operatorId = parseInt(event.dataTransfer.getData('operatorId'), 10);
            if (operatorId) {
                this.onDropOperatorOnDepartment(event);
                return;
            }
            event.currentTarget.classList.remove('!bg-blue-600');
            let currentlyDraggedElement = this.currentlyDraggedElement;
            if (currentlyDraggedElement) {
                if (currentlyDraggedElement instanceof Tank) {
                    const tankId = currentlyDraggedElement.id;
                    currentlyDraggedElement = await this.createPlannableForTank({
                        status: PLANNABLE_STATUSES.PLANNED,
                        date: this.date || null,
                        tankId: tankId,
                    });
                    if (!currentlyDraggedElement) {
                        currentlyDraggedElement = Plannable.query()
                            .where((plannable) => {
                                return plannable.tank_id === tankId && plannable.date === this.date;
                            })
                            .first();
                        if (!currentlyDraggedElement) {
                            this.$emit('setIsDragTargetCalendar', false);
                            return;
                        }
                    }
                    this.$emit('setIsDragTargetCalendar', false);
                }

                // this means we get a plannable from another column.
                const newTimeSpan = {
                    date: this.date,
                    to: decimalToTime(timeToDecimal(timeslot) + 2), // todo: should possibly be possible to lookup
                    from: timeslot,
                    department_id: this.department ? this.department.id : null,
                    user_id: null,
                };
                newTimeSpan['user_id'] = this.getUserIdFromTimeRange(newTimeSpan);
                const uuid = uuidv4();
                Plannable.update({
                    where: currentlyDraggedElement.id,
                    data: {
                        status: PLANNABLE_STATUSES.PLANNED,
                        date: this.date,
                        time_spans: { [uuid]: newTimeSpan },
                    },
                });
                this.createPlannableTimeSpan({
                    plannableId: currentlyDraggedElement.id,
                    timeSpanUUID: uuid,
                    timeSpan: newTimeSpan,
                });
                this.setCurrentlyDraggedPlannableTuple(null);
                // this will trigger a reload
                return;
            }
            const timeSpanUUID = event.dataTransfer.getData('timespanUUID');
            const plannableId = event.dataTransfer.getData('plannableId');
            let timeSpan = this.getTimeSpanFromAllPlannables(timeSpanUUID);
            if (timeSpan) {
                const timeSpanDuration = this.getTimeSpanDuration(timeSpan);
                timeSpan.from = timeslot;
                timeSpan.to = decimalToTime(timeToDecimal(timeSpan.from) + timeSpanDuration);
                timeSpan.department_id = this.department ? this.department.id : null;
                timeSpan.date = this.date;
                timeSpan.user_id = this.getUserIdFromTimeRange(timeSpan);
                this.movePlannableTimeSpan({
                    plannableId,
                    timeSpanUUID,
                    timeSpan,
                });
            } else {
                timeSpan = this.plannerFakeTimeSpansForPlannables[plannableId];
                const timeSpanDuration = this.getTimeSpanDuration(timeSpan);
                const plannableTuple = this.getPlannableTupleFromAllPlannables(parseInt(plannableId, 10));
                plannableTuple.date = this.date;
                plannableTuple.from = timeslot;
                plannableTuple.to = decimalToTime(timeToDecimal(timeslot) + timeSpanDuration);
                plannableTuple.departmentId = this.department ? this.department.id : null;

                if (!this.department) {
                    // case 1: the new department is also an unassigned department
                    this.setFakeTimeSpansForPlannable({
                        plannableId: parseInt(plannableId, 10), // todo: probably not necessary to  use parseInt here
                        timeSpan: {
                            date: this.date,
                            from: timeslot,
                            to: decimalToTime(timeToDecimal(timeslot) + timeSpanDuration),
                            department_id: this.department ? this.department.id : null,
                            user_id: this.getUserIdFromTimeRange(timeSpan),
                        },
                    });
                } else {
                    // case 2: we move from unassigned to a real department.
                    // we need to create the whole structure
                    const newTimeSpanUUID = uuidv4();
                    const newTimeSpan = {
                        date: this.date,
                        to: decimalToTime(timeToDecimal(timeslot) + timeSpanDuration),
                        from: timeslot,
                        department_id: this.department.id,
                        user_id: this.getUserIdFromTimeRange(timeSpan),
                    };
                    if (!plannableTuple.plannable.time_spans) {
                        // Update via vuex orm model
                        Plannable.update({
                            where: plannableTuple.plannable.id,
                            data: {
                                time_spans: {
                                    [newTimeSpanUUID]: newTimeSpan,
                                },
                            },
                        });
                        this.$set(plannableTuple.plannable, 'time_spans', {});
                    }
                    this.$set(plannableTuple, 'timeSpanUUID', newTimeSpanUUID);
                    this.$set(plannableTuple, 'plannableId', plannableTuple.plannable.id);
                    this.createPlannableTimeSpan({
                        plannableId: plannableTuple.plannable.id,
                        timeSpanUUID: newTimeSpanUUID,
                        timeSpan: newTimeSpan,
                    });
                }
            }
            this.setCurrentlyDraggedPlannableTuple(null);
            this.setDragDestinationDepartmentId(null);
            this.updateCurrentlyDraggedTaskType(null);
            this.$emit('setIsDragTargetCalendar', false);
        },
        onTimespanDragEnter(e) {
            e.currentTarget.classList.add('!bg-blue-600');
            if (this.currentlyDraggedElement) {
                this.$emit('setIsDragTargetCalendar', true);
            }
        },
        onTimespanDragLeave(e) {
            e.currentTarget.classList.remove('!bg-blue-600');
        },
        onMouseDownDragHandle(event, plannableTuple) {
            this.dragTarget = event.target.nodeName === 'svg' ? event.target.parentElement : event.target;
            if (_.includes(this.$refs['resize-handle-' + plannableTuple.timeSpanUUID], this.dragTarget)) {
                event.preventDefault();
                this.$el.addEventListener('mousemove', this.onResizeMouseMove);
                this.$el.addEventListener('mouseup', this.onResizeMouseUp);
                this.setCurrentlyResizingPlannableTuple(plannableTuple);
            }
        },
        onResizeMouseMove(e) {
            const departmentColumnn = this.$refs['departmentColumn'].getBoundingClientRect();
            const y = e.clientY - departmentColumnn.top; //y position within the element.
            const toTimeStamp = this.getTimeFromRelativePosition(y, departmentColumnn.height);

            let timeSpan = this.getTimeSpanFromAllPlannables(this.plannerCurrentlyResizingPlannableTuple.timeSpanUUID);
            if (timeSpan) {
                timeSpan.to = toTimeStamp;
                timeSpan.user_id = this.getUserIdFromTimeRange(timeSpan);
            } else {
                timeSpan = this.plannerFakeTimeSpansForPlannables[this.plannerCurrentlyResizingPlannableTuple.plannableId];
                timeSpan.to = toTimeStamp;
                this.setFakeTimeSpansForPlannable({
                    plannableId: this.plannerCurrentlyResizingPlannableTuple.plannableId,
                    timeSpan: timeSpan,
                });
            }
        },
        onResizeMouseUp(e) {
            const departmentColumnn = this.$refs['departmentColumn'].getBoundingClientRect();
            const y = e.clientY - departmentColumnn.top; //y position within the element.
            const toTimeStamp = this.getTimeFromRelativePosition(y, departmentColumnn.height);

            if (this.plannerCurrentlyResizingPlannableTuple.timeSpanUUID) {
                this.resizePlannableTimeSpan({
                    plannableId: this.plannerCurrentlyResizingPlannableTuple.plannableId,
                    timeSpanUUID: this.plannerCurrentlyResizingPlannableTuple.timeSpanUUID,
                    to: toTimeStamp,
                    userId: this.getUserIdFromTimeRange({
                        to: toTimeStamp,
                        from: this.plannerCurrentlyResizingPlannableTuple.from,
                    }),
                });
            }
            this.$el.removeEventListener('mousemove', this.onResizeMouseMove);
            this.$el.removeEventListener('mouseup', this.onResizeMouseUp);
            this.setCurrentlyResizingPlannableTuple(null);
        },
        getTimeFromRelativePosition(y, maxHeight) {
            const timeslots = 48;
            const timeSlotNo = Math.min(48, Math.round((y / maxHeight) * timeslots));
            return decimalToTime(timeSlotNo / 2.0);
        },
        getTimeSpanSlots(plannableTuple) {
            let timeslots = [];
            if (plannableTuple.to && plannableTuple.from) {
                const toTime = timeToDecimal(plannableTuple.to);
                for (let i = timeToDecimal(plannableTuple.from) + 0.5; i < toTime; i = i + 0.5) {
                    timeslots.push(decimalToTime(i));
                }
            }
            return timeslots;
        },
        // this is identical to the one above
        getAllTimeSpanSlots(timeSpan) {
            let timeslots = [];
            if (timeSpan.to && timeSpan.from) {
                const toTime = timeToDecimal(timeSpan.to);
                for (let i = timeToDecimal(timeSpan.from); i < toTime; i = i + 0.5) {
                    timeslots.push(decimalToTime(i));
                }
            }
            return timeslots;
        },
        splitPlannable(plannableTuple, timeStamp) {
            let timeSpan = this.getTimeSpanFromAllPlannables(plannableTuple.timeSpanUUID);
            const newTimeSpanUUID = uuidv4();
            const newTimeSpan = {
                to: timeSpan.to,
                from: timeStamp,
                department_id: timeSpan.department_id,
            };
            newTimeSpan.user_id = this.getUserIdFromTimeRange(newTimeSpan);
            timeSpan.to = timeStamp;
            timeSpan.user_id = this.getUserIdFromTimeRange(timeSpan);
            this.$set(plannableTuple.plannable.time_spans, newTimeSpanUUID, newTimeSpan);
            this.splitPlannableTimeSpan({
                plannableId: plannableTuple.plannable.id,
                originalTimeSpanUUID: plannableTuple.timeSpanUUID,
                originalTimeSpanUserId: timeSpan.user_id,
                splitTimeSpanUUID: newTimeSpanUUID,
                splitTimeSpanFrom: timeStamp,
                splitTimeSpanUserId: newTimeSpan.user_id,
            });
        },
        onDragEnd() {
            this.setCurrentlyDraggedPlannableTuple(null);
            this.setDragDestinationDepartmentId(null);
        },
        onMouseOver(event, plannableTuple) {
            if (!this.plannerHighlightedPlannable && !this.plannerCurrentlyDraggedPlannableTuple && !this.plannerCurrentlyResizingPlannableTuple) {
                this.setHighlightedPlannable(plannableTuple.plannable);
            }
        },
        onMouseLeave() {
            if (!this.plannerCurrentlyDraggedPlannableTuple && !this.plannerCurrentlyResizingPlannableTuple) {
                this.setHighlightedPlannable(null);
            }
        },
        getTimeSlotElementPoint(x, y) {
            const elementsAtPoint = document.elementsFromPoint(x, y);
            return elementsAtPoint.find((element) => element.classList.contains('timeslot'));
        },
        onDrag(event) {
            const elementAtPoint = this.getTimeSlotElementPoint(event.x, event.y);
            if (elementAtPoint !== this.currentlyColoredTimeStampElement) {
                if (this.currentlyColoredTimeStampElement) {
                    const newEvent = new DragEvent('dragleave');
                    this.currentlyColoredTimeStampElement.dispatchEvent(newEvent);
                }
                if (elementAtPoint) {
                    const newEvent = new DragEvent('dragenter');
                    elementAtPoint.dispatchEvent(newEvent);
                }
                this.currentlyColoredTimeStampElement = elementAtPoint;
            }
        },
        onDrop(event) {
            const elementAtPoint = this.getTimeSlotElementPoint(event.x, event.y);
            if (elementAtPoint) {
                const newEvent = new DragEvent('drop', { dataTransfer: event.dataTransfer });
                elementAtPoint.dispatchEvent(newEvent);
            }
            this.updateCurrentlyDraggedTaskType(null);
        },
        onDragOver(event) {
            const elementAtPoint = this.getTimeSlotElementPoint(event.x, event.y);
            if (elementAtPoint !== this.currentlyColoredTimeStampElement) {
                if (this.currentlyColoredTimeStampElement) {
                    const newEvent = new DragEvent('dragleave');
                    this.currentlyColoredTimeStampElement.dispatchEvent(newEvent);
                }
                if (elementAtPoint) {
                    const newEvent = new DragEvent('dragenter');
                    elementAtPoint.dispatchEvent(newEvent);
                }
                this.currentlyColoredTimeStampElement = elementAtPoint;
            }
            if (this.department) {
                this.setDragDestinationDepartmentId(this.department.id);
            } else {
                this.setDragDestinationDepartmentId('unassigned');
            }
        },
        plannablesAreTheConnected(plannableTupleA, plannableTupleB) {
            return (
                plannableTupleA?.sub_order?.uuid === plannableTupleB?.sub_order?.uuid &&
                plannableTupleA?.batch_no === plannableTupleB?.batch_no &&
                plannableTupleA?.tank_id === plannableTupleB?.tank_id
            );
        },
        onDropJoin(event, targetPlannableTuple, draggedPlannableTuple) {
            // Add draggedPlannableTuple hours to targetTimeSpan.to
            const draggedTimeSpanHours = timeToDecimal(draggedPlannableTuple.to) - timeToDecimal(draggedPlannableTuple.from);
            const updatedTargetTimeTo = decimalToTime(Math.min(timeToDecimal(targetPlannableTuple.to) + draggedTimeSpanHours, 24));
            const targetTimeSpan = this.getTimeSpanFromAllPlannables(targetPlannableTuple.timeSpanUUID);
            targetTimeSpan.to = updatedTargetTimeTo;

            // Delete the old time span
            const updatedDraggedPlannableTimeSpans = Plannable.find(draggedPlannableTuple.plannableId).time_spans;
            if (updatedDraggedPlannableTimeSpans) {
                delete updatedDraggedPlannableTimeSpans[draggedPlannableTuple.timeSpanUUID];
            }
            Plannable.update({
                where: draggedPlannableTuple.plannableId,
                data: {
                    time_spans: updatedDraggedPlannableTimeSpans,
                },
            });

            // Stop propagation to prevent event from bubbling up and doing a move and clean up
            event.stopPropagation();
            this.setCurrentlyDraggedPlannableTuple(null);
            this.setDragDestinationDepartmentId(null);

            this.joinPlannableTimeSpans({
                plannableId: targetPlannableTuple.plannableId,
                targetTimeSpanUUID: targetPlannableTuple.timeSpanUUID,
                joinedTimeSpanUUID: draggedPlannableTuple.timeSpanUUID,
                joinedTimeSpanTo: updatedTargetTimeTo,
                joinedTimeSpanUserId: this.getUserIdFromTimeRange(targetTimeSpan),
            });
        },
        onDropOperatorOnDepartment(event) {
            const operatorId = parseInt(event.dataTransfer.getData('operatorId'), 10);
            if (!operatorId || !this.department) {
                return;
            }
            let currentlyEditingNewDepartmentUserSchedule = {
                user_id: operatorId,
                user: User.find(operatorId),
                department: this.department,
                department_id: this.department?.id,
            };
            this.$emit('menuNew', currentlyEditingNewDepartmentUserSchedule, this.date);
        },
        onClickMenuNew() {
            // create a temp departmentUserSchedule
            let currentlyEditingNewDepartmentUserSchedule = {
                user_id: null,
                user: null,
                department: this.department,
                department_id: this.department?.id,
                reason: '',
            };
            this.$emit('menuNew', currentlyEditingNewDepartmentUserSchedule, this.date);
        },
        onClickMenuEdit(departmentUserSchedule, date) {
            if (departmentUserSchedule.from && departmentUserSchedule.to) {
                this.$emit('menuEdit', departmentUserSchedule, date);
            }
        },
        getUserIdFromTimeSlot(timeslot) {
            const userId = this.availableTimeSlots[timeslot];
            if (!userId || (typeof userId === 'boolean' && userId)) {
                return null;
            }
            return userId;
        },
        getUserIdFromTimeRange(timeRange, getAll = false) {
            const allSlots = this.getAllTimeSpanSlots(timeRange);
            let userIds = new Set();
            for (let i = 0; i < allSlots.length; i++) {
                const userId = this.getUserIdFromTimeSlot(allSlots[i]);
                if (userId) {
                    if (!getAll) {
                        return userId;
                    } else {
                        userIds.add(userId);
                    }
                }
            }
            return getAll && userIds.size > 0 ? [...userIds] : null;
        },
        updateCurrentlyDraggedTaskType(type) {
            this.$emit('setCurrentlyDraggedTaskType', type);
        },
        /**
         * @param {string} type
         * @returns {boolean}
         */
        departmentSupportCurrentlyDraggedTaskType(type) {
            if (this.department && type) {
                // this means we need to check matching
                return !type || this.department?.sub_order_types?.includes(type);
            }
            return true;
        },
        onClickOpenDropDownMenu(event) {
            let data = {
                department: this.department,
                departmentUserSchedules: this.departmentUserSchedules,
                departmentLabel: this.departmentLabel,
                srcElement: event.srcElement,
            };
            this.$emit('setCurrentlyOpenedDropDownMenu', data);
        },
        plannableHasStartTimeOrEstimatedDuration(plannable) {
            return plannable?.sub_order?.start_time || plannable?.sub_order?.estimated_duration;
        },
        getStartTimeDurationStringFromPlannable(plannable) {
            const startTime = plannable?.sub_order?.start_time;
            const estimatedDuration = plannable?.sub_order?.estimated_duration;
            if (startTime && estimatedDuration) {
                return `@${startTime} est:${estimatedDuration}t`;
            } else if (startTime) {
                return `@${startTime}`;
            } else if (estimatedDuration) {
                return `est: ${estimatedDuration}`;
            } else return '';
        },
        getAssignedOperatorsInTimeSpan(plannableTuple) {
            plannableTuple = plannableTuple || {};
            const allAssignedOperators = { ...(plannableTuple?.plannable?.assigned_operators || {}) };
            if (plannableTuple?.timeSpanUUID) {
                const timeSpan = plannableTuple?.plannable?.time_spans[plannableTuple?.timeSpanUUID];
                const userIds = this.getUserIdFromTimeRange(timeSpan, true) || [];
                return _.map(allAssignedOperators, (operator) => {
                    return { ...operator, activeOnDepartment: userIds.includes(operator.user_id) || !this.department };
                });
            } else {
                return _.map(allAssignedOperators, (operator) => {
                    return { ...operator, activeOnDepartment: !this.department };
                });
            }
        },
        formatUnixTime,
    },
    mounted() {},
};
</script>
<style lang="scss">
.resource-department {
    &:last-child {
        .timeslot {
            @apply border-r-[1px];
        }
    }
}
</style>
