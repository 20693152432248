<template>
    <CardTemplate
        :header="subOrder.address"
        :task="task"
        :customer="subOrder.customer"
        :date="plannable && plannable.date ? plannable.date : subOrder.date"
        :dateEnd="plannable && plannable.date ? null : subOrder.date_end"
        :priority="priority"
        :plannable="plannable"
        :loading="loading"
        @show-operators="$emit('show-operators')"
        @show-work-logs="$emit('show-work-logs')"
    >
        <template v-slot:link>
            <router-link
                :to="{
                    name: 'order-detail',
                    params: { uuid: subOrder.order_uuid },
                }"
                class="text-blue-500 body-sm underline"
            >
                #{{ plannable ? plannable.id : subOrder.order_sequence_no ? subOrder.order_sequence_no : subOrder.order_uuid.slice(0, 5) }}
            </router-link>
        </template>

        <template v-if="subOrder.flushing" v-slot:flushing> (+ <IconFlushing class="inline-block h-4" />) </template>

        <template v-if="subOrder.inspection" v-slot:inspection> (+ <IconInspection class="inline-block h-4" />) </template>
    </CardTemplate>
</template>

<script>
import IconFlushing from '@/assets/svg/flushing.svg';
import IconInspection from '@/assets/svg/inspection.svg';
import CardTemplate from '@/components/future/CardTemplate';
import { PRIORITIES } from '@/constants';

export default {
    components: {
        CardTemplate,
        IconFlushing,
        IconInspection,
    },
    props: {
        subOrder: {
            type: Object,
            default: function () {
                return {};
            },
        },
        plannable: {
            type: Object,
        },
        loading: {
            type: Object,
        },
    },
    computed: {
        task() {
            return this.$t('sub_order.type.' + this.subOrder.type_id);
        },
        priority() {
            if (this.subOrder.urgency === 'urgent') {
                return PRIORITIES.HIGH;
            }
            if (this.subOrder.urgency === 'same_day') {
                return PRIORITIES.MEDIUM;
            }
            return PRIORITIES.LOW;
        },
    },
};
</script>
