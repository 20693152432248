<template>
    <div class="inline-block" ref="wrapper">
        <slot name="activator" :on="{ click: onClickActivator }" />
        <div v-if="show" class="anchored-dropdown-content fixed z-1000" :style="{ top: top || null, left: left || null }">
            <div class="dropdown-arrow-top relative" :style="{ left: arrowLeft + 'px' || null }"></div>
            <div class="rounded-lg bg-white shadow overflow-hidden">
                <slot name="default" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            top: null,
            left: null,
            arrowLeft: null,
        };
    },
    props: {},
    methods: {
        onClickActivator() {
            this.show = !this.show;
            this.$emit(this.show ? 'opened' : 'closed');
            const bounds = this.$refs.wrapper.getBoundingClientRect();
            this.top = bounds.bottom;
            this.left = bounds.left;
            this.arrowLeft = bounds.width / 2;
        },
        onMousedown(event) {
            if (!this.$refs.wrapper.contains(event.target) && this.show) {
                this.show = false;
                this.$emit('closed');
            }
        },
    },
    mounted() {
        document.addEventListener('mousedown', this.onMousedown);
    },
    beforeDestroy() {
        document.removeEventListener('mousedown', this.onMousedown);
    },
};
</script>

<style lang="scss">
.anchored-dropdown-content {
    .dropdown-arrow-top {
        position: relative;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent white transparent;
        transform: translateX(-50%);
    }
}
</style>
