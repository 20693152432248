<template>
    <div class="h-6 w-6 rounded-full flex justify-center items-center" :class="properties.backgroundColor">
        <component v-if="properties.component" :is="properties.component" class="text-white" />
        <span v-else class="w-6">&nbsp;</span>
    </div>
</template>

<script>
import IconTimelapse from '@/assets/svg/timelapse.svg';
import { STATUSES } from '@/store/models/WorkLog';

export default {
    components: {
        IconTimelapse,
    },
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    computed: {
        properties() {
            const res = {};
            switch (this.status) {
                case STATUSES.AVAILABLE:
                    res.component = null;
                    res.backgroundColor = 'bg-grey';
                    break;
                case STATUSES.IN_PROGRESS:
                    res.component = IconTimelapse;
                    res.backgroundColor = 'bg-orange';
                    break;

                default:
                    break;
            }

            return res;
        },
    },
};
</script>
