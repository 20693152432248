import { Model } from '@vuex-orm/core';

import Order from './Order';

export const STATUSES = {
    AVAILABLE: 'available',
    BUSY: 'busy',
    UNAVAILABLE: 'unavailable',
};

export default class User extends Model {
    static fields() {
        return {
            id: this.number(),
            name: this.string(),
            initials: this.string().nullable(),
            phone: this.string().nullable(),
            email: this.string(),
            admin: this.boolean(),
            is_developer: this.boolean(),
            is_operator: this.boolean(),
            updated_at: this.number(),
            created_at: this.number(),

            work_logs: this.attr(),
            status: this.string(),
            sort: this.number(),
            location_entry_per_device: this.attr(),
            unread_chat_message_count: this.number(),

            created_orders: this.hasMany(Order, 'created_by_user_id'),
        };
    }

    get isUnavailable() {
        return this.status === STATUSES.UNAVAILABLE;
    }
    get isAvailable() {
        return this.status === STATUSES.AVAILABLE;
    }
}

User.entity = 'users';
User.primaryKey = 'id';
