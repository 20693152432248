import { Model } from '@vuex-orm/core';

export default class Department extends Model {
    static fields() {
        return {
            id: this.number(),
            code: this.string(),
            name: this.string(),
            description: this.string(),
            label: this.string().nullable(),
            sub_order_types: this.attr().nullable(),
            created_at: this.number(),
            updated_at: this.number(),
        };
    }
}

Department.entity = 'departments';
Department.primaryKey = 'id';
