import { Model } from '@vuex-orm/core';

import Order from './Order';

export default class File extends Model {
    static fields() {
        return {
            ext: this.string(),
            name: this.string(),
            needs_approval: this.boolean(),
            order: this.belongsTo(Order, 'order_uuid'),
            order_uuid: this.string(),
            original_filename: this.string(),
            role: this.string(),
            sha1: this.string(),
            uuid: this.string(),
        };
    }
}

File.entity = 'files';
File.primaryKey = 'uuid';
