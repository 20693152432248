import Vue from 'vue';
import Vuex from 'vuex';

import orderForm from '@/store/modules/orderForm';

Vue.use(Vuex);

import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import axios from 'axios';
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';

import ChatChannel from '@/store/models/ChatChannel';
import ChatMessage from '@/store/models/ChatMessage';
import Department from '@/store/models/Department';
import DepartmentUserSchedule from '@/store/models/DepartmentUserSchedule';
import Plannable from '@/store/models/Plannable';
import PostitNote from '@/store/models/PostitNote';
import Tank from '@/store/models/Tank';
import TankAgreement from '@/store/models/TankAgreement';
import WorkLog from '@/store/models/WorkLog';

import actions from './actions';
import getters from './getters';
import AdditionalFile from './models/AdditionalFile';
import AnalyzedFile from './models/AnalyzedFile';
import AutocompleteValue from './models/AutocompleteValue';
import Contact from './models/Contact';
import Customer from './models/Customer';
import DiggingDeclarationFile from './models/DiggingDeclarationFile';
import DisposalLocation from './models/DisposalLocation';
import File from './models/File';
import MapFile from './models/MapFile';
import Order from './models/Order';
import SubOrder from './models/SubOrder';
import User from './models/User';
import WasteType from './models/WasteType';
import mutations, { MUTATIONS } from './mutations';

VuexORM.use(VuexORMAxios, { axios });
const database = new VuexORM.Database();
database.register(AdditionalFile);
database.register(AnalyzedFile);
database.register(AutocompleteValue);
database.register(Contact);
database.register(Customer);
database.register(DepartmentUserSchedule);
database.register(DisposalLocation);
database.register(DiggingDeclarationFile);
database.register(File);
database.register(MapFile);
database.register(Order);
database.register(SubOrder);
database.register(User);
database.register(WasteType);
database.register(WorkLog);
database.register(ChatChannel);
database.register(ChatMessage);
database.register(Plannable);
database.register(TankAgreement);
database.register(Tank);
database.register(Department);
database.register(PostitNote);

const initialDate = new Date();
const initialDateStr = format(initialDate, 'yyyy-MM-dd', { locale: nb });

const store = new Vuex.Store({
    state: {
        user: null,
        tenant: null,
        tenants: null,
        currentTenantId: localStorage.getItem('currentTenantId'),
        config: null,
        modules: null,
        revisionsDocument: {},
        today: initialDateStr,
        plannerDateStart: initialDateStr,
        plannerDateEnd: initialDateStr,
        plannerFilterUserIds: [],
        plannerCurrentlyDraggedPlannableTuple: null,
        plannerCurrentlyResizingPlannableTuple: null,
        plannerDragDestinationDepartmentId: null,
        plannerHighlightedPlannable: null,
        plannerFakeTimeSpansForPlannables: {},
        plannerColumnsExpanded: {
            tanks: true,
            sub_orders: true,
            unplanned: true,
            planned_0: true,
            planned_1: true,
            planned_2: true,
            planned_3: true,
            planned_4: true,
            planned_5: true,
            planned_6: true,
            in_progress: true,
            completed: true,
        },
        isObjectsLoaded: false,
        revisionMaps: {
            [Order.entity]: {},
            [SubOrder.entity]: {},
            [Plannable.entity]: {},
            [WorkLog.entity]: {},
            [TankAgreement.entity]: {},
            [Tank.entity]: {},
            [Customer.entity]: {},
            [Contact.entity]: {},
            [AutocompleteValue.entity]: {},
            [WasteType.entity]: {},
            [DisposalLocation.entity]: {},
            [Department.entity]: {},
            [PostitNote.entity]: {},
            [DepartmentUserSchedule.entity]: {},
        },
        revisionHashes: {
            [Order.entity]: null,
            [SubOrder.entity]: null,
            [Plannable.entity]: null,
            [WorkLog.entity]: null,
            [TankAgreement.entity]: null,
            [Tank.entity]: null,
            [Customer.entity]: null,
            [Contact.entity]: null,
            [AutocompleteValue.entity]: null,
            [WasteType.entity]: null,
            [DisposalLocation.entity]: null,
            [Department.entity]: null,
            [PostitNote.entity]: null,
            [DepartmentUserSchedule.entity]: null,
        },
        completedWorkLogCount: 0,
    },
    getters,
    mutations,
    actions,
    modules: { orderForm },
    plugins: [VuexORM.install(database)],
});

function setTimeoutForTomorrow() {
    const tomorrow = new Date();
    tomorrow.setHours(24, 0, 0, 0);
    const timeUntilTomorrow = tomorrow - new Date();
    setTimeout(() => {
        updateToday();
    }, timeUntilTomorrow);
}
function updateToday() {
    const today = format(new Date(), 'yyyy-MM-dd', { locale: nb });
    store.commit(MUTATIONS.SET_TODAY, today);
    setTimeoutForTomorrow();
}

setTimeoutForTomorrow();

export default store;
