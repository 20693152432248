var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VSelect',_vm._g(_vm._b({attrs:{"options":_vm.options,"value":_vm.value},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('div',{staticClass:"flex items-center w-full"},[_c('VAvatar',{attrs:{"background":"bg-white","size":50}},[_vm._v(" "+_vm._s(option.initials)+" ")]),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(option.label))]),_c('BadgeStatusOperator',{staticClass:"ml-auto",attrs:{"status":option.status}})],1)]}}])},'VSelect',{
        clearable: true,
        multiple: true,
        label: 'Velg operatør',
        placeholder: '-',
        taggable: false,
        ..._vm.$attrs,
    },false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }