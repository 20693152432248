import axios from 'axios';
import Vue from 'vue';

let headerMap = {
    'X-Requested-With': 'XMLHttpRequest',
};
let selectedTenantId = localStorage.getItem('currentTenantId');
if (selectedTenantId) {
    headerMap['X-ZAPIT-TenantID'] = selectedTenantId;
}
const apiAxiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        common: headerMap,
    },
});

apiAxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.response?.status === 401) {
            window.location = process.env.VUE_APP_LOGIN_URL + '?redirect=' + encodeURI(window.location);
        } else if (error?.response?.status === 404) {
            // Assuming this will be handled by the components
        } else {
            /*
            If error has a response property, we're dealing with a server error. Network
            errors, as in when the user has no internet connection, don't have a response
            property;
            */
            const errorMessage = error.response
                ? 'Noe gikk galt, prøv igjen'
                : 'Det ser ut som du har et nettverksproblem. Vennligst sjekk og prøv igjen';
            Vue.toasted.error(errorMessage);
        }

        return Promise.reject(error);
    }
);

const PATH_ROOT = '/api/planner';

// -----------------------------------------------------------------------
// GET
// -----------------------------------------------------------------------

export const autocompleteValueGet = async (id) => {
    return gateway('GET', `/autocomplete_values/${id}`);
};

export const customerGet = async (id) => {
    return gateway('GET', `/customers/${id}`);
};

export const contactGet = async (id) => {
    return gateway('GET', `/contacts/${id}`);
};

export const customerMessagesGet = async (orderUUID) => {
    return gateway('GET', `/messaging/get_customer_messages/${orderUUID}`);
};

export const orderGet = async (uuid) => {
    return gateway('GET', `/orders/${uuid}`);
};

export const orderUpdatesGet = async (uuid) => {
    return gateway('GET', `/updates/order/${uuid}`);
};

export const ordersGet = async () => {
    return gateway('GET', '/orders');
};

export const subOrderGet = async (uuid) => {
    return gateway('GET', `/sub_orders/${uuid}`);
};

export const subOrderUpdatesGet = async (uuid) => {
    return gateway('GET', `/updates/sub_order/${uuid}`);
};

export const plannableGet = async (id) => {
    return gateway('GET', `/plannables/${id}`);
};
export const workLogGet = async (uuid) => {
    return gateway('GET', `/work_logs/${uuid}`);
};
export const tankAgreementGet = async (id) => {
    return gateway('GET', `/tank_agreements/${id}`);
};
export const tankGet = async (id) => {
    return gateway('GET', `/tanks/${id}`);
};
export const wasteTypeGet = async (id) => {
    return gateway('GET', `/waste_types/${id}`);
};
export const disposalLocationGet = async (id) => {
    return gateway('GET', `/disposal_locations/${id}`);
};
export const departmentGet = async (id) => {
    return gateway('GET', `/departments/${id}`);
};
export const departmentUserScheduleGet = async (id) => {
    return gateway('GET', `/department_user_schedules/${id}`);
};

export const postitNoteGet = async (id) => {
    return gateway('GET', `/postit_notes/${id}`);
};

export const userGet = async () => {
    return gateway('GET', '/user');
};

export const usersGet = async () => {
    return gateway('GET', '/users');
};

export const revisionMapGet = async (revisionChecksums) => {
    return gateway('GET', '/revisionMap', revisionChecksums);
};

export const objectMapGet = async () => {
    return gateway('GET', '/objectMap');
};

export const chatMessagesGet = async (lastTimestamp) => {
    return gateway('GET', '/messaging/chat_messages', {
        last_timestamp: lastTimestamp,
    });
};

export const workLogModalDataGet = async (payload) => {
    return gateway('GET', '/workLogModalData', payload);
};

export const productDataGet = async () => {
    return gateway('GET', '/productData');
};

// -----------------------------------------------------------------------
// POST
// -----------------------------------------------------------------------

export const plannableAssignOperators = async (plannableId, operatorIds) => {
    return gateway('POST', `/planning/assignPlannableOperators/${plannableId}`, {
        operator_ids: operatorIds,
    });
};

export const plannableSetOperatorExecutionOrder = async (plannableId, operatorId, executionOrder) => {
    return gateway('POST', `/planning/setPlannableOperatorExecutionOrder/${plannableId}`, {
        operator_id: operatorId,
        execution_order: executionOrder,
    });
};

export const customerMessageSend = async (orderUUID, message, emailRecipients, smsRecipients) => {
    return gateway('POST', `/messaging/send_message_to_customer/${orderUUID}`, {
        message,
        email_recipients: emailRecipients.join(','),
        sms_recipients: smsRecipients.join(','),
    });
};

export const new247CustomerCreate = async (data) => {
    return gateway('POST', '/integrations/create_new_247_customer', data);
};

export const synchronize247Order = async (twentyfoursevenOrderUuid, workLogUuids) => {
    return gateway('POST', '/integrations/synchronize_247_order', {
        twentyfourseven_order_uuid: twentyfoursevenOrderUuid,
        work_log_uuids: workLogUuids,
    });
};

export const orderCreate = async ({ updates } = {}) => {
    return gateway('POST', '/orders', { updates });
};

export const orderUpdateStatus = async (uuid, status) => {
    return gateway('POST', `/planning/update_order_status/${uuid}`, { status });
};

export const subOrderCreate = async ({ updates }) => {
    return gateway('POST', '/sub_orders', { updates });
};

export const plannableStatusUpdate = async (id, status, orderedIdList, date) => {
    return gateway('POST', `/planning/update_plannable_status/${id}`, {
        date,
        status,
        ordered_id_list: orderedIdList,
    });
};

export const tankCreatePlannable = async (tankId, date, status) => {
    return gateway('POST', `/planning/create_plannable_for_tank/${tankId}`, {
        date,
        status,
    });
};

export const plannableTimeSpanResize = async (plannableId, timeSpanUUID, to, userId) => {
    return gateway('POST', `/planning/update_plannable_time_spans/${plannableId}/resize`, {
        time_span_uuid: timeSpanUUID,
        to,
        user_id: userId,
    });
};

export const plannableTimeSpanMove = async (plannableId, timeSpanUUID, timeSpan) => {
    return gateway('POST', `/planning/update_plannable_time_spans/${plannableId}/move`, {
        ...timeSpan,
        time_span_uuid: timeSpanUUID,
    });
};

export const plannableTimeSpanCreate = async (plannableId, timeSpanUUID, timeSpan) => {
    return gateway('POST', `/planning/update_plannable_time_spans/${plannableId}/create`, {
        ...timeSpan,
        time_span_uuid: timeSpanUUID,
    });
};

export const plannableTimeSpanSplit = async (
    plannableId,
    originalTimeSpanUUID,
    splitTimeSpanUUID,
    splitTimeSpanFrom,
    originalTimeSpanUserId,
    splitTimeSpanUserId
) => {
    return gateway('POST', `/planning/update_plannable_time_spans/${plannableId}/split`, {
        original_time_span_uuid: originalTimeSpanUUID,
        original_time_span_user_id: originalTimeSpanUserId,
        split_time_span_uuid: splitTimeSpanUUID,
        split_time_span_from: splitTimeSpanFrom,
        split_time_span_user_id: splitTimeSpanUserId,
    });
};

export const plannableTimeSpansJoin = async (plannableId, targetTimeSpanUUID, joinedTimeSpanUUID, joinedTimeSpanTo, joinedTimeSpanUserId) => {
    return gateway('POST', `/planning/update_plannable_time_spans/${plannableId}/join`, {
        target_time_span_uuid: targetTimeSpanUUID,
        joined_time_span_uuid: joinedTimeSpanUUID,
        joined_time_span_to: joinedTimeSpanTo,
        joined_time_span_user_id: joinedTimeSpanUserId || null,
    });
};

export const fileCreate = async (formData) => {
    return gateway('POST', '/media/file', formData);
};

export const workLogUpdateStatus = async (uuid, status) => {
    return gateway('POST', `/planning/update_work_log_status/${uuid}`, {
        status,
    });
};

export const chatMessagesMarkRead = async (chatChannelUuid, unreadChatMessageUuids) => {
    return gateway('POST', '/messaging/mark_chat_messages_read', {
        chat_channel_uuid: chatChannelUuid,
        chat_message_uuids: unreadChatMessageUuids,
    });
};

export const chatMessageAdd = async (data) => {
    return gateway('POST', '/messaging/add_chat_message', data);
};

export const postitNoteCreate = async (values) => {
    return gateway('POST', '/postit_notes', values);
};

export const deparmentUserScheduleCreate = async (values) => {
    return gateway('POST', '/department_user_schedules', values);
};

// -----------------------------------------------------------------------
// PATCH
// -----------------------------------------------------------------------

export const orderUpdate = async (uuid, { updates, deletes }) => {
    return gateway('PATCH', `/orders/${uuid}`, { updates, deletes });
};

export const subOrderUpdate = async (uuid, { updates, deletes }) => {
    return gateway('PATCH', `/sub_orders/${uuid}`, { updates, deletes });
};

export const workLogUpdate = async (workLogUuid, updates, deletes) => {
    return gateway('PATCH', `/work_logs/${workLogUuid}`, {
        updates,
        deletes,
    });
};

export const postitNoteUpdate = async (postitNoteId, values) => {
    return gateway('PATCH', `/postit_notes/${postitNoteId}`, values);
};

export const departmentUserScheduleUpdate = async (id, values) => {
    return gateway('PATCH', `/department_user_schedules/${id}`, values);
};

// -----------------------------------------------------------------------
// DELETE
// -----------------------------------------------------------------------

export const orderDelete = async (uuid) => {
    return gateway('DELETE', `/orders/${uuid}`);
};

export const subOrderDelete = async (uuid) => {
    return gateway('DELETE', `/sub_orders/${uuid}`);
};

export const workLogDelete = async (uuid) => {
    return gateway('DELETE', `/work_logs/${uuid}`);
};

export const departmentUserScheduleDelete = async (id) => {
    return gateway('DELETE', `/department_user_schedules/${id}`);
};

// -----------------------------------------------------------------------
// GATEWAY
// -----------------------------------------------------------------------

async function gateway(method, path, payload = null) {
    try {
        const url = PATH_ROOT + path;
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const args = { method, url, options };

        // Handle HTTP methods
        switch (method) {
            case 'GET':
                args.params = payload;
                break;
            case 'POST':
            case 'PUT':
            case 'PATCH':
            case 'DELETE':
                args.data = payload;
                break;
        }

        // Make XHR call
        const response = await apiAxiosInstance(args);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
