import { Model } from '@vuex-orm/core';

export default class ChatMessage extends Model {
    static fields() {
        return {
            uuid: this.string(),
            chat_channel_uuid: this.string(),
            author_user_id: this.number(),
            author_name: this.string(),
            author_role: this.string(),
            channels: this.attr([]),
            text: this.string(),
            unread_by_user_ids: this.attr([]),
            unread_by_roles: this.attr([]),
            ref_type: this.string().nullable(),
            ref_id: this.string().nullable(),
            deleted_at: this.number().nullable(),
            created_at: this.number(),
            updated_at: this.number(),
        };
    }
}

ChatMessage.entity = 'chat messages';
ChatMessage.primaryKey = 'uuid';
