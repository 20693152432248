<template>
    <div
        class="flex justify-center items-center text-blue-600 rounded-full relative"
        :class="[background, size > 50 ? 'h3' : 'h4']"
        :style="{ height: `${size}px`, width: `${size}px` }"
    >
        <img v-if="imageSrc" class="rounded-full" :alt="imageAlt" :height="size" :src="imageSrc" :width="size" />
        <IconBlock v-if="refusedAt" class="absolute text-red"></IconBlock>
        <div class="absolute -top-1 -right-1">
            <slot name="badge-tr" />
        </div>
        <div class="absolute -bottom-1 -right-1">
            <slot name="badge-br" />
        </div>
        <div class="absolute -top-1 -left-1">
            <slot name="badge-tl" />
        </div>
        <div class="absolute -bottom-1 -left-1">
            <slot name="badge-bl" />
        </div>
        <slot name="default" />
    </div>
</template>

<script>
import IconBlock from '@/assets/svg/block.svg';

export default {
    components: {
        IconBlock,
    },
    props: {
        background: {
            type: String,
            default: 'bg-blue-300',
        },
        imageAlt: {
            type: String,
            default: 'Avatar image',
        },
        imageSrc: {
            type: String,
            default: null,
        },
        size: {
            type: Number,
            default: 64,
        },
        refusedAt: {
            type: Number,
            default: null,
        },
    },
};
</script>
