var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 rounded-lg relative flex flex-col transition-all",class:{
        'w-[480px]': _vm.isExpanded,
        'w-[60px]': !_vm.isExpanded,
        'bg-brown-400': !_vm.isEmpty && _vm.color === 'brown',
        'bg-blue-400': !_vm.isEmpty && _vm.color === 'blue',
        'bg-blue-600 border border-dashed': _vm.isEmpty,
        'border-blue-400': _vm.isEmpty && _vm.color === 'blue',
        'border-brown-400': _vm.isEmpty && _vm.color === 'brown',
        'text-brown-700': !_vm.isEmpty && _vm.color === 'brown',
        'text-blue-700': !_vm.isEmpty && _vm.color === 'blue',
        'text-brown-400': _vm.isEmpty && _vm.color === 'brown',
        'text-blue-400': _vm.isEmpty && _vm.color === 'blue',
    }},[_c('h3',{staticClass:"h3 transition-transform origin-[0.75rem_0.75rem]",class:{ 'rotate-90': !_vm.isExpanded }},[_vm._t("header")],2),(_vm.isExpanded)?[_c('div',{staticClass:"mt-6 flex-grow overflow-y-auto"},[_vm._t("body")],2),(_vm.isEmpty)?_c('div',{staticClass:"absolute top-1/2 left-0 right-0 text-center uppercase text-h3 font-light transform -translate-y-1/2"},[_vm._t("emptyContent")],2):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }