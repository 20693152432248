import { Model } from '@vuex-orm/core';

import Contact from './Contact';

export default class Customer extends Model {
    static fields() {
        return {
            contacts: this.hasMany(Contact, 'customer_id'),
            created_at: this.number(),
            default_contact_email: this.string(),
            default_contact_name: this.string(),
            default_contact_phone: this.string(),
            type: this.string().nullable(),
            street: this.string().nullable(),
            zip: this.string().nullable(),
            city: this.string().nullable(),
            id: this.number(),
            integration_id: this.string().nullable(),
            name: this.string(),
            remote_id: this.number().nullable(),
            tenant_id: this.number(),
            updated_at: this.number(),
        };
    }
}

Customer.entity = 'customers';
Customer.primaryKey = 'id';
