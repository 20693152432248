<template>
    <vue-final-modal
        v-slot="{ close }"
        v-bind="$attrs"
        classes="flex justify-center items-center"
        content-class="relative flex flex-col w-[600px] max-h-full mx-4 p-8 rounded bg-white"
        esc-to-close
        v-on="$listeners"
    >
        <h2 class="h2 text-blue-700">Velg operatør</h2>
        <div class="border rounded-[4px] text-blue-600 mt-8 mb-4">
            <VOperatorSelect color="blue" :assignedOperatorIds="assignedOperatorIds" @change="onChange" />
        </div>
        <button :disabled="isSaving" class="btn-blue" @click="onClickSave(close)">
            {{ isSaving ? 'Lagrer endringer...' : 'Lagre' }}
        </button>
        <button class="absolute top-8 right-8 pa-2 text-blue-700" @click="close">
            <IconClose />
        </button>
    </vue-final-modal>
</template>

<script>
import { isEqual } from 'lodash';
import { VueFinalModal } from 'vue-final-modal';
import { mapActions } from 'vuex';

import IconClose from '@/assets/svg/close.svg';
import VOperatorSelect from '@/components/VOperatorSelect';
import { ACTIONS } from '@/store/actions';

export default {
    components: {
        IconClose,
        VOperatorSelect,
        VueFinalModal,
    },
    props: {
        plannable: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            assignedOperatorIds: [],
            isLoading: false,
            isSaving: false,
        };
    },
    computed: {},
    methods: {
        ...mapActions({
            assignOperators: ACTIONS.ASSIGN_PLANNABLE_OPERATORS,
        }),
        onChange(value) {
            this.assignedOperatorIds = value.map((option) => option.value);
        },
        async onClickSave(close) {
            const currentOperatorIds = Object.keys(this.plannable.assigned_operators)
                .map((key) => Number(key))
                .sort();
            const newOperatorIds = [...this.assignedOperatorIds].sort();

            /*
            Exit early if user didn't effectively change anything
            */
            if (isEqual(currentOperatorIds, newOperatorIds)) {
                close();
                return;
            }

            this.isSaving = true;

            await this.assignOperators({
                plannableId: this.plannable.id,
                operatorIds: this.assignedOperatorIds,
            });

            this.isSaving = false;
            close();
        },
    },
    watch: {
        plannable(plannable) {
            if (!plannable) {
                this.assignedOperatorIds = [];
                return;
            }

            // set selected operator as select value if it exists
            this.assignedOperatorIds = Object.keys(plannable.assigned_operators).map((key) => Number(key));
        },
    },
};
</script>
