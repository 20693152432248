import { Model } from '@vuex-orm/core';

export default class Plannable extends Model {
    static fields() {
        return {
            id: this.number(),
            revision: this.number(),
            batch_no: this.number(),
            batch_start_date: this.string().nullable(),
            status: this.string(),
            date: this.string().nullable(),
            sort_order: this.number().nullable(),

            tank: this.attr({}).nullable(),
            sub_order: this.attr({}).nullable(),
            assigned_operators: this.attr({}),
            work_log_count: this.attr({}),
            time_spans: this.attr({}).nullable(),

            // Deprecated
            tank_id: this.number().nullable(),
            sub_order_uuid: this.string().nullable(),
            updated_at: this.string().nullable(),
        };
    }
}

Plannable.entity = 'plannables';
Plannable.primaryKey = 'id';
