import { doc, onSnapshot } from 'firebase/firestore';
import Vue from 'vue';

import {
    autocompleteValueGet,
    chatMessageAdd,
    chatMessagesGet,
    chatMessagesMarkRead,
    contactGet,
    customerGet,
    deparmentUserScheduleCreate,
    departmentGet,
    departmentUserScheduleDelete,
    departmentUserScheduleGet,
    departmentUserScheduleUpdate,
    // departmentUserScheduleUpdate,
    disposalLocationGet,
    fileCreate,
    objectMapGet,
    orderCreate,
    orderDelete,
    orderGet,
    ordersGet,
    orderUpdate,
    orderUpdatesGet,
    orderUpdateStatus,
    plannableAssignOperators,
    plannableGet,
    plannableSetOperatorExecutionOrder,
    plannableStatusUpdate,
    plannableTimeSpanCreate,
    plannableTimeSpanMove,
    plannableTimeSpanResize,
    plannableTimeSpansJoin,
    plannableTimeSpanSplit,
    postitNoteCreate,
    postitNoteGet,
    postitNoteUpdate,
    productDataGet,
    revisionMapGet,
    subOrderCreate,
    subOrderDelete,
    subOrderGet,
    subOrderUpdate,
    subOrderUpdatesGet,
    tankAgreementGet,
    tankCreatePlannable,
    tankGet,
    userGet,
    usersGet,
    wasteTypeGet,
    workLogDelete,
    workLogGet,
    workLogModalDataGet,
    workLogUpdate,
    workLogUpdateStatus,
} from '@/api';
import { WORK_LOG_STATUSES } from '@/constants';
import ChatChannel from '@/store/models/ChatChannel';
import ChatMessage from '@/store/models/ChatMessage';
import Contact from '@/store/models/Contact';
import Department from '@/store/models/Department';
import DepartmentUserSchedule from '@/store/models/DepartmentUserSchedule';
import Plannable from '@/store/models/Plannable';
import PostitNote from '@/store/models/PostitNote';
import Tank from '@/store/models/Tank';
import TankAgreement from '@/store/models/TankAgreement';
import WorkLog from '@/store/models/WorkLog';
import { getDeletedKeys, getNewOrUpdatedKeys } from '@/util';

import AutocompleteValue from './models/AutocompleteValue';
import Customer from './models/Customer';
import DisposalLocation from './models/DisposalLocation';
import Order from './models/Order';
import SubOrder from './models/SubOrder';
import User from './models/User';
import WasteType from './models/WasteType';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
    UPLOAD_ATTACHMENT: 'UPLOAD_ATTACHMENT',
    ASSIGN_PLANNABLE_OPERATORS: 'ASSIGN_PLANNABLE_OPERATORS',
    SET_PLANNABLE_OPERATOR_EXECUTION_ORDER: 'SET_PLANNABLE_OPERATOR_EXECUTION_ORDER',
    CREATE_DEPARTMENT_USER_SCHEDULE: 'CREATE_DEPARTMENT_USER_SCHEDULE',
    CREATE_ORDER: 'CREATE_ORDER',
    CREATE_SUB_ORDER: 'CREATE_SUB_ORDER',
    DELETE_ORDER: 'DELETE_ORDER',
    DELETE_ORDER_ATTACHMENT: 'DELETE_ORDER_ATTACHMENT',
    DELETE_ORDER_PROPERTY: 'DELETE_ORDER_PROPERTY',
    DELETE_SUB_ORDER: 'DELETE_SUB_ORDER',
    DELETE_SUB_ORDER_ATTACHMENT: 'DELETE_SUB_ORDER_ATTACHMENT',
    DELETE_SUB_ORDER_PROPERTY: 'DELETE_SUB_ORDER_PROPERTY',
    LOAD_AUTOCOMPLETE_VALUE: 'LOAD_AUTOCOMPLETE_VALUE',
    LOAD_CUSTOMER: 'LOAD_CUSTOMER',
    LOAD_CONTACT: 'LOAD_CONTACT',
    LOAD_OPERATORS: 'LOAD_OPERATORS',
    LOAD_ORDER: 'LOAD_ORDER',
    LOAD_ORDER_UPDATES: 'LOAD_ORDER_UPDATES',
    LOAD_ORDERS: 'LOAD_ORDERS',
    LOAD_SUB_ORDER: 'LOAD_SUB_ORDER',
    LOAD_SUB_ORDER_UPDATES: 'LOAD_SUB_ORDER_UPDATES',
    LOAD_PLANNABLE: 'LOAD_PLANNABLE',
    LOAD_WORK_LOG: 'LOAD_WORK_LOG',
    LOAD_TANK_AGREEMENT: 'LOAD_TANK_AGREEMENT',
    LOAD_TANK: 'LOAD_TANK',
    LOAD_WASTE_TYPE: 'LOAD_WASTE_TYPE',
    LOAD_DISPOSAL_LOCATION: 'LOAD_DISPOSAL_LOCATION',
    LOAD_DEPARTMENT: 'LOAD_DEPARTMENT',
    LOAD_DEPARTMENT_USER_SCHEDULE: 'LOAD_DEPARTMENT_USER_SCHEDULE',
    LOAD_POSTIT_NOTE: 'LOAD_POSTIT_NOTE',
    LOAD_USER: 'LOAD_USER',
    LOAD_USERS: 'LOAD_USERS',
    LOAD_OBJECT_MAP: 'LOAD_OBJECT_MAP',
    LOAD_REVISION_MAP: 'LOAD_REVISION_MAP',
    GET_ORDER: 'GET_ORDER',
    GET_SUB_ORDER: 'GET_SUB_ORDER',
    UPDATE_DEPARTMENT_USER_SCHEDULE: 'UPDATE_DEPARTMENT_USER_SCHEDULE',
    UPDATE_ORDER: 'UPDATE_ORDER',
    UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
    UPDATE_SUB_ORDER: 'UPDATE_SUB_ORDER',
    UPDATE_PLANNABLE_STATUS: 'UPDATE_PLANNABLE_STATUS',
    CREATE_PLANNABLE_FOR_TANK: 'CREATE_PLANNABLE_FOR_TANK',
    RESIZE_PLANNABLE_TIME_SPAN: 'RESIZE_PLANNABLE_TIME_SPAN',
    MOVE_PLANNABLE_TIME_SPAN: 'MOVE_PLANNABLE_TIME_SPAN',
    CREATE_PLANNABLE_TIME_SPAN: 'CREATE_PLANNABLE_TIME_SPAN',
    SPLIT_PLANNABLE_TIME_SPAN: 'SPLIT_PLANNABLE_TIME_SPAN',
    JOIN_PLANNABLE_TIME_SPANS: 'JOIN_PLANNABLE_TIME_SPANS',
    UPDATE_WORK_LOG: 'UPDATE_WORK_LOG',
    DELETE_WORK_LOG: 'DELETE_WORK_LOG',
    APPROVE_WORK_LOG: 'APPROVE_WORK_LOG',
    SET_WORK_LOG_INVOICED: 'SET_WORK_LOG_INVOICED',
    START_WORK_LOG: 'START_WORK_LOG',
    COMPLETE_WORK_LOG: 'COMPLETE_WORK_LOG',
    LOAD_CHAT_MESSAGES: 'LOAD_CHAT_MESSAGES',
    MARK_CHAT_MESSAGES_READ: 'MARK_CHAT_MESSAGES_READ',
    ADD_CHAT_MESSAGE: 'ADD_CHAT_MESSAGE',
    CREATE_POSTIT_NOTE: 'CREATE_POSTIT_NOTE',
    UPDATE_POSTIT_NOTE: 'UPDATE_POSTIT_NOTE',
    GET_WORK_LOG_MODAL_DATA: 'GET_WORK_LOG_MODAL_DATA',
    GET_PRODUCT_DATA: 'GET_PRODUCTS',
    DELETE_DEPARTMENT_USER_SCHEDULE: 'DELETE_DEPARTMENT_USER_SCHEDULE',
};

let unbindRevisions = null;

let chatMessagesLastTimestamp = 0;

let revisionsLoaderActive = false;
let revisionsLoaderTimeoutId = null;
let revisionsLoaderImmediateRunRequested = false;
let revisionsLoaderIsExecuting = false;
const revisionsLoaderInterval = 10000;

async function revisionsLoaderExecute(dispatch) {
    try {
        console.log('revisionLoaderExecute');
        await dispatch(ACTIONS.LOAD_USERS);
        await dispatch(ACTIONS.LOAD_REVISION_MAP);
        await dispatch(ACTIONS.LOAD_CHAT_MESSAGES);
    } finally {
        if (revisionsLoaderImmediateRunRequested) {
            revisionsLoaderImmediateRunRequested = false;
            revisionsLoaderExecute(dispatch);
        } else {
            revisionsLoaderIsExecuting = false;
            revisionsLoaderTriggerDelayedNext(dispatch);
        }
    }
}

function revisionsLoaderTriggerDelayedNext(dispatch) {
    if (revisionsLoaderActive) {
        revisionsLoaderTimeoutId = setTimeout(function () {
            revisionsLoaderIsExecuting = true;
            revisionsLoaderTimeoutId = false;
            revisionsLoaderExecute(dispatch);
        }, revisionsLoaderInterval);
    }
}

async function startRevisionsLoaderTimer(dispatch) {
    if (!revisionsLoaderActive) {
        revisionsLoaderActive = true;
        try {
            console.log('startRevisionsLoaderTimer');
            await dispatch(ACTIONS.LOAD_USERS);
            await dispatch(ACTIONS.LOAD_OBJECT_MAP);
            await dispatch(ACTIONS.LOAD_CHAT_MESSAGES);
        } finally {
            revisionsLoaderTriggerDelayedNext(dispatch);
        }
    }
}

// eslint-disable-next-line
async function requestImmediateRevisionsLoader(dispatch) {
    if (revisionsLoaderActive) {
        if (revisionsLoaderIsExecuting) {
            revisionsLoaderImmediateRunRequested = true;
        } else {
            if (revisionsLoaderTimeoutId) {
                clearTimeout(revisionsLoaderTimeoutId);
                revisionsLoaderTimeoutId = null;
            }
            revisionsLoaderIsExecuting = true;
            revisionsLoaderExecute(dispatch);
        }
    }
}

// eslint-disable-next-line
function stopRevisionsLoaderTimer() {
    if (revisionsLoaderActive) {
        revisionsLoaderActive = false;
        if (revisionsLoaderTimeoutId) {
            clearTimeout(revisionsLoaderTimeoutId);
        }
    }
}

async function insertOrUpdateAndDeleteRevisionedObjects(commit, model, newOrUpdatedObjects, deletedIds) {
    if (newOrUpdatedObjects) {
        await model.insertOrUpdate({ data: newOrUpdatedObjects });
        await commit(MUTATIONS.UPDATE_REVISION_MAP_ENTRIES, {
            type: model.entity,
            map: newOrUpdatedObjects.reduce((carry, item) => {
                carry[item[model.primaryKey]] = item.revision;
                return carry;
            }, {}),
        });
    }
    deleteObjects(commit, model, deletedIds);
}

async function insertOrUpdateAndDeleteTimestampedObjects(commit, model, newOrUpdatedObjects, deletedIds) {
    await model.insertOrUpdate({ data: newOrUpdatedObjects });
    await commit(MUTATIONS.UPDATE_REVISION_MAP_ENTRIES, {
        type: model.entity,
        map: newOrUpdatedObjects.reduce((carry, item) => {
            carry[item[model.primaryKey]] = item.updated_at % 10000;
            return carry;
        }, {}),
    });
    deleteObjects(commit, model, deletedIds);
}

async function dispatchLoadAndDeleteObjects(commit, state, dispatch, action, model, revisionMap) {
    if (revisionMap) {
        const newOrUpdatedIds = getNewOrUpdatedKeys(state.revisionMaps[model.entity], revisionMap);
        const deletedIds = getDeletedKeys(state.revisionMaps[model.entity], revisionMap);
        for (const id of newOrUpdatedIds) {
            dispatch(action, id);
        }
        await deleteObjects(commit, model, deletedIds);
    }
}

async function deleteObjects(commit, model, deletedIds) {
    if (deletedIds) {
        for (const deletedId of deletedIds) {
            await model.delete(deletedId);
        }
        await commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, { type: model.entity, keys: deletedIds });
    }
}

export default {
    async [ACTIONS.ASSIGN_PLANNABLE_OPERATORS]({ commit }, { plannableId, operatorIds }) {
        const plannable = await plannableAssignOperators(plannableId, operatorIds);
        insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, [plannable], null);
    },
    async [ACTIONS.SET_PLANNABLE_OPERATOR_EXECUTION_ORDER]({ commit }, { plannableId, operatorId, executionOrder }) {
        const updatedPlannables = await plannableSetOperatorExecutionOrder(plannableId, operatorId, executionOrder);
        insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, updatedPlannables, null);
    },
    async [ACTIONS.CREATE_ORDER]() {
        const order = await orderCreate();

        Order.insert({ data: order });

        return order;
    },
    async [ACTIONS.CREATE_SUB_ORDER](context, { updates }) {
        const subOrder = await subOrderCreate({ updates });

        SubOrder.insert({ data: subOrder });

        /*
        Add SubOrder to parent Order as well
        */
        Order.update({
            where: updates.order_uuid,
            data(order) {
                order.sub_order_uuids.push(subOrder.uuid);
            },
        });

        return subOrder;
    },
    async [ACTIONS.DELETE_ORDER]({ commit }, { uuid }) {
        await orderDelete(uuid);

        const subOrders = SubOrder.query().where('order_uuid', uuid).get();
        const subOrderUuids = [];
        const plannableIds = [];
        const workLogUuids = [];
        for (const subOrder of subOrders) {
            subOrderUuids.push(subOrder.uuid);
        }
        for (const plannable of Plannable.query()
            .where((plannable) => subOrderUuids.includes(plannable.sub_order_uuid))
            .get()) {
            plannableIds.push(plannable.id);
        }
        for (const workLog of WorkLog.query()
            .where((workLog) => subOrderUuids.includes(workLog.sub_order_uuid))
            .get()) {
            workLogUuids.push(workLog.uuid);
        }
        commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, { type: Order.entity, keys: [uuid] });
        commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, { type: SubOrder.entity, keys: subOrderUuids });
        commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, { type: Plannable.entity, keys: plannableIds });
        commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, { type: WorkLog.entity, keys: workLogUuids });
        WorkLog.query().delete((workLog) => workLogUuids.includes(workLog.uuid));
        Plannable.query().delete((plannable) => plannableIds.includes(plannable.id));
        SubOrder.query().delete((subOrder) => subOrderUuids.includes(subOrder.uuid));
        Order.delete(uuid);
    },
    async [ACTIONS.DELETE_SUB_ORDER]({ commit }, { uuid }) {
        await subOrderDelete(uuid);

        const subOrder = SubOrder.find(uuid);
        const orderUUID = subOrder.order_uuid;
        const plannableIds = [];
        const workLogUuids = [];
        for (const plannable of Plannable.query().where('sub_order_uuid', uuid).get()) {
            plannableIds.push(plannable.id);
        }
        for (const workLog of WorkLog.query().where('sub_order_uuid', uuid).get()) {
            workLogUuids.push(workLog.uuid);
        }
        commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, { type: SubOrder.entity, keys: [uuid] });
        commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, { type: Plannable.entity, keys: plannableIds });
        commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, { type: WorkLog.entity, keys: workLogUuids });
        WorkLog.query().delete((workLog) => workLogUuids.includes(workLog.uuid));
        Plannable.query().delete((plannable) => plannableIds.includes(plannable.id));
        subOrder.$delete();

        /*
        Remove SubOrder from parent Order as well
        */
        Order.update({
            where: orderUUID,
            data(order) {
                order.sub_order_uuids = order.sub_order_uuids.filter((subOrderUUID) => subOrderUUID !== uuid);
            },
        });
    },
    async [ACTIONS.LOAD_AUTOCOMPLETE_VALUE]({ commit }, id) {
        const autocompleteValue = await autocompleteValueGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, AutocompleteValue, [autocompleteValue], null);
    },
    async [ACTIONS.LOAD_CUSTOMER]({ commit }, id) {
        const customer = await customerGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, Customer, [customer], null);
    },
    async [ACTIONS.LOAD_CONTACT]({ commit }, id) {
        const contact = await contactGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, Contact, [contact], null);
    },
    async [ACTIONS.LOAD_ORDER]({ commit }, uuid) {
        const order = await orderGet(uuid);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Order, [order], null);
    },
    async [ACTIONS.LOAD_ORDER_UPDATES](context, uuid) {
        const updates = await orderUpdatesGet(uuid);
        return updates;
    },
    async [ACTIONS.LOAD_ORDERS]() {
        const orders = await ordersGet();
        await Order.insertOrUpdate({
            data: orders,
        });
    },
    async [ACTIONS.LOAD_SUB_ORDER]({ commit }, uuid) {
        const subOrder = await subOrderGet(uuid);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, SubOrder, [subOrder], null);
    },
    async [ACTIONS.LOAD_SUB_ORDER_UPDATES](context, uuid) {
        const updates = await subOrderUpdatesGet(uuid);
        return updates;
    },
    async [ACTIONS.LOAD_PLANNABLE]({ commit }, id) {
        const plannable = await plannableGet(id);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, [plannable], null);
    },
    async [ACTIONS.LOAD_WORK_LOG]({ commit }, uuid) {
        const workLog = await workLogGet(uuid);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, WorkLog, [workLog], null);
    },
    async [ACTIONS.LOAD_TANK_AGREEMENT]({ commit }, id) {
        const tankAgreement = await tankAgreementGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, TankAgreement, [tankAgreement], null);
    },
    async [ACTIONS.LOAD_TANK]({ commit }, id) {
        const tank = await tankGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, Tank, [tank], null);
    },
    async [ACTIONS.LOAD_WASTE_TYPE]({ commit }, id) {
        const wasteType = await wasteTypeGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, WasteType, [wasteType], null);
    },
    async [ACTIONS.LOAD_DISPOSAL_LOCATION]({ commit }, id) {
        const disposalLocation = await disposalLocationGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, DisposalLocation, [disposalLocation], null);
    },
    async [ACTIONS.LOAD_DEPARTMENT]({ commit }, id) {
        const department = await departmentGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, Department, [department], null);
    },
    async [ACTIONS.LOAD_DEPARTMENT_USER_SCHEDULE]({ commit }, id) {
        const departmentUserSchedule = await departmentUserScheduleGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, DepartmentUserSchedule, [departmentUserSchedule], null);
    },
    async [ACTIONS.LOAD_POSTIT_NOTE]({ commit }, id) {
        const postitNote = await postitNoteGet(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, PostitNote, [postitNote], null);
    },
    async [ACTIONS.LOAD_USER]({ commit, dispatch, state }) {
        return userGet()
            .then((data) => {
                let rootPath = process.env.VUE_APP_FIRESTORE_ROOT + '/root/tenant/' + data.tenant.id;
                let firestoreRoot = doc(Vue.prototype.$firestoreDB, rootPath);

                if (unbindRevisions) {
                    unbindRevisions();
                }
                unbindRevisions = onSnapshot(firestoreRoot, (snapshot) => {
                    commit(MUTATIONS.SET_REVISIONS_DOCUMENT, snapshot.data());
                });
                commit(MUTATIONS.SET_USER, data);
                if (
                    state.modules != null &&
                    state.modules.some((module) => module.id === 'planner') &&
                    state.user &&
                    state.tenant &&
                    (state.user.admin || state.tenant.pivot.is_admin)
                ) {
                    startRevisionsLoaderTimer(dispatch);
                }

                return data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    throw error;
                } else {
                    throw 'Unknown error';
                }
            });
    },
    async [ACTIONS.LOAD_USERS]() {
        const users = await usersGet();

        User.insertOrUpdate({
            data: users,
        });
    },
    async [ACTIONS.LOAD_OBJECT_MAP]({ commit }) {
        const data = await objectMapGet();
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Order, data.orders, null);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, SubOrder, data.sub_orders, null);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, data.plannables, null);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, WorkLog, data.work_logs, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, TankAgreement, data.tank_agreements, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, Tank, data.tanks, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, Customer, data.customers, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, Contact, data.contacts, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, AutocompleteValue, data.autocomplete_values, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, WasteType, data.waste_types, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, DisposalLocation, data.disposal_locations, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, Department, data.departments, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, PostitNote, data.postit_notes, null);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, DepartmentUserSchedule, data.department_user_schedules, null);

        await commit(MUTATIONS.UPDATE_COMPLETED_WORK_LOG_COUNT, data.completed_work_log_count);

        await commit(MUTATIONS.OBJECT_MAP_LOADED);
    },
    async [ACTIONS.LOAD_REVISION_MAP]({ state, commit, dispatch }) {
        const data = await revisionMapGet({
            order_revision_map_sha1: state.revisionHashes[Order.entity],
            sub_order_revision_map_sha1: state.revisionHashes[SubOrder.entity],
            plannable_revision_map_sha1: state.revisionHashes[Plannable.entity],
            work_log_revision_map_sha1: state.revisionHashes[WorkLog.entity],
            tank_agreement_updated_at_map_sha1: state.revisionHashes[TankAgreement.entity],
            tank_updated_at_map_sha1: state.revisionHashes[Tank.entity],
            customer_updated_at_map_sha1: state.revisionHashes[Customer.entity],
            contact_updated_at_map_sha1: state.revisionHashes[Contact.entity],
            autocomplete_value_updated_at_map_sha1: state.revisionHashes[AutocompleteValue.entity],
            waste_type_updated_at_map_sha1: state.revisionHashes[WasteType.entity],
            disposal_location_updated_at_map_sha1: state.revisionHashes[DisposalLocation.entity],
            department_updated_at_map_sha1: state.revisionHashes[Department.entity],
            postit_note_updated_at_map_sha1: state.revisionHashes[PostitNote.entity],
            department_user_schedules_updated_at_map_sha1: state.revisionHashes[DepartmentUserSchedule.entity],
        });
        /* eslint-disable prettier/prettier */
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_ORDER, Order, data?.order_revision_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_SUB_ORDER, SubOrder, data?.sub_order_revision_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_PLANNABLE, Plannable, data?.plannable_revision_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_WORK_LOG, WorkLog, data?.work_log_revision_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_TANK_AGREEMENT, TankAgreement, data?.tank_agreement_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_TANK, Tank, data?.tank_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_CUSTOMER, Customer, data?.customer_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_CONTACT, Contact, data?.contact_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_AUTOCOMPLETE_VALUE, AutocompleteValue, data?.autocomplete_value_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_WASTE_TYPE, WasteType, data?.waste_type_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_DISPOSAL_LOCATION, DisposalLocation, data?.disposal_location_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_DEPARTMENT, Department, data?.department_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_POSTIT_NOTE, PostitNote, data?.postit_note_updated_at_map);
        await dispatchLoadAndDeleteObjects(commit, state, dispatch, ACTIONS.LOAD_DEPARTMENT_USER_SCHEDULE, DepartmentUserSchedule, data?.department_user_schedules_updated_at_map);
        /* eslint-enable */

        await commit(MUTATIONS.UPDATE_COMPLETED_WORK_LOG_COUNT, data.completed_work_log_count);
    },
    async [ACTIONS.GET_ORDER](context, uuid) {
        return orderGet(uuid);
    },
    async [ACTIONS.GET_SUB_ORDER](context, uuid) {
        return subOrderGet(uuid);
    },
    async [ACTIONS.CREATE_DEPARTMENT_USER_SCHEDULE]({ commit }, values) {
        const departmentUserSchedule = await deparmentUserScheduleCreate(values);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, DepartmentUserSchedule, [departmentUserSchedule], null);
    },
    async [ACTIONS.UPDATE_DEPARTMENT_USER_SCHEDULE]({ commit }, { id, values }) {
        const departmentUserSchedule = await departmentUserScheduleUpdate(id, values);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, DepartmentUserSchedule, [departmentUserSchedule], null);
    },
    async [ACTIONS.DELETE_DEPARTMENT_USER_SCHEDULE]({ commit }, id) {
        await departmentUserScheduleDelete(id);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, DepartmentUserSchedule, [], [id]);
    },
    async [ACTIONS.UPDATE_ORDER]({ commit }, { uuid, updates, deletes }) {
        const order = await orderUpdate(uuid, { updates, deletes });

        await Order.update({ data: order });
        await commit(MUTATIONS.UPDATE_REVISION_MAP_ENTRIES, {
            type: Order.entity,
            map: { [order.id]: order.revision },
        });

        return order;
    },
    async [ACTIONS.UPDATE_ORDER_STATUS]({ commit }, { uuid, status }) {
        const order = await orderUpdateStatus(uuid, status);

        await Order.update({ data: order });
        await commit(MUTATIONS.UPDATE_REVISION_MAP_ENTRIES, {
            type: Order.entity,
            map: { [order.id]: order.revision },
        });
        return order;
    },
    async [ACTIONS.UPDATE_SUB_ORDER]({ commit }, { uuid, updates, deletes }) {
        const subOrder = await subOrderUpdate(uuid, { updates, deletes });

        await SubOrder.update({
            where: uuid,
            data: subOrder,
        });
        await commit(MUTATIONS.UPDATE_REVISION_MAP_ENTRIES, {
            type: SubOrder.entity,
            map: { [subOrder.id]: subOrder.revision },
        });
        return subOrder;
    },
    async [ACTIONS.UPDATE_PLANNABLE_STATUS]({ commit }, { date, orderedIdList, status, id }) {
        const response = await plannableStatusUpdate(id, status, orderedIdList, date);
        insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, response.updated_plannables, null);
    },
    async [ACTIONS.CREATE_PLANNABLE_FOR_TANK]({ commit }, { tankId, date, status }) {
        const response = await tankCreatePlannable(tankId, date, status);
        if (response.created_plannable) {
            const plannable = response.created_plannable;
            await Plannable.insertOrUpdate({ data: plannable });
            await commit(MUTATIONS.UPDATE_REVISION_MAP_ENTRIES, {
                type: Plannable.entity,
                map: { [plannable.id]: plannable.revision },
            });
            return plannable;
        }
        return null;
    },
    async [ACTIONS.RESIZE_PLANNABLE_TIME_SPAN]({ commit }, { plannableId, timeSpanUUID, to, userId }) {
        const plannable = await plannableTimeSpanResize(plannableId, timeSpanUUID, to, userId);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, [plannable], null);
    },
    async [ACTIONS.MOVE_PLANNABLE_TIME_SPAN]({ commit }, { plannableId, timeSpanUUID, timeSpan }) {
        const changes = await plannableTimeSpanMove(plannableId, timeSpanUUID, timeSpan);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, changes.updated_plannables, changes.deleted_plannable_ids);
    },
    async [ACTIONS.CREATE_PLANNABLE_TIME_SPAN]({ commit }, { plannableId, timeSpanUUID, timeSpan }) {
        const changes = await plannableTimeSpanCreate(plannableId, timeSpanUUID, timeSpan);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, changes.updated_plannables, changes.deleted_plannable_ids);
    },
    async [ACTIONS.SPLIT_PLANNABLE_TIME_SPAN](
        { commit },
        { plannableId, originalTimeSpanUUID, splitTimeSpanUUID, splitTimeSpanFrom, originalTimeSpanUserId, splitTimeSpanUserId }
    ) {
        const plannable = await plannableTimeSpanSplit(
            plannableId,
            originalTimeSpanUUID,
            splitTimeSpanUUID,
            splitTimeSpanFrom,
            originalTimeSpanUserId,
            splitTimeSpanUserId
        );
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, [plannable], null);
    },
    async [ACTIONS.JOIN_PLANNABLE_TIME_SPANS](
        { commit },
        { plannableId, targetTimeSpanUUID, joinedTimeSpanUUID, joinedTimeSpanTo, joinedTimeSpanUserId }
    ) {
        const changes = await plannableTimeSpansJoin(plannableId, targetTimeSpanUUID, joinedTimeSpanUUID, joinedTimeSpanTo, joinedTimeSpanUserId);
        await insertOrUpdateAndDeleteRevisionedObjects(commit, Plannable, changes.updated_plannables, changes.deleted_plannable_ids);
    },
    async [ACTIONS.UPLOAD_ATTACHMENT](context, formData) {
        return await fileCreate(formData);
    },
    // async [ACTIONS.DELETE_ORDER_ATTACHMENT](context, { key, orderUUID, attachmentUUID }) {
    //     await orderPropertyDelete(orderUUID, attachmentUUID, key);
    //     const file = File.find(attachmentUUID);
    //     file.$delete();
    //     Order.update({
    //         where: orderUUID,
    //         data(order) {
    //             order.attachments = order.attachments.filter((uuid) => uuid !== attachmentUUID);
    //         },
    //     });
    // },
    // async [ACTIONS.DELETE_ORDER_PROPERTY](context, { key, orderUUID, propertyId }) {
    //     const order = await orderPropertyDelete(orderUUID, propertyId, key);
    //
    //     Order.update({
    //         where: orderUUID,
    //         data: order,
    //     });
    //
    //     return order;
    // },
    // async [ACTIONS.DELETE_SUB_ORDER_ATTACHMENT](context, { key, subOrderUUID, attachmentUUID }) {
    //     await subOrderPropertyDelete(subOrderUUID, attachmentUUID, key);
    //
    //     /*
    //     All attachment fields should be added to the following mapping, here as well as
    //     in the ADD_SUB_ORDER_ATTACHMENT action
    //     */
    //     const fileModels = {
    //         additional_files: AdditionalFile,
    //         analyzed_files: AnalyzedFile,
    //         digging_declaration_files: DiggingDeclarationFile,
    //         map_files: MapFile,
    //     };
    //
    //     const model = fileModels[key];
    //     const file = model.find(attachmentUUID);
    //     file.$delete();
    //
    //     SubOrder.update({
    //         where: subOrderUUID,
    //         data(order) {
    //             order[key] = order[key].filter((uuid) => uuid !== attachmentUUID);
    //         },
    //     });
    // },
    // async [ACTIONS.DELETE_SUB_ORDER_PROPERTY](context, { key, subOrderUUID, propertyId }) {
    //     const subOrder = await subOrderPropertyDelete(subOrderUUID, propertyId, key);
    //
    //     SubOrder.update({
    //         where: subOrderUUID,
    //         data: subOrder,
    //     });
    //
    //     return subOrder;
    // },
    async [ACTIONS.UPDATE_WORK_LOG](context, { workLogUuid, updates, deletes }) {
        const workLog = await workLogUpdate(workLogUuid, updates, deletes);
        await WorkLog.update({
            data: workLog,
        });
        return workLog;
    },
    async [ACTIONS.DELETE_WORK_LOG](context, workLogUuid) {
        await workLogDelete(workLogUuid);
        await WorkLog.delete(workLogUuid);
    },
    async [ACTIONS.APPROVE_WORK_LOG](context, workLogUuid) {
        const workLog = await workLogUpdateStatus(workLogUuid, WORK_LOG_STATUSES.APPROVED);
        await WorkLog.update({
            data: workLog,
        });
        return workLog;
    },
    async [ACTIONS.START_WORK_LOG](context, workLogUuid) {
        const workLog = await workLogUpdateStatus(workLogUuid, WORK_LOG_STATUSES.IN_PROGRESS);
        await WorkLog.update({
            data: workLog,
        });
        return workLog;
    },
    async [ACTIONS.COMPLETE_WORK_LOG](context, workLogUuid) {
        const workLog = await workLogUpdateStatus(workLogUuid, WORK_LOG_STATUSES.COMPLETED);
        await WorkLog.update({
            data: workLog,
        });
        return workLog;
    },
    async [ACTIONS.SET_WORK_LOG_INVOICED](context, workLogUuid) {
        const workLog = await workLogUpdateStatus(workLogUuid, WORK_LOG_STATUSES.INVOICED);
        await WorkLog.update({
            data: workLog,
        });
        return workLog;
    },
    async [ACTIONS.LOAD_CHAT_MESSAGES]() {
        const response = await chatMessagesGet(chatMessagesLastTimestamp);
        chatMessagesLastTimestamp = response.last_timestamp;
        for (const chatChannelUuid in response.chat_channels) {
            const chatChannelData = response.chat_channels[chatChannelUuid];
            for (const chatMessageData of chatChannelData.messages) {
                chatMessageData.chat_channel_uuid = chatChannelUuid;
            }
            await ChatMessage.insertOrUpdate({
                data: chatChannelData.messages,
            });
            chatChannelData.unread_count = ChatMessage.query()
                .where('chat_channel_uuid', chatChannelUuid)
                .where((message) => {
                    return message.unread_by_roles.includes('backoffice');
                })
                .count();
            await ChatChannel.insertOrUpdate({
                data: chatChannelData,
            });
        }
    },
    async [ACTIONS.MARK_CHAT_MESSAGES_READ](context, { chatChannelUuid, unreadChatMessageUuids }) {
        await chatMessagesMarkRead(chatChannelUuid, unreadChatMessageUuids);
    },
    async [ACTIONS.ADD_CHAT_MESSAGE](context, data) {
        await chatMessageAdd(data);
    },
    async [ACTIONS.CREATE_POSTIT_NOTE]({ commit }, values) {
        const postitNote = await postitNoteCreate(values);
        await insertOrUpdateAndDeleteTimestampedObjects(commit, PostitNote, [postitNote], null);
        return postitNote;
    },
    async [ACTIONS.UPDATE_POSTIT_NOTE]({ commit }, { postitNoteId, updates }) {
        const postitNote = await postitNoteUpdate(postitNoteId, updates);
        if (Object.prototype.hasOwnProperty.call(updates, 'done') && updates.done) {
            await PostitNote.delete(postitNoteId);
            await commit(MUTATIONS.DELETE_REVISION_MAP_ENTRIES, {
                type: PostitNote.entity,
                keys: [postitNoteId],
            });
        } else {
            await PostitNote.insertOrUpdate({
                data: postitNote,
            });
            await commit(MUTATIONS.UPDATE_REVISION_MAP_ENTRIES, {
                type: PostitNote.entity,
                map: { [postitNote.id]: postitNote.updated_at % 10000 },
            });
        }
        return postitNote;
    },
    async [ACTIONS.GET_WORK_LOG_MODAL_DATA](context, plannableId) {
        return await workLogModalDataGet(plannableId ? { plannable_id: plannableId } : {});
    },
    async [ACTIONS.GET_PRODUCT_DATA]() {
        return await productDataGet();
    },
};
