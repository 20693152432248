<template>
    <div>
        <h3 class="h3 mt-4">Tank</h3>
        <dl class="order_information_dl">
            <dt>Navn</dt>
            <dd>{{ tank.name }}</dd>
            <dt>Beskrivelse</dt>
            <dd>{{ tank.description }}</dd>
            <dt>Adresse</dt>
            <dd>{{ tank.address }}</dd>
            <template v-if="tank.volume">
                <dt>Volum</dt>
                <dd>{{ tank.volume }}</dd>
            </template>
            <template v-if="tank.interval">
                <dt>Intervall</dt>
                <dd>{{ tank.interval }}</dd>
            </template>
            <template v-if="tank.last_emptied">
                <dt>Sist tømt</dt>
                <dd>{{ tank.last_emptied }}</dd>
            </template>
            <template v-if="tank.comment">
                <dt>Kommentar</dt>
                <dd>{{ tank.comment }}</dd>
            </template>
            <template v-if="tank.contact">
                <dt>Kontakt</dt>
                <dd>{{ tank.contact }}</dd>
            </template>
            <template v-if="tank.email">
                <dt>Epost</dt>
                <dd>{{ tank.email }}</dd>
            </template>
            <template v-if="tank.phone">
                <dt>Telefon</dt>
                <dd>{{ tank.phone }}</dd>
            </template>
            <template v-if="tank.po_number">
                <dt>Merking / PO</dt>
                <dd>{{ tank.po_number }}</dd>
            </template>
        </dl>
    </div>
</template>

<script>
export default {
    name: 'WorkLogTankInfo',
    props: {
        tank: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss"></style>
