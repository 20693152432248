<template>
    <div>
        <h3 class="h3 mt-4">Avtale</h3>
        <dl class="order_information_dl">
            <dt>Avtalenavn</dt>
            <dd>{{ tankAgreement.name }}</dd>
            <dt>Beskrivelse</dt>
            <dd>{{ tankAgreement.description }}</dd>
            <dt>Kontakt</dt>
            <dd>{{ tankAgreement.contact }}</dd>
            <dt>Epost</dt>
            <dd>{{ tankAgreement.email }}</dd>
            <dt>Telefon</dt>
            <dd>{{ tankAgreement.phone }}</dd>
            <dt>Merking</dt>
            <dd>{{ tankAgreement.po_number }}</dd>
        </dl>
    </div>
</template>

<script>
export default {
    name: 'WorkLogTankAgreementInfo',
    props: {
        tankAgreement: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss"></style>
