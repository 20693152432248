<template>
    <div class="flex-auto overflow-auto flex flex-col">
        <h3 v-if="objectType === 'order'" class="h3" :title="order.uuid">Ordre for {{ order.customer.name }}</h3>
        <h3 v-if="objectType === 'tankAgreement'" class="h3" :title="tankAgreement.id">Avtaleoppdrag for {{ tankAgreement.customer.name }}</h3>
        <div v-if="!customerHas247RemoteId" class="mt-2">
            <IconWarning class="inline-block mr-2 text-orange" />
            Denne kunden har ingen kobling til 24SevenOffice og ordre kan derfor ikke opprettes.
        </div>
        <div v-for="subObject in subObjects" :key="subObject.uuid ? subObject.uuid : subObject.id">
            <div v-if="objectType === 'order'" class="mt-2 font-bold" :title="subObject.uuid">{{ $t('sub_order.type.' + subObject.type_id) }}</div>
            <div v-else-if="objectType === 'tankAgreement'" class="mt-2 font-bold" :title="subObject.id">{{ subObject.name }}</div>
            <div v-else>Ukjent objekttype {{ objectType }}</div>
            <template v-if="subObject.workLogsByStatus.approved">
                <div v-for="workLog in subObject.workLogsByStatus.approved" :key="workLog.uuid">
                    <v-checkbox
                        :disabled="workLog.twentyfourseven_order_uuid && workLog.twentyfourseven_order_uuid !== selectedTwentyfoursevenOrder?.uuid"
                        :model-value="selectedWorkLogUuids[workLog.uuid]"
                        @change="updateSelectedWorkLogUuids(workLog.uuid, ...arguments)"
                        >{{ workLog.operator }}, {{ format(parseISO(workLog.date), 'dd/MM-yyyy', { locale: nb }) }} ({{
                            Object.keys(workLog.order_lines || {}).length
                        }})
                    </v-checkbox>
                    <OrderLines v-if="selectedWorkLogUuids[workLog.uuid]" :workLog="workLog" :isEditing="false" />
                </div>
            </template>
            <div v-else class="mt-4">Ingen godkjente kjørelister klare til fakturering.</div>
        </div>
        <div v-if="customerHas247RemoteId">
            <h3 class="h3 mt-4">24sevenoffice-integrasjon</h3>
            <div
                v-for="twentyfoursevenOrder in selectableTwentyfoursevenOrders"
                :key="twentyfoursevenOrder?.uuid"
                class="mt-2 p-2 rounded-md border border-blue-400"
                :class="{ 'bg-blue-400': twentyfoursevenOrder?.uuid === selectedTwentyfoursevenOrder?.uuid }"
            >
                <div @click="selectTwentyfoursevenOrder(twentyfoursevenOrder)">
                    <IconRadioChecked class="w-6 mr-2 inline-block" v-if="twentyfoursevenOrder?.uuid === selectedTwentyfoursevenOrder?.uuid" />
                    <IconRadioUnchecked class="w-6 mr-2 inline-block" v-else />
                    <template v-if="twentyfoursevenOrder">Eksisterende 24seven-ordre (id:{{ twentyfoursevenOrder.remote_id }})</template>
                    <template v-else>Ny 24seven-ordre</template>
                </div>
                <div
                    v-if="twentyfoursevenOrder === selectedTwentyfoursevenOrder && (sumSelectedOrderLines || selectedTwentyfoursevenOrder)"
                    class="mr-[7px] mt-2 pt-1 border-t"
                >
                    <div class="text-right">
                        Total: <strong>{{ sumSelectedOrderLines }}</strong>
                    </div>
                    <button v-if="customerHas247RemoteId" class="small-btn-blue" @click="onSubmitWorkLogs" :disabled="isSubmitting || submitSuccess">
                        <IconLoader v-if="isSubmitting" width="32" height="32" class="text-blue-400 mx-auto align-middle animate-spin inline-block" />
                        <IconCheckmark v-if="submitSuccess" width="32" height="32" class="text-blue-400 inline-block" />
                        {{ selectedTwentyfoursevenOrder ? 'Synkroniser' : 'Opprett ordre for' }} {{ countSelectedWorkLogs }} kjøreliste{{
                            countSelectedWorkLogs === 1 ? '' : 'r'
                        }}
                        til 24sevenoffice
                    </button>
                </div>
                <div v-if="!twentyfoursevenOrder && !selectedTwentyfoursevenOrder && !sumSelectedOrderLines">
                    (Ingen kjørelister med ordrelinjer valgt)
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import Vue from 'vue';
import { mapState } from 'vuex';

import { synchronize247Order } from '@/api';
import IconCheckmark from '@/assets/svg/checkmark.svg';
import IconLoader from '@/assets/svg/loader.svg';
import IconRadioChecked from '@/assets/svg/radio-checked.svg';
import IconRadioUnchecked from '@/assets/svg/radio-unchecked.svg';
import IconWarning from '@/assets/svg/warning.svg';
import OrderLines from '@/components/future/OrderLines.vue';
import VCheckbox from '@/components/VCheckbox/VCheckbox.vue';
import Customer from '@/store/models/Customer';

export default {
    name: 'OrderLineInvoicer',
    components: { IconCheckmark, IconLoader, IconRadioChecked, IconRadioUnchecked, IconWarning, OrderLines, VCheckbox },
    props: {
        tankAgreement: {
            type: Object,
            default: null,
        },
        order: {
            type: Object,
            default: null,
        },
        twentyfoursevenOrders: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            selectedWorkLogUuids: {},
            isSubmitting: false,
            submitSuccess: false,
            selectedTwentyfoursevenOrder: null,
            nb,
        };
    },
    computed: {
        ...mapState(['modules']),
        objectType() {
            if (this.order) {
                return 'order';
            } else if (this.tankAgreement) {
                return 'tankAgreement';
            } else {
                return null;
            }
        },
        selectableTwentyfoursevenOrders() {
            return [...this.twentyfoursevenOrders, null];
        },
        mainObject() {
            if (this.order) {
                return this.order;
            } else if (this.tankAgreement) {
                return this.tankAgreement;
            } else {
                return null;
            }
        },
        subObjects() {
            if (this.order) {
                return this.order.subOrders;
            } else if (this.tankAgreement) {
                return this.tankAgreement.tanks;
            } else {
                return [];
            }
        },
        customerHas247RemoteId() {
            if (this.modules != null && this.modules.some((module) => module.id === '24seven_integration')) {
                let customerId = null;
                if (this.order) {
                    customerId = this.order?.customer?.id;
                } else if (this.tankAgreement) {
                    customerId = this.tankAgreement.customer_id;
                }
                if (customerId) {
                    const customer = Customer.find(customerId);
                    return customer && customer.integration_id === '24seven' && customer.remote_id;
                }
            }
            return false;
        },
        countSelectedWorkLogs() {
            return Object.keys(this.selectedWorkLogUuids).length;
        },
        sumSelectedOrderLines() {
            var total = 0;
            for (const subObjectId in this.subObjects) {
                const subObject = this.subObjects[subObjectId];
                for (const workLog of subObject.workLogsByStatus.approved) {
                    if (this.selectedWorkLogUuids[workLog.uuid]) {
                        total += Object.values(workLog?.order_lines || {}).reduce((carry, orderLine) => {
                            return carry + (parseFloat(String(orderLine.total || 0).replace(',', '.')) || 0);
                        }, 0);
                    }
                }
            }
            return total;
        },
    },
    methods: {
        updateSelectedWorkLogUuids(uuid, value) {
            if (value) {
                Vue.set(this.selectedWorkLogUuids, uuid, true);
            } else {
                Vue.delete(this.selectedWorkLogUuids, uuid);
            }
        },
        async onSubmitWorkLogs() {
            this.isSubmitting = true;
            this.submitSuccess = false;
            try {
                const response = await synchronize247Order(this.selectedTwentyfoursevenOrder?.uuid, Object.keys(this.selectedWorkLogUuids));

                for (const workLog of response.updated_work_logs) {
                    this.$emit('workLogUpdated', workLog);
                }
                this.$emit('twentyfoursevenOrderUpdated', response.twentyfourseven_order);
                this.selectTwentyfoursevenOrder(response.twentyfourseven_order);
                this.submitSuccess = true;
            } finally {
                this.isSubmitting = false;
            }
        },
        selectTwentyfoursevenOrder(twentyfoursevenOrder) {
            if (twentyfoursevenOrder !== this.selectedTwentyfoursevenOrder) {
                this.submitSuccess = false;
                this.selectedTwentyfoursevenOrder = twentyfoursevenOrder;
                this.selectedWorkLogUuids = {};
                (this.selectedTwentyfoursevenOrder?.work_log_uuids || []).forEach((uuid) => {
                    Vue.set(this.selectedWorkLogUuids, uuid, true);
                });
            }
        },
        format,
        parseISO,
    },
    mounted() {
        if (this.twentyfoursevenOrders.length === 1) {
            this.selectTwentyfoursevenOrder(this.twentyfoursevenOrders[0]);
        }
    },
};
</script>

<style lang="scss"></style>
