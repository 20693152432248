import { Model } from '@vuex-orm/core';
import { format, formatISO9075, sub } from 'date-fns';
import { nb } from 'date-fns/locale';

import Department from '@/store/models/Department';
import User from '@/store/models/User';
import { timestampToTimeSlot } from '@/util';

export default class DepartmentUserSchedule extends Model {
    static fields() {
        return {
            created_at: this.number(),
            deleted_at: this.number(),
            department: this.belongsTo(Department, 'department_id'),
            department_id: this.number().nullable(),
            from: this.number().nullable(),
            id: this.number(),
            priority: this.number(),
            reason: this.string().nullable(),
            tenant_id: this.number(),
            to: this.number().nullable(),
            type: this.string().nullable(),
            updated_at: this.number(),
            user_id: this.number(),
            user: this.belongsTo(User, 'user_id'),
        };
    }

    getTimeSlotFrom(date) {
        if (date) {
            return date === this.getDateFrom() ? timestampToTimeSlot(this.from) : '00:00';
        } else {
            return timestampToTimeSlot(this.from);
        }
    }

    getTimeSlotTo(date) {
        if (date) {
            return date === this.getDateTo() ? timestampToTimeSlot(this.to) : '24:00';
        } else {
            return timestampToTimeSlot(this.to);
        }
    }

    getDateFrom() {
        return format(this.from * 1000, 'yyyy-MM-dd', { locale: nb });
    }

    getDateTo() {
        return format(this.to * 1000, 'yyyy-MM-dd', { locale: nb });
    }

    getFullDateFrom() {
        return (this.from && formatISO9075(this.from * 1000, { locale: nb })) || '';
    }

    getFullDateTo() {
        return (this.to && formatISO9075(this.to * 1000, { locale: nb })) || '';
    }

    getDepartmentLabel() {
        if (this.department_id) {
            const department = this.department;
            return department?.label || department?.name || 'Ikke navngitt';
        } else {
            return 'N/A';
        }
    }

    getFullReadableDateRange() {
        if (this.getDateFrom() === this.getDateTo()) {
            // if to/from dates both are the same date, we only need to display one date, and a range
            return (
                ((this.from && format(this.from * 1000, 'eee d. MMM H:mm', { locale: nb })) || '') +
                ' - ' +
                (this.to && format(this.to * 1000, 'H:mm', { locale: nb }))
            );
        } else if (this.from && this.to && this.getTimeSlotFrom() === '00:00' && this.getTimeSlotTo() === '00:00') {
            // if days both start and end at midnight, assume this is whole days.
            // show range start day to end day minus one
            const fromDate = format(this.from * 1000, 'eee d. MMM', { locale: nb });
            const toDate = format(sub(this.to * 1000, { days: 1 }), 'eee d. MMM', { locale: nb });
            return fromDate === toDate ? fromDate : fromDate + ' - ' + toDate;
        }
        // we dont need to include year here
        return (
            ((this.from && format(this.from * 1000, 'eee d. MMM H:mm', { locale: nb })) || '') +
                ' - ' +
                (this.to && format(this.to * 1000, 'eee d. MMM H:mm', { locale: nb })) || ''
        );
    }
}

DepartmentUserSchedule.entity = 'department_user_schedules';
DepartmentUserSchedule.primaryKey = 'id';
