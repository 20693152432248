import { Model } from '@vuex-orm/core';

export default class ChatChannel extends Model {
    static fields() {
        return {
            uuid: this.string(),
            name: this.string(),
            role_participants: this.attr([]),
            user_participants: this.attr([]),
            deleted_at: this.number().nullable(),
            created_at: this.number(),
            updated_at: this.number(),
            unread_count: this.number(0),
        };
    }
}

ChatChannel.entity = 'chat channels';
ChatChannel.primaryKey = 'uuid';
